import { Tag } from "antd";
import { ComponentTableActions } from "../../../components/table/actions";

export const getProductOptionColumns = (onAction = (action, data) => console.log(action, data)) => {
    return [
        {
          title: '',
          dataIndex: 'id',
        },
       
      
         {
          title: 'NAME',
          dataIndex: 'name',
        },
      
      
        {
          title: 'OPTIONS VALUE',
          dataIndex: 'values',
          render: (_,{values})=>{
            let valueName = values ? values.map((value)=>(<Tag color="purple-inverse">{value.name}</Tag>)):null;
            return <div><b>{valueName}</b></div>

          }
       
        },
       
        {
            title: 'Actions',
            render: (_, record) => <ComponentTableActions onAction={onAction} record={record} />
        },
      ];
}