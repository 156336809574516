
import { Modal, Table } from 'antd';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../../../redux/features/modal';
import { getRoles } from '../../../../../../../redux/features/role';
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top'
import { Modals } from '../../../../components/layout/modal';



const columns = [
    {
        title: '',
        dataIndex: 'id',

    },

    {
        title: 'NAME',
        dataIndex: 'name',

    },


    {
        title: 'DESCRIPTION',
        dataIndex: 'description',


    },
    {
        title: 'DISPLAY NAME',
        dataIndex: 'display_name',

    },


    {
        title: 'CREATE AT',
        dataIndex: 'created_at',
        render: (text) => {
            let date = moment(text).format('DD-MM-YYYY');
            return date;
        }
    },

];
const data = [];

export const DashboardPageRoleList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRoles());
    }, [])

    const roleList = useSelector((store) => store.role.roles);
    const roleState = useSelector((store) => store.role.get);
    const handleAddClicked = (e) => {
        e.preventDefault();
        dispatch(showModal(Modals.RoleModalForm));

    }
    return (
        <div>
            <DashboardComponentBodyTop title="Roles Managent" onAdd={handleAddClicked} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    columns={columns}
                                    dataSource={roleList}
                                    loading={roleState.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

