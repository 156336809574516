import { Input, Button, message, Steps, theme, Divider, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from '../../../../../../../../redux/features/role';
import { firstStepForm } from './steps/firstStep';

const initialState = {
    name: "",
    description: "",
    display_name: ""
}

export const RoleModalForm = (props) => {
    const dispatch = useDispatch();
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const { TextArea } = Input;
    const { id, submitted } = props;

    useEffect(() => {
        dispatch(getPermission());
    }, []);

    const perimissions = useSelector((store) => store.role.permissions);

    const steps = [
        {
            title: 'Role Details',
            content: (<div className='container'>
                <Divider />
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <div className="form-label">
                                Name
                            </div>
                            <Input placeholder="Name" name="name" />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <div className="form-label">
                                Display Name
                            </div>
                            <Input placeholder="Display Name" name="display_name" />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="form-label">
                                Description
                            </div>
                            <TextArea rows={4} name="description" />

                        </div>
                    </div>
                </div>
            </div>),
        },
        {
            title: 'Permission Details',
            content: (<div className='container'>
                <Divider />
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Display Name</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {perimissions.map((per) => (

                            <tr key={per.id}>
                                <td></td>
                                <td>{per.name}</td>
                                <td>{per.display_name}</td>
                                <td><Checkbox /></td>
                            </tr>






                        ))
                        }
                    </tbody>
                </table>


            </div >),

        },

    ];

    const contentStyle = {
        lineHeight: '260px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };

    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));


    return (
        <div className='container-fuild' >
            <div><h4>Create Role</h4></div>

            <>
                <Steps current={current} items={items} />
                <div>{steps[current].content}</div>
                <div
                    style={{
                        marginTop: 24,
                    }}
                >
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={() => message.success('Processing complete!')}>
                            Done
                        </Button>
                    )}
                    {current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            Previous
                        </Button>
                    )}
                </div>
            </>


        </div>
    )
}
