import React, { useState, useEffect } from 'react'
import { DashboardComponentTabs } from '../../../../components/layout/body/bottom'
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top'
import { DashboardComponentMainBoard } from '../../../../components/layout/body/mainbody'
import { ComponentDataTable } from '../../../../components/table/Datatable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, getUsers } from '../../../../../../../redux/features/user'
import * as styles from './index.module.scss';
import { Button, Dropdown, Modal, Space, Table, Tag } from 'antd';
import { ComponectSearchInput } from '../../../../components/form/input/search'
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment/moment'
import { IconCustomer } from '../../../../../../components/icons/customer'
import { IconDot } from '../../../../../../components/icons/dot'
import { showModal } from '../../../../../../../redux/features/modal'
import { Modals } from '../../../../components/layout/modal'
import { getCustomerColumns } from './colums'






const items = [
  {
    label: '1st menu item',
    key: '1',

  },
  {
    label: '2nd menu item',
    key: '2',
  
  },
  
];
const menuProps = {
  items,
 
};



export const DashboardPageCustomerList = () => {
    //state
     const [isModalOpen, setIsModalOpen] = useState(false);

     //redux
    const dispatch = useDispatch();
    const customers = useSelector((store) => store.user.users);
    const customerState = useSelector((store) => store.user.get);
   


   //effects

    useEffect(() => {
        dispatch(getUsers());
    }, [])

    //handlers
    const handleAddClicked = (e) => {

        e.preventDefault();
        dispatch(showModal(Modals.UserFormModal,{},parentProps={ height:900, width:700}))

    }
    const handleEdit = (value) => {
         //e.preventDefault();
      //  console.log("event value: ", value);
        dispatch(showModal(Modals.UserFormModal, { value },parentProps = { height:900, width:700}))
    }

    const handleDelete =(id)=>{
      dispatch(deleteUser(id));
    }

    const handleView =(id)=>{
       dispatch(showModal(Modals.UserViewFormModal,{id},parentProps = { height:900, width:1000}))
    };

    const handleAction =(action,data)=>{
       
        switch(action){
            case "view":
                handleView(data.id);
                break;
             case 'edit':
                handleEdit(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }
       
    }


    return (
        <div>
            <DashboardComponentBodyTop title="Accounts"  onAdd={handleAddClicked}/>
            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Active</a>
                    <a className={`nav-link ${styles.link}`} href="#">Blocked</a>


                </nav>
            </div>

            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search account" />
                        </div>
                        <div className='col-md-2'>
                              <Dropdown menu={menuProps}>
                              <Button>
                                <Space>
                                  Actions
                                  <DownOutlined/>
                                 </Space>
                               </Button>
                                 </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table
                        loading={customerState.isLoading}
                        columns={getCustomerColumns(handleAction)}
                        dataSource={customers}
                    />
                </div>



            </div>
        </div>
    )
}
