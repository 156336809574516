.imageCart {
    width: 50px;
    height: 50px;
}

.operator {
    margin-right: 10px;
    text-align: center;

    span {
        display: block;
    }

    img {
        height: 90px;
        width: 90px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px;
    }
}

.popup {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.618);

    display: flex;
    justify-content: center;
    align-items: center;

    .dialog {
        background-color: white;
        width: 60vw;
        padding: 50px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }
}