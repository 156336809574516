
import React from 'react';
import { Tag, Space, Image } from 'antd';
import moment from 'moment';
import { ComponentTableActions } from '../../../components/table/actions';
import GraceApi from '../../../../../../api/grace/endpoint';

export const getMobileOperatorsColumns = (action = (action, data) => console.log(action, data)) => {
    return [
        {
            title: '',
            dataIndex: 'id',
    
        },
        {
            title: 'LOGO',
            dataIndex: 'logo',
            render: (_,{logo}) => {
                return (
                    <Image width={40}
                        height={40} style={{ borderRadius: "20px" }} src={GraceApi.domain+logo} />
                )
            }
    
        },
        {
            title: 'NAME',
            dataIndex: 'name',
    
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'description',
    
    
        },
        {
            title:"VALUE",
            dataIndex: 'value',
        },
        {
            title: 'STATUS',
            dataIndex: 'active',
            render: (active) => {
                if (active === 1) {
                    return (
                        <Tag color="green" key={active}>
                            {"IN ABLE".toUpperCase()}
                        </Tag>
                    );
                } else if (active === 0) {
                    return (<Tag color="red" key={active}>
                        {"DISABLE".toUpperCase()}
                    </Tag>);
    
                }
    
    
            }
    
        },
        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'ACTION',
            render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },
    ]
}