import React, { useEffect, useState } from 'react'
import * as styles from './index.module.scss'
import background from '../../../../../assets/images/woman.png';
import { Input, Button, Card, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { login, register, verifyEmail, requestEmailVerification, verifyPhoneOwnership } from '../../../../../redux/features/authentication';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../../../assets/constants/color';
import { AuthInput } from '../../components/form/input';

const initialValues = {
    "referral_code": "",
    name: "",
    email: "",
    phone: "255",
    password: "",
}
export const RegistrationPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [values, setValues] = useState(initialValues);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [phoneVerificationError, setPhoneVerificationError] = useState("");
    const [showEmailVerificationCodeField, setShowEmailVerificationCodeField] = useState(false);
    const registerState = useSelector((state) => state.authentication.register);
    const requestEmailVerificationState = useSelector((state) => state.authentication.requestEmailVerification);
    const verifyEmailState = useSelector((state) => state.authentication.verifyEmail);
    const verifyPhoneOwnershipState = useSelector((state) => state.authentication.verifyPhoneOwnership);

    useEffect(() => {
        if (registerState.error) {
            setErrorMessage(registerState.error);
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }

    }, [registerState]);

    useEffect(() => {
        if (requestEmailVerificationState.data) {
            if (requestEmailVerificationState.data.verification) {
                if (requestEmailVerificationState.data.verification.status == 'VERIFIED') {
                    setEmailVerified(true);
                }
                if (requestEmailVerificationState.data.verification.status == 'PENDING') {
                    setShowEmailVerificationCodeField(true);
                }
            }
        }
    }, [requestEmailVerificationState]);

    useEffect(() => {
        if (verifyEmailState.data) {
            if (verifyEmailState.data.verification) {
                if (verifyEmailState.data.verification.status == 'VERIFIED') {
                    setEmailVerified(true);
                }
            }
        }
        if (verifyEmailState.error) {
            if (verifyEmailState.error.message) {

            }
        }
    }, [verifyEmailState])

    useEffect(() => {
        if (verifyPhoneOwnershipState.data) {
            if (verifyPhoneOwnershipState.data.verification) {
                if (verifyPhoneOwnershipState.data.verification.status == 'ACCEPTED') {
                    setPhoneVerified(true);
                }
            }
        }
        if (verifyPhoneOwnershipState.error) {
            if (verifyPhoneOwnershipState.error.message) {
                setPhoneVerificationError(verifyPhoneOwnershipState.error.message)
            }
        }
    }, [verifyPhoneOwnershipState])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });

    }
    const handleSubmit = () => {
        console.log("input", values)
        // const { email, password } = values;
        let payload = values;
        payload['name'] = `${values.firstname} ${values.lastname}`;
        dispatch(register(values));
        // console.log(email, password);
    }

    const processError = (name) => {
        if (name == 'phone' && phoneVerificationError) {
            return phoneVerificationError;
        }
        if (registerState.error && registerState.error.errors) {
            const errors = registerState.error.errors;
            if (errors[name]) {
                return errors[name][0];
            }
        }
        
        return "";
    }

    const requestEmailVerificationHandler = () => {
        setEmailVerified(false);
        setShowEmailVerificationCodeField(false);
        dispatch(requestEmailVerification({
            "email": values.email,
        }));
    }

    const verifyEmailHandler = () => {
        dispatch(verifyEmail({
            "email": values.email,
            "code": values.code,
        }))
    }

    const verifyPhoneOwnershipHandler = () => {
        setPhoneVerified(false);
        setPhoneVerificationError("");
        dispatch(verifyPhoneOwnership({
            "firstname": values.firstname,
            "lastname": values.lastname,
            "phone": values.phone,
        }));
    }

    return (
            <div className={`${styles.wrapper}`} style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className={styles.register}>
                                <h1>GRACE PRODUCTS</h1>
                                
                                <Card className={styles.card}>
                                    <div className="py-4"></div>
                                    <h2>Register New Account</h2>
                                    {errorMessage && <div class="alert alert-danger">{errorMessage?.message}</div>}
                                    <div className="py-3"></div>
                                    <AuthInput label="REFFERAL KING CODE" name="refaral_code" value={values.fullname} onChange={handleChange} placeholder="Join the Kings & Queens." error={processError('refaral_code')} />
                                    <div className="py-1"></div>
                                    <div className="row">
                                        <div className='col-lg-6'>
                                            <AuthInput label="First Namme" name="firstname" value={values.firstname} onChange={handleChange} placeholder="Full Name" error={processError('name')} />
                                        </div>
                                        <div className='col-lg-6'>
                                            <AuthInput label="Last Name" name="lastname" value={values.lastname} onChange={handleChange} placeholder="Full Name" error={processError('name')} />
                                        </div>
                                    </div>
                                    <div className="py-1"></div>

                                    <div className="row">
                                        <div className="col">
                                            <AuthInput label="Email" name="email" value={values.email} onChange={handleChange} placeholder="Email Address" onBlur={requestEmailVerificationHandler} error={processError('email')} />
                                        </div>
                                        {(showEmailVerificationCodeField && !emailVerified) && <div className="col-lg-4"><AuthInput label="Code" name="code" value={values.code} onChange={handleChange} placeholder="eg:- 1234" onBlur={verifyEmailHandler} error={verifyEmailState.error?.message} /></div>}
                                    </div>
                                    {requestEmailVerificationState.isLoading && (
                                        <div>
                                            <span className='spinner-border spinner-border-sm mx-2'></span> Sending Verification email to {values.email}...
                                        </div>
                                    )}
                                    {verifyEmailState.isLoading && (
                                        <div>
                                            <span className='spinner-border spinner-border-sm mx-2'></span> Verifying the code
                                        </div>
                                    )}
                                    {emailVerified && <span className='text-success'>Email Verified!</span>}
                                    {(showEmailVerificationCodeField && !emailVerified) && <span className='text-success'>An email has been sent! Type the code in the code field to verify</span>}
                                    
                                    <div className="py-1"></div>

                                    <AuthInput label="Phone" name="phone" value={values.phone} onChange={handleChange} placeholder="Phone Number" error={processError('phone')} onBlur={verifyPhoneOwnershipHandler} />
                                    {verifyPhoneOwnershipState.isLoading && (
                                        <div>
                                            <span className='spinner-border spinner-border-sm mx-2'></span> Verifying Phone Ownership Status
                                        </div>
                                    )}
                                    {phoneVerified && <span className='text-success'>Phone Ownership Verified</span>}

                                    <div className="py-1"></div>

                                    <AuthInput type="password" label="Password" name="password" value={values.password} onChange={handleChange} placeholder="Password" error={processError('password')} />
                                    <div className="py-3"></div>
                                    {/* <div className='d-flex mt-3'>
                                        <div className="mb-3 d-flex flex-grow-1">
                                            <input type="checkbox" className='form-check-input' />
                                            <div className="px-1"></div>
                                            <span className="form-check-label">Remember Me</span>
                                        </div>
                                        <p className='text-primary' style={{ cursor: "pointer" }}>Forgot Password?</p>
                                    </div> */}
                                    <Button
                                        disabled={!(emailVerified && phoneVerified)}
                                        onClick={handleSubmit}
                                        style={{
                                            backgroundColor: Colors.primary,
                                            borderRadius: '14px',
                                            color: '#fff',
                                            height: "40px"
                                        }} block> Register {registerState.isLoading && <span className='spinner-border spinner-border-sm mx-2'></span>}
                                    </Button>
                                    {!(emailVerified && phoneVerified) && <p style={{ fontSize: 10 }} className=' text-black-50'>You will be able to submit this phone when your phone number and email have been verified</p>}
                                        <div className="py-4"></div>
                                    <p className='text-center'>Already have an account? <span onClick={() => navigate("/auth/login")} className='text-primary'>Sign In</span></p>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
    )
}
