import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showUser } from '../../../../../../../redux/features/user';
export const userViewFormModal = (props) => {
//const 
 const { id, submitted, changeParentProps} = props;

 //state
 const [mount, setMount] =useState(false);
 const [user ,setUser]=useState({});


//redux
 const dispatch = useDispatch ();
 const userState = useSelector((store)=>store.user.view);
 //effects
 useEffect(()=>{
    setMount(true)
    setUser({});
 },[mount])

 useEffect(()=>{
    if(!mount) return;
    dispatch(showUser(id))
 },[id])
 useEffect(()=>{

 },[user])

  return (
      <div className="row">
        <div className='col-lg-12'>
            <div className="row">
                <div className="card">
                    <div className="card-header">{user.users ? user.users.name : null} | {user.users ? user.users.email :null}  | {user.users ?user.users.phone :null}</div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>MEMBER CODE</th>
                                            <th>STATUS</th>
                                            <th>WALLET</th>
                                            <th>LEVEL</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{user.users ? user.users.member.king_code:null}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>


                                    </tbody>
                                    </table>
                                

                            </div>
                            <div className="col-md-4">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                             <div className="d-flex">
                                <h4 className="header-title flex-grow-1">Role List</h4>
                            </div>

                            <div className="table-responsive">
                                <hr />
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                      
                                            <tr>
                                                <th scope="row">1</th>
                                                <td>name</td>
                                                <td>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" value="value"
                                                            name="role_id[]"
                                                            
                                                            type="checkbox" id="formCheck1"/>
                                                    </div>
                                                </td>

                                            </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                      
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
    
                            <div className="d-flex">
                                <h4 className="header-title flex-grow-1">Permission List</h4>
                            </div>
    
                            <div className="table-responsive">
                                <hr />
                                <table className="table mb-0">
    
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Status</th>
    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                            <tr>
                                                <th scope="row">1</th>
                                                <td></td>
                                                <td>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" value="perm"
                                                            name="permission_ids[]"
                                                            
                                                            type="checkbox" id="formCheck1"/>
                                                    </div>
                                                </td>
    
                                            </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        
                       


                    </div>
                </div>
            </div>
        </div>
  )
}
