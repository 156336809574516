import moment from 'moment/moment';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import order, { getOrders } from '../../../../../../redux/features/order';
import ButtonMain from '../../../../../components/buttons/Main';
import { useNavigate } from 'react-router-dom';

export const MyOrders = () => {
    // router
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const getOrdersState = useSelector(state => state.order.get)
    const orders = useSelector(state => state.order.orders)

    // effects
    useEffect(() => {
        dispatch(getOrders({params: {user_id: user.id}}))
    }, []);

    return (
        <div>
            {/* <h3>My Orders</h3> */}
            {getOrdersState.isLoading ? 'Loading...' : null}
            
            <div className="table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Order</th>
                            <th>Agent</th>
                            <th>Status</th>
                            <th width="200px">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {orders.map((order, i) => (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{(moment(order.created_at).format("dddd, DD/MM/yyyy"))}</td>
                                <td>
                                    <h6>ORDER-{order.id}</h6>
                                    <p>{order.items.length} Item(s) with a total of {order.total_amount}</p>
                                </td>
                                <td>
                                    {order.agent ? (
                                        <div>
                                            <h6>{order.agent.company_name}</h6>
                                            <p>{order.agent.street?.name}, {order.agent.ward?.name}, {order.agent.district?.name}, {order.agent.region?.name}, {order.agent.country?.name}</p>
                                        </div>
                                    ) : 'N/A'}
                                </td>
                                <td>
                                    {order.status}
                                </td>
                                <td>
                                    {order.status == 'PENDING' ? <ButtonMain className="btn-secondary" onClick={() => navigate('/stores', { state: { order } })}>Assign Agent</ButtonMain> : null}
                                    {order.status == 'AGENT_ASSIGNED' ? <ButtonMain onClick={() => navigate('/payment', { state: { order } })}>Pay Now</ButtonMain> : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
