import { createSlice } from "@reduxjs/toolkit";
import { login } from "./authentication";


export class MainMenuModes {
    static normal = 'normal';
    static transparent = 'transparent';
}

const initialState = {
    network: {
        unauthorized: false,
        offline: false,
    },
    loader: {
        isLoading: false,
        message: "Please Wait..."
    },
    mainMenu: {
        mode: MainMenuModes.normal, // normal & transparent
    }
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateNetworkUnauthorized(state, action) {
            state.network.unauthorized = action.payload;
        },
        updateNetworkOffline(state, action) {
            state.network.offline = action.payload;
        },
        updateLoader(state, action) {
            state.loader.isLoading = action.payload.isLoading ?? false;
            state.loader.message = action.payload.isLoading && action.payload.message ? action.payload.message : 'Please Wait...';
        },
        appMainMenuUpdateTransparency(state, action) {
            state.mainMenu.mode = action.payload ?? 'normal';
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.network.unauthorized = false;
        }
    },
});

export default appSlice.reducer;
export const { updateNetworkUnauthorized, updateLoader, updateNetworkOffline, appMainMenuUpdateTransparency } = appSlice.actions

// selectors
export const selectorAppNetworkIsUnauthorized = (state) => state.app.network.unauthorized;

// main menu modes
