
import { ComponentTableActions } from "../../../components/table/actions";
export const getProductSkuColumns = (onAction = (action, data) => console.log(action, data)) => {
    return [
        {
          title: '',
          dataIndex: 'id',
        },
        {
          title: 'PRODUCT NAME',
          dataIndex: 'products',
          render:(_,{products})=>{
            let productName = products.name;
            return <h5>{productName}</h5>
          }
        },
         {
          title: 'SKU NAME',
          dataIndex: 'name',
        },
      
        {
        title: 'DESCRIPTION',
          dataIndex: 'description',
          
        },
        {
          title: 'PRICE',
          dataIndex: 'price',
       
        },
       
        {
            title: 'Actions',
            render: (_, record) => <ComponentTableActions onAction={onAction} record={record} />
        },
      ];
}