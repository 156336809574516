@import '$assets/scss/main.scss';

.header {
    position: relative;
    
    display: flex;
    align-items: center;

    // padding: 30px 30px 30px 80px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    &.transparent {
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
        background-color: transparent;
        color: white;
        box-shadow: none;
        z-index: 10;
    }

    .logo {
        height: 30px;
    }

    .menu {
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        a {
            all: unset;
            margin: 0 5px;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid $primary;
            }
        }

    }

    .extra {
        display: flex;

        margin: 0 30px;

        div {
            margin: 0 10px;
        }
    }

    .toggle {
        margin-left: auto;
    }

    .sidemenu {
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        height: 100vh;
        color: black;
        padding: 20px;
        width: 70vw;

        display: flex;
        flex-direction: column;

        border-right: solid 1px gray;
        background-color: rgba(#fff, 0.9);
        z-index: 100;

        a {
            all: unset;
            cursor: pointer;
            border-bottom: solid 1px gray;
            padding-bottom: 10px;
            margin-bottom: 15px;
        }

        &.show {
            left: 0;
            transition: all 0.3s ease-in;
        }

        &.hide {
            left: -70vw;
            transition: all 0.3s ease-out;
        }
    }

}