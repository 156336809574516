import moment from "moment/moment";
import { ComponentTableActions } from "../../../../components/table/actions";

export const getMerchantTypeColums =(action=(action,data)=>console.log(action,data))=>{
    return (
        columns = [
        {
            title: '',
            dataIndex: 'id',

        },
        {
            title: 'NAME',
            dataIndex: 'name',

        },
        {
            title: 'Description',
            dataIndex: 'description',

        },

        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'ACTION',
          render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },

    ]
    );

}