
.wrapper {
    text-align: center;
}

.banner {
    height: 450px;
    aspect-ratio: calc(293/696);
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.items {
    flex: 1;
    display: grid;
    margin: 0 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}