import React from 'react'
import React ,{useEffect,useState}from 'react'
import ButtonMain from '../../../../../components/buttons/Main';
import * as styles from './index.module.scss';
import banner from '$assets/images/category_product_banner.png';
import WebComponentItemProduct from '../../items/product/index';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../../../redux/features/product';
import { useNavigate } from 'react-router-dom';


const WebComponentPartialCategoryProducts = () => {
    const dispatch = useDispatch();
    const products = useSelector((store) =>store.product.products)
    const [activeCategory, setActiveCategory] = useState(null);
    
    const navigate = useNavigate();

    useEffect (() =>{
        dispatch(getProducts());
    }, []);

    return (
        <div className={styles.wrapper}>
            <h3>Popular Products</h3>
            <p>These are are the most sold products</p>

            <div className='d-flex '>
                <div className='d-none d-md-block'>
                    <div className={` ${styles.banner}`} style={{ backgroundImage: `url(${banner})` }}>
                        <h3>See All <br />Products</h3>
                        <ButtonMain  onClick={() => navigate('/shop')}>See All</ButtonMain>
                    </div>
                </div>
                <div className={styles.items}>
                    { products.filter(p => activeCategory ?  p.categories.some(c => c.id == activeCategory) : true).map((value)=>(
                         <div key={value.id} >
                             <WebComponentItemProduct product={value}/>
                         </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WebComponentPartialCategoryProducts;