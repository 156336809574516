import { EditOutlined } from "@ant-design/icons";
import { Dropdown, Space, Tag } from "antd";
export const getMenu = (record, onAction = (action, data) => console.log(action, data)) => {
    return {
        items: [
            // {
            //     key: 'view',
            //     label: 'View',
            //     icon: <EyeOutlined />
            // },
            // edit
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />
            },
        
        ],
        onClick: (item) => onAction(item.key, { 'id': record.id, 'payload': record}),
    }
}

export const getColumns =( onAction=(data,action)=>{})=>{
    return ( colums=[

            {
            title: '',
            dataIndex: 'id',

           },
              {
            title: 'Name',
            dataIndex: 'user',
            render:(_,{user})=>{
                let name = user ? user.name :null;
                return (<p>{name}</p>);
            }

        },
           {
            title: 'STATUS',
            dataIndex: 'is_active',
            render:(_,{is_active})=>{
                if(is_active== 0){
                    return <p className="text-danger">IN ACTIVE</p>
                }else{
                    return <p className="text-success">ACTIVE</p>
                }
            }

        },
           {
            title: 'WALLET',
            dataIndex: 'wallet',
            render:(_,{wallet})=>{
                return <p>TSH {wallet}.00/=</p>
            }

        },
           {
            title: 'CODE',
            dataIndex: 'king_code',

        },

           {
            title: 'PARENT',
            dataIndex: 'parent',
            render:(_,{parent})=>{
                let parentName = parent ? (parent.user)? parent.user.name:"Null"  : null;
                return <Tag color="blue">{parentName}</Tag>
            }
            

        },
           {
            title: 'ACTION',
               render: (_, record) =>{
                return  (
                <Space size="middle">
                    <Dropdown.Button onClick={() => onAction('view', {id: record.id,active:record.is_active})} menu={getMenu(record, onAction)}>
                        {
                            (record.is_active==1)?<p className="text-danger">DEACTIVATE</p>:<p className="text-success">ACTIVE</p>
                        }
                    </Dropdown.Button>
                </Space>
            )
               }

        },

       ]) ;
}