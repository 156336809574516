import { Alert, Divider, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../../../../redux/features/modal';
import { createProductOption, viewProduct } from '../../../../../../../redux/features/product';

const intialValue = {
    name :""
}
export const ProductOptionModal = (props) => {
    //console.log(props);
    //props
    const {product_id,id, submitted ,changeParentProps} = props;
    const dispatch = useDispatch();
    //state
    const[values,setValues]=useState(intialValue);
    const [mount,setMount] =useState(false);
    const createOptionState = useSelector((store)=>store.product.createOption);
    const error = useSelector((store)=>store.product.createOption.error);

       

    //effects
    useEffect(()=>{
        setMount(true);
    },[mount])
    useEffect(()=>{
        if(!mount) return;
        handleAddEvent();
    },[submitted]);


   
     useEffect(() => {
        if(!mount) return;
        changeParentProps('confirmLoading',createOptionState.isLoading);
    }, [createOptionState.isLoading])

   useEffect(()=>{
    if(!mount) return;
     dispatch(viewProduct(product_id));
    // dispatch(closeModal());
   },[createOptionState.isSuccessful])

    

   //handle
   const handleChange =(e)=>{
    const name = e.target.name;
    const value = e.target.value;
    setValues({...values, [name]:value});
   }
   const handleAddEvent=()=>{
    data ={
        productId:product_id,
        value:values
    }
    dispatch(createProductOption(data));
    
   }


  return (
    <div className='container'>
        <Divider>{(id)? `EDIT OPTION` :`CREATE OPTION`}</Divider>
        <div className='row'>
               {error && (
                <div className='mt-2'>
                     <Alert message={error.message} type="error" />
                </div>
            )}
            <div className='col-md-12'>
                <label className='form-label'>Name</label>
                <Input name="name" value={values.name} onChange={(e)=>handleChange(e)}/>
            </div>
            </div>
        
        
    </div>
  )
}
