import React, { useState } from 'react'
import { IconDownload } from '../../../../../../components/icons/download';
import * as styles from './index.module.scss';
import { PropTypes } from 'prop-types';
import { Button, Modal, Button, Dropdown, Space, Tabs } from 'antd';
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Modals } from '../../modal/index';
import { showModal } from '../../../../../../../redux/features/modal';

export const DashboardComponentBodyTop = (props) => {
    const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '2',
            icon: < FilePdfOutlined />

        },
    ];

    const menuProps = {
        items,
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    // redux
    const dispatch = useDispatch();

    // handlers
    const handleShowModal = (e) => {
        // dispatch(showModal(Modals.ProductFormModal));
        if (props.onAdd) {
            props.onAdd(e);
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChange = (key) => {
        console.log(key);
    };
    const { title, link } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                <h4>{title}</h4>
            </div>
            <div className={styles.icon}>
                <div className="btn-group">
                    <Dropdown menu={menuProps}>
                        <Button style={{
                            borderRadius: '20px',
                        }}>
                            <Space>
                                <IconDownload width="16px" height="16px" />  Export

                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>

                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">{link}</a>
                    </div>
                </div>
                <div className="px-2"></div>
                <Button type="success" style={{
                    borderRadius: '20px',
                    backgroundColor: '#258E01',
                    color: '#ffffff'

                }} onClick={handleShowModal}>+</Button>



            </div>
            <div>

            </div>
        </div>
    )
}

DashboardComponentBodyTop.defaultProps = {
    links: "#",
    icon: "Products",
    onAdd: () => console.log('Add Clicked')
}

DashboardComponentBodyTop.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
}
