import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";

const GET_ORDERS = 'order/GET_ORDERS';
const CREATE_ORDER = 'order/CREATE_ORDER';
const SHOW_ORDER = 'order/SHOW_ORDER';
const DELETE_ORDER = 'order/DELETE_ORDER';
const UPDATE_ORDER = 'order/UPDATE_ORDER';
const ASSIGN_AGENT = 'order/ASSIGN_AGENT';
const CREATE_PAYMENT = 'order/CREATE_PAYMENT';
const CHECK_PAYMENT_STATUS = 'order/CHECK_PAYMENT_STATUS';

const initialState = {
    orders: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    assignAgent: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    createPayment: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    checkPaymentStatus: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    }
}

export const getOrders = createAsyncThunk(GET_ORDERS, async (data = {}, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.orders, data.params, token, store);
})

export const createOrder = createAsyncThunk(CREATE_ORDER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.orderCreate, data.payload, token, store)
})

export const showOrder = createAsyncThunk(SHOW_ORDER, async (data, store) => {
    console.log("review data", data);
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.orderShow(data), null, token, store)
})
export const deleteOrder = createAsyncThunk(DELETE_ORDER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('DELETE', GraceApi.orderDelete(data), null, token, store)
})

export const updateOrder = createAsyncThunk(UPDATE_ORDER, async (data, store) => {

    const { token } = store.getState().authentication;
    return await customFetch('PUT', GraceApi.orderUpdate(data.id), data.payload, token, store)
});

export const assignAgent = createAsyncThunk(ASSIGN_AGENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.orderAssignAgent, data.payload, token, store);
});

export const createPayment = createAsyncThunk(CREATE_PAYMENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.orderCreatePayment(data.id), data.payload, token, store);
})

export const checkPaymentStatus = createAsyncThunk(CHECK_PAYMENT_STATUS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.checkPaymentStatus(data.id), null, token, store);
})

const orderSlice = createSlice({
    name: 'order',
    initialState,
    extraReducers: {
        //get orders
        [getOrders.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
            state.orders = [];
        },
        [getOrders.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getOrders.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload.data;
            state.orders = action.payload.data;

        },
        //create order
        [createOrder.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;

        },
        [createOrder.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;

        },
        [createOrder.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload;
            state.orders.unshift(action.payload.data);
        },
        //show Order
        [showOrder.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;
            state.view.data = {};

        },
        [showOrder.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showOrder.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;
        },
        //delete order

        [deleteOrder.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = null;

        },
        [deleteOrder.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = action.error

        },
        [deleteOrder.fulfilled]: (state, action) => {
            state.delete.isLoading = false
            state.delete.isSuccessful = true
            state.delete.data = action.payload.data,
            state.delete.error = null;
            state.orders = state.orders.filter(order => order.id != action.meta.arg);
        },
        //update order
        [updateOrder.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;
        },
        [updateOrder.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;
        },
        [updateOrder.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload.data;
            const value = state.orders.find(order => order.id === action.payload.id);
            if (value > -1) {
                state.orders['value'] = action.payload.value;
            }
        },
        [assignAgent.pending]: (state, action) => {
            state.assignAgent.isLoading = true;
            state.assignAgent.isSuccessful = false;
            state.assignAgent.error = null;
            state.assignAgent.data = {};
        },
        [assignAgent.fulfilled]: (state, action) => {
            state.assignAgent.isLoading = false;
            state.assignAgent.isSuccessful = true;
            state.assignAgent.error = null;
            state.assignAgent.data = action.payload;
        },
        [assignAgent.rejected]: (state, action) => {
            state.assignAgent.isLoading = false;
            state.assignAgent.isSuccessful = false;
            state.assignAgent.error = action.payload ?? action.error;
            state.assignAgent.data = {};
        },
        [createPayment.pending]: (state, action) => {
            state.createPayment.isLoading = true;
            state.createPayment.isSuccessful = false;
            state.createPayment.error = null;
            state.createPayment.data = {};
        },
        [createPayment.fulfilled]: (state, action) => {
            state.createPayment.isLoading = false;
            state.createPayment.isSuccessful = true;
            state.createPayment.error = null;
            state.createPayment.data = action.payload;
        },
        [createPayment.rejected]: (state, action) => {
            state.createPayment.isLoading = false;
            state.createPayment.isSuccessful = false;
            state.createPayment.error = action.payload ?? action.error;
            state.createPayment.data = {};
        },
        [checkPaymentStatus.pending]: (state, action) => {
            state.checkPaymentStatus.isLoading = true;
            state.checkPaymentStatus.isSuccessful = false;
            state.checkPaymentStatus.error = null;
            state.checkPaymentStatus.data = {};
        },
        [checkPaymentStatus.fulfilled]: (state, action) => {
            state.checkPaymentStatus.isLoading = false;
            state.checkPaymentStatus.isSuccessful = true;
            state.checkPaymentStatus.error = null;
            state.checkPaymentStatus.data = action.payload;
        },
        [checkPaymentStatus.rejected]: (state, action) => {
            state.checkPaymentStatus.isLoading = false;
            state.checkPaymentStatus.isSuccessful = false;
            state.checkPaymentStatus.error = action.payload ?? action.error;
            state.checkPaymentStatus.data = {};
        },
    }
})
export default orderSlice.reducer;