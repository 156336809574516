@import '$assets/scss/main.scss';

.wrapper {
    height: 100%;
    width: 100%;

    .logo {
        height: 65px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: flex-end;

        h1 {
            all: unset;
            // font-family: Electrolux Sans;
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0.5242448449134827px;
            text-align: left;
        }
    }

    .menu {
        height: calc(100% - 65px);
        width: 100%;
        padding-top: 31px;
        overflow-y: scroll;

        .menuItem {
            all: unset;
            width: 100%;
            padding: 18px 28px;

            cursor: pointer;
            
            &.active {
                background-color: $primary;
                color: #ffffff;
            }
    
            display: flex;
            align-items: center;
    
            
    
            .menuItemText {
                all: unset;
                margin-left: 13px;
                font-family: 'Roboto', sans-serif;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.25px;
                text-align: left;
            }
        }
    }
}