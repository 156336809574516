import React, { useState } from 'react'
import * as styles from './index.module.scss'
import background from '../../../../../assets/images/woman.png';
import { Input, Button, Card, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../../redux/features/authentication';
import { useNavigate } from 'react-router-dom';
import Colors from '../../../../../assets/constants/color';



const initialValues = {
    email: "",
    password: "",
}
export const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [values, setValues] = useState(initialValues);
    const [errorMessage, setErrorMessage] = useState("");
    const loginState = useSelector((state) => state.authentication.login);

    useState(() => {
        if (loginState.error) {
            setErrorMessage(loginState.error);
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }

    }, [loginState])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setValues({ ...values, [name]: value });

    }
    const handleSubmit = () => {
        console.log("input", values)
        // const { email, password } = values;
        dispatch(login(values));
        // console.log(email, password);
    }

    return (
            <div className={`${styles.wrapper}`} style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className={styles.login}>
                                <h1>GRACE PRODUCTS</h1>
                                
                                <Card className={styles.card}>
                                    <div className="py-4"></div>
                                    <h2>Login To Your Account</h2>
                                    {errorMessage && <div class="alert alert-danger">{errorMessage}</div>}
                                    <label className="form-label">Email</label>
                                    <Input size="large" name="email" value={values.email} onChange={handleChange} style={{ borderRadius: "14px" }} placeholder="Email" />
                                    <label className="form-label">Password</label>
                                                    <Input.Password
                                                        name="password"
                                                        value={values.password}
                                                        style={{ borderRadius: "14px" }}
                                                        size='large'
                                                        placeholder="Password"
                                                        onChange={handleChange}
                                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                                    />
                                    <div className='d-flex mt-3'>
                                        <div className="mb-3 d-flex flex-grow-1">
                                            <input type="checkbox" className='form-check-input' />
                                            <div className="px-1"></div>
                                            <span className="form-check-label">Remember Me</span>
                                        </div>
                                        <p className='text-primary' style={{ cursor: "pointer" }}>Forgot Password?</p>
                                    </div>
                                    <Button
                                        onClick={handleSubmit}
                                        style={{
                                            backgroundColor: Colors.primary,
                                            borderRadius: '14px',
                                            color: '#fff',
                                            height: "40px"
                                        }} block> Login {loginState.isLoading && <span className='spinner-border spinner-border-sm mx-2'></span>}
                                    </Button>
                                        <div className="py-4"></div>
                                    <p className='text-center'>Don't have an account? <span className='text-primary' onClick={() => navigate('/auth/register')}>Sign Up</span></p>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
    )
}
