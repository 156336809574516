import React from 'react'

export const IconSetting = (props) => {
    return (
        <div>
            <svg viewBox="0 0 16 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 9.51526C0.335786 9.51526 0 9.85689 0 10.2783C0 10.6997 0.335786 11.0414 0.75 11.0414H6.38888C6.8031 11.0414 7.13888 10.6997 7.13888 10.2783C7.13888 9.85689 6.8031 9.51526 6.38888 9.51526H0.75ZM15.2492 11.0414H10.3607V12.737C10.3607 13.1584 10.0249 13.5 9.61072 13.5C9.1965 13.5 8.86072 13.1584 8.86072 12.737V10.3024C8.86047 10.2944 8.86035 10.2864 8.86035 10.2783C8.86035 10.2703 8.86047 10.2622 8.86072 10.2542V7.81956C8.86072 7.39814 9.1965 7.05651 9.61072 7.05651C10.0249 7.05651 10.3607 7.39814 10.3607 7.81956V9.51526H15.2492C15.6634 9.51526 15.9992 9.85689 15.9992 10.2783C15.9992 10.6997 15.6634 11.0414 15.2492 11.0414ZM7.25 3.72163C7.25 3.30022 7.58579 2.95859 8 2.95859L15.25 2.95859C15.6642 2.95859 16 3.30022 16 3.72163C16 4.14305 15.6642 4.48468 15.25 4.48468L8 4.48468C7.58579 4.48468 7.25 4.14305 7.25 3.72163ZM0.75 2.95859C0.335786 2.95859 0 3.30022 0 3.72163C0 4.14305 0.335786 4.48468 0.75 4.48468H4.02734V6.18041C4.02734 6.60182 4.36313 6.94345 4.77734 6.94345C5.19156 6.94345 5.52734 6.60182 5.52734 6.18041V3.74774C5.52763 3.73907 5.52777 3.73037 5.52777 3.72163C5.52777 3.7129 5.52763 3.7042 5.52734 3.69553V1.26301C5.52734 0.841589 5.19156 0.499963 4.77734 0.499963C4.36313 0.499963 4.02734 0.841589 4.02734 1.26301V2.95859H0.75Z" fill="#0D0D0D" />
            </svg>


        </div>
    )
}
