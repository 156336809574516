import React from 'react'
import { IconDashboard } from '../../../../../../components/icons/dashboard';
import { IconDash } from '../../../../../../components/icons/dashdash';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { Segmented } from 'antd';

import * as styles from './index.module.scss';
export const DashboardComponentTabs = () => {
    return (
        <div className={styles.wrapper}>

            <nav className={`nav ${styles.nav}`}>
                <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                <a className={`nav-link ${styles.link}`} href="#">Available</a>
                <a className={`nav-link ${styles.link}`} href="#">Disabled</a>

            </nav>
            <div className="flex-grow-1"></div>
            <div className={styles.alginLeft}>
             <Segmented
            
            options={[
              {
            value: 'List',
            icon: <BarsOutlined />,
              },
             {
            value: 'Kanban',
           icon: <AppstoreOutlined />,
      },
    ]}
  />
            </div>
        </div >
    )
}

