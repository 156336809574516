import React from 'react'

export const IconShop = (props) => {
    return (
        <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.32573 0.3C2.46737 0.111146 2.68966 0 2.92573 0H11.6264C11.8625 0 12.0848 0.111146 12.2264 0.3L14.4016 3.20022C14.4989 3.33005 14.5516 3.48795 14.5516 3.65022V13.801C14.5516 14.3845 14.3198 14.9441 13.9072 15.3567C13.4946 15.7693 12.935 16.0011 12.3515 16.0011H2.20067C1.61717 16.0011 1.05756 15.7693 0.644958 15.3567C0.232357 14.9441 0.000560164 14.3845 0.000560164 13.801V3.67887C0.000187857 3.66917 0 3.65941 0 3.64962C0 3.46806 0.0645101 3.30157 0.171859 3.17183L2.32573 0.3ZM1.50056 13.801V4.39962H13.0516V13.801C13.0516 13.9867 12.9778 14.1648 12.8465 14.2961C12.7152 14.4274 12.5371 14.5011 12.3515 14.5011H2.20067C2.01499 14.5011 1.83691 14.4274 1.70562 14.2961C1.57432 14.1648 1.50056 13.9867 1.50056 13.801ZM11.2514 1.5L12.3011 2.89962H2.25102L3.30073 1.5H11.2514ZM5.12528 6.55004C5.12528 6.13582 4.78949 5.80004 4.37528 5.80004C3.96107 5.80004 3.62528 6.13582 3.62528 6.55004C3.62528 7.51814 4.00986 8.44658 4.69441 9.13113C5.37896 9.81568 6.30741 10.2003 7.27551 10.2003C8.2436 10.2003 9.17205 9.81568 9.8566 9.13113C10.5412 8.44658 10.9257 7.51814 10.9257 6.55004C10.9257 6.13582 10.5899 5.80004 10.1757 5.80004C9.76152 5.80004 9.42573 6.13582 9.42573 6.55004C9.42573 7.12031 9.19919 7.66723 8.79594 8.07047C8.3927 8.47372 7.84578 8.70026 7.27551 8.70026C6.70523 8.70026 6.15831 8.47372 5.75507 8.07047C5.35182 7.66723 5.12528 7.12031 5.12528 6.55004Z" />
        </svg>

    )
}
