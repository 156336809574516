import { Divider, Input, Checkbox } from 'antd'
import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../../../../../redux/features/modal';
import { showPaymentMethods, updatePaymentMethods, createPaymentMethods } from '../../../../../../../redux/features/payment_methods';


const initialState = {
    name: '',
    description: '',
    active: ''
}
export const PaymentMethodModalEdit = (props) => {
    console.log("The porps values",props)
    const { id, submitted,changeParentProps } = props;
     const { TextArea } = Input;
    // state
    const [mounted, setMounted] = useState(false);
    const [values, setValues] = useState(initialState);
    const [paymentMethod, setPaymentMethod] = useState({});

   //redux
    const dispatch = useDispatch();
    const viewState = useSelector((store) => store.payment_methods.view);
    const updateState = useSelector((store) => store.payment_methods.update);
    const createState = useSelector((store) => store.payment_methods.create);



    // effects
    useEffect(() => {
        setMounted(true);
        setValues({
             name: '',
             description: '',
             active: ''

        })
    }, [mounted]);

    useEffect(() => {
        if (!id) return;
        dispatch(showPaymentMethods(id));
    }, [id])

    useEffect(() => {
        if(!mounted) return;
        if (!viewState.isSuccessful) return;
        setPaymentMethod(viewState.data);
        setValues({});
    }, [viewState.isSuccessful])

    useEffect(() => {

        if (!paymentMethod) return;
        console.log(' value form state :', paymentMethod);
        let payload = initialState;
        for (const field in initialState) {
            console.log("field ==" + field);
            if (field in paymentMethod) {
                console.log("dectect field ==", field, "the value",)
                payload[field] = paymentMethod[field];

            }

        }
        setValues(payload);
    }, [paymentMethod]);

    useEffect(() => {
        if (!mounted) return;
        if (id) {
            handleUpdate();
        } else {
            handleCreate();
        }
    }, [submitted]);


    useEffect(() => {
        if(!mounted) return;
        if (!createState.isSuccessful) return;
        dispatch(closeModal());
    }, [createState.isSuccessful]);

    useEffect(() => {
        if(!mounted) return;
        if (!updateState.isSuccessful) return;
        dispatch(closeModal());

    }, [updateState.isSuccessful])

 useEffect(() => {
        changeParentProps('confirmLoading', updateState.isLoading);
    }, [updateState.isLoading])

    useEffect(() => {
        changeParentProps('confirmLoading', createState.isLoading);
    }, [createState.isLoading])



    // handlers
    const handleCreate = () => {
        dispatch(createPaymentMethods(values));
    }

    const handleUpdate = () => {
        const data = {
            id: id,
            value: values
        }
        dispatch(updatePaymentMethods(data));
        updateState.isSuccessful ? dispatch(closeModal()) : null;

    }

    const handleChange = (event) => {
        // console.log("event", event);
        const value = event.target.value;
        const name = event.target.name;
        setValues({ ...values, [name]: value });
    }

    const handleChecked = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        setValues({ ...values, [name]: checked });

    }

    return (
        <div>
            <div className='contianer'>
                {  (id) ? <div>
                    <div><h4> EDIT PAYMENT METHOD </h4></div>
                </div> : <div>
                    <h4> CREATE PAYMENT METHOD </h4>
                </div>}
                <Divider />
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="form-label">
                                Name
                            </div>
                            <Input onChange={handleChange} placeholder="Name" name="name" value={values.name} />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="form-label">
                                Description
                            </div>
                            <TextArea rows={4} onChange={handleChange} name="description" value={values.description} />

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <Checkbox name="active" value={values.active} onChange={handleChecked} />  Active
                    </div>
                </div>


            </div>
        </div>
    )
}
