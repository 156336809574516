import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { DashboardPageOrderList } from './list';
export const DashboardPageOrderRoutes = () => {
    return (
        <Routes>
            <Route index element={<DashboardPageOrderList />} />
        </Routes>
    )
}
