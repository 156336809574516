.wrapper {
    width: 100%;
    height: 100%;
    display: flex;




}

.container {
    height: 45px;
    width: 100%;
    padding: 31px;

    .text {
        height: 100%;
        width: 100%;
        display: flex;

        .button {
            background-color: rgb(202, 188, 188);
            border-radius: 20px;
            color: black;
        }

    }


}

.mindash {
    margin-top: 30px;
    width: 100%;
    height: 120px;
    display: flex;
    border-radius: 20px;
    background-color: white;



    .divider {
        width: 0.12%;
        height: 100px;
        background-color: #D9D9D9;
        margin: 10px 10px;
        display: flex;
    }
}