import React from 'react'
import social from '$assets/images/social.png';
import * as styles from './index.module.scss';
import whatSapp from '$assets/images/whatSapp.png';
import twitter from '$assets/images/twitter.png';
import fb from '$assets/images/fb.png';
import ig from '$assets/images/ig.png';




export const WebHomeComponentSocials = () => {
  return (
    <div className='container my-5'>
        <h2 className='text-center'>Follow us on social media</h2>
        <p className='text-center'>@graceproducts</p>
        <div className="row my-5">
            <div className="col-lg-3">
                <div className={`img-fluid ${styles.section}` } style={{ backgroundImage: `url(${social})` }}>
                    <div className={styles.overlay}>
                        <img src={whatSapp} alt="" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className={`img-fluid ${styles.section}` } style={{ backgroundImage: `url(${social})` }}>
                    <div className={styles.overlay}>
                        <img src={twitter} alt="" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className={`img-fluid ${styles.section}` } style={{ backgroundImage: `url(${social})` }}>
                    <div className={styles.overlay}>
                        <img src={fb} alt="" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className={`img-fluid ${styles.section}` } style={{ backgroundImage: `url(${social})` }}>
                    <div className={styles.overlay}>
                        <img src={ig} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
