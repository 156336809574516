import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import DashboardComponentAppLayout from '../components/layout/app';
import { RootModal } from '../components/layout/modal';
import { DashboardPageCustomerRoutes } from './customers/pages/routes';
import { DashboardDashboardPage } from './dashboard/index';
import { DashBoardPageMerchantRoute } from './merchant/Pages/routes';
import { DashboardPageOrderRoutes } from './orders/pages/routes';
import { PaymentMethodRoutes } from './payment_methods/routes';
import { DashboardPageProductRoutes } from './products/routes';
import { DashboardPageShopRoutes } from './shops/pages/routes';
import { DashboardPageLocationRoutes } from './location/routes';


import { ConfigProvider } from 'antd';
import Colors from '../../../../assets/constants/color';
import { MobileOperatorRoutes } from './mobile_operators/routes';
import { ConfigurationRoutes } from './config/routes';
import { DashboardPageMemberRouter } from './member/routes';

export const DashboardRoutes = () => {
  // redux
  const { token } = useSelector((state) => state.authentication);

  // router
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
    }
  }, [token]);

  return (
    <ConfigProvider theme={mainTheme}>
      <DashboardComponentAppLayout>
        <Routes>
          <Route index element={<DashboardDashboardPage />} />
          <Route path="/dashboard" element={<DashboardDashboardPage />} />
          <Route path="/products/*" element={<DashboardPageProductRoutes />} />
          <Route path="/orders" element={<DashboardPageOrderRoutes />} />
          <Route path="/customers" element={<DashboardPageCustomerRoutes />} />
          <Route path="/members" element={<DashboardPageMemberRouter />} />
          <Route path="/shops" element={<DashboardPageShopRoutes />} />
          <Route path="/merchants/*" element={<DashBoardPageMerchantRoute />} />
          <Route path="/payments" element={<PaymentMethodRoutes />} />
          <Route path="/mobiles" element={<MobileOperatorRoutes />} />
          <Route path="/locations/*" element={<DashboardPageLocationRoutes />} />
          <Route path="/config/*" element={<ConfigurationRoutes />} />
        </Routes>
        <RootModal />
      </DashboardComponentAppLayout>
    </ConfigProvider>
  );
}

const mainTheme = {
  token: {
    colorPrimary: Colors.primary,
  },
};