.wrapper {
    height: 65px;
    width: 100%;
    padding: 0px;


    .title {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        float: left;
        /* identical to box height */

        display: flex;
    }

    .icon {
        float: right;
        padding: 10px;
        display: flex;

    }

}