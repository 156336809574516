import React, { useEffect, useState } from 'react'
import { Divider, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createProductCategory, showProductCategory, updateProductCategory } from '../../../../../../../redux/features/product'
import { closeModal } from '../../../../../../../redux/features/modal';
const initialState = {
    logo: "",
    name: "",
    description: "",
    parent_id: ""
}
export const ProductCategoryModalCreate = (props) => {
    const { TextArea } = Input;
    const { id, submitted } = props;
    const dispatch = useDispatch();


    const [mounted, setMounted] = useState(false);
    const [values, setValues] = useState(initialState);
    const [categories, setCategories] = useState({});
    const categoryList = useSelector((store) => store.product.categories);
    const viewState = useSelector((store) => store.product.viewCategory);
    const updateState = useSelector((store) => store.product.updateCategory);


    useEffect(() => {
        setMounted(true);
    }, [mounted]);

    useEffect(() => {
        if (!mounted) return;
        if (id) {
            handleUpdate();
        } else {
            handleCreate();
        }
    }, [submitted]);

    const handleCreate = () => {
        dispatch(createProductCategory(values));
    }


    useEffect(() => {
        if (id) {
            dispatch(showProductCategory(id))
        } else {
            return;
        }

    }, [id])

    const handleUpdate = () => {
        const data = {
            id: id,
            value: values
        }
        dispatch(updateProductCategory(data));


    }


    useEffect(() => {
        if (!viewState.isSuccessful) return;

        setCategories(viewState.data);
        console.log("data received", viewState.data)
        setValues({});
    }, [viewState.isSuccessful]);


    useEffect(() => {
        console.log("data loaded", categories)
        if (!categories) return;
        console.log(' value form state :', categories);
        let payload = initialState;
        for (const field in initialState) {
            console.log("field ==" + field);
            if (field in categories) {
                console.log("dectect field ==", field, payload[field] = categories[field],)
                payload[field] = categories[field];

            }

        }
        setValues(payload);
    }, [categories]);


    useEffect(() => {
        if (!updateState.isSuccessful) return;

        dispatch(closeModal());

    }, [updateState.isSuccessful])

    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setValues({ ...values, [name]: value });
    }
    const handleChangeSelect = (e) => {
        setValues({ ...values, 'parent_id': e });
    }
    return (
        <div>
            <br />
            <div className="container">

                <div className='row'>
                    {viewState.isSuccessful ? <div>
                        <div><h4> EDIT PRODUCT CATEGORY</h4></div>
                    </div> : <div>
                        <h4>CREATE PRODUCT CATEGORY </h4>
                    </div>}

                </div>
                <Divider />
                <div className="row">
                    <div className="col-md-12">

                        <div class="mb-3">
                            <label className="form-label">Logo</label>
                            <input name='logo' className="form-control" type="file" />
                        </div>


                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <div className="form-label">
                                Name
                            </div>
                            <Input onChange={handleChange} placeholder="Name" name="name" value={values.name} />

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <div className="form-label">
                                Select Parent
                            </div>
                            <Select
                                name="parent_id"
                                defaultValue={values.parent_id}
                                onChange={handleChangeSelect}
                                style={{
                                    width: 210,
                                }}
                                options={categoryList.map(type => ({ label: type.name, value: type.id }))}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="form-label">
                                Description
                            </div>
                            <TextArea rows={4} onChange={handleChange} name="description" value={values.description} />

                        </div>
                    </div>
                </div>
                <div className="row">

                </div>



            </div>
        </div>
    )
}
