import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_AGENTS = 'agent/GET_AGENTS';
const CREATE_AGENT = 'agent/CREATE_AGENT';
const SHOW_AGENT = 'agent/SHOW_AGENT';
const DELETE_AGENT = 'agent/DELETE_AGENT';
const UPDATE_AGENT = 'agent/UPDATE_AGENT';
const GET_AGENTS_NONPARAMS= 'agent/GET_AGENTS_NONPARAMS';
const initialState = {
    agents: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    getAGentsNoParams:{
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    }

}
export const getAgents = createAsyncThunk(GET_AGENTS, async (data, store) => {
    const { token } = store.getState().authentication;
//data.params=
    return await customFetch('GET', GraceApi.agents, data.params, token, store);
})
export const getAgentsNoParams =createAsyncThunk(GET_AGENTS_NONPARAMS,async(data,store)=>{
     const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.agents, null,token,store);
})
export const createAgent = createAsyncThunk(CREATE_AGENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.agentCreate, data.payload, token, store)
})

export const showAgent = createAsyncThunk(SHOW_AGENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.agentShow(data.id), null, token, store)
})
export const deleteAgent = createAsyncThunk(DELETE_AGENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.agentDelete(data.id), null, token, store)
})

export const updateAgent = createAsyncThunk(UPDATE_AGENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.agentUpdate(data.id), null, token, store)
})
const agentSlice = createSlice({
    name: 'agent',
    initialState,
    extraReducers: {

        [getAgents.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
            state.agents = [];
        },
        [getAgents.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getAgents.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload.data;
            state.agents = action.payload.data;

        },
        [getAgentsNoParams.pending]: (state, action) => {
            state.getAGentsNoParams.isLoading = true;
            state.getAGentsNoParams.isSuccessful = false;
            state.getAGentsNoParams.error = null;
            state.getAGentsNoParams.data = {};
            state.agents = [];
        },
        [getAgentsNoParams.rejected]: (state, action) => {
            state.getAGentsNoParams.isLoading = false;
            state.getAGentsNoParams.isSuccessful = false;
            state.getAGentsNoParams.error = action.error;

        },
        [getAgentsNoParams.fulfilled]: (state, action) => {
            state.getAGentsNoParams.isLoading = false;
            state.getAGentsNoParams.isSuccessful = true;
            state.getAGentsNoParams.error = null;
            state.getAGentsNoParams.data = action.payload.data;
            state.agents = action.payload.data;

        },
        //create Agent
        [createAgent.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
            state.create.data = {};
        },
        [createAgent.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;

        },
        [createAgent.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload.data;
            state.agents = state.agents.unshift(action.payload.data);
        },
        //show Agent
        [showAgent.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;
            state.view.data = {};

        },
        [showAgent.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showAgent.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;
        },
        //delete Agent

        [deleteAgent.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = null;

        },
        [deleteAgent.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = action.error

        },
        [deleteAgent.fulfilled]: (state, action) => {
            state.delete.isLoading = false
            state.delete.isSuccessful = true
            state.delete.data = action.payload.data,
                state.delete.error = null;
            state.agents = state.agents.filter(agent => agent.id != action.meta.arg.id);
        },
        //update Agent
        [updateAgent.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;
        },
        [updateAgent.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;
        },
        [updateAgent.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload.data;
            const value = state.agents.find(agent => agent.id === action.payload.id);
            if (value > -1) {
                state.agents['value'] = action.payload.value;
            }

        },



    }
})
export default agentSlice.reducer;
