import { Card, Input } from 'antd'
import React from 'react'
import ButtonMain from '../../../../../components/buttons/Main/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMobileOperators } from '../../../../../../redux/features/mobile_operators';
import { payoutCreatePayout } from '../../../../../../redux/features/payout';
import { AuthInput } from '../../../../auth/components/form/input';

export const WithdrawPage = () => {

    // state
    const [payload, setPayload] = useState({amount: 0});
    const [errors, setErrors] = useState({});

    // redux
    const dispatch = useDispatch();
    const operators = useSelector(state => state.mobile_operators.mobileOperators);
    const { user } = useSelector((state) => state.authentication);
    const createPayoutState = useSelector((state) => state.payout.createPayout);

    // effects
    useEffect(() => {
        dispatch(getMobileOperators());
    },[]);

    useEffect(() => {
        if (createPayoutState.error) {
            if (createPayoutState.error.errors) {
                Object.keys(createPayoutState.error.errors).forEach((key) => {
                    setErrors(prev => {
                        return {...prev, [key]: createPayoutState.error.errors[key][0]}
                    });
                })
            }
        }
    }, [createPayoutState]);

    // handlers
    const handleChange = (e) => {
        // console.debug({'name': e.target.name, 'value': e.target.value})
        setPayload(prev => {
            return {...prev, [e.target.name]: e.target.value}
        });
    }

    const handleRequestPayout = () => {
        // alert(payload.amount);
        dispatch(payoutCreatePayout({
            payload: {
                member_id: user.member?.id,
                amount: payload.amount,
            }
        }))
    }
  return (
    <div className='container my-5'>
        <div className="row">
            <div className="col-lg-4">
                <h3>Withdrawal Page</h3>
                <Card>
                    <div className="form-group mb-3">
                        <AuthInput label="Amount" name="amount" error={errors.amount} onChange={handleChange} />
                        <p className="text-sm">Your account balance is {user.member.wallet}</p>
                    </div>
                    <div className="py-2"></div>
                    <ButtonMain onClick={handleRequestPayout}>Request Payout {createPayoutState.isLoading ? <span className='spinner spinner-border-sm text-white spinner-border'></span> : null}</ButtonMain>
                </Card>
            </div>
        </div>
    </div>
  )
}
