import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { DashboarPagePayementMethod } from './list'

export const PaymentMethodRoutes = () => {
    return (
        <Routes>
            <Route index element={<DashboarPagePayementMethod />} />
        </Routes>
    )
}
