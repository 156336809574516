import React from 'react'

export const IconCart = (props) => {
  const { filled } = props;
  
  if (filled) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 30" version="1.1" fill='currentColor' {...props}>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-Set-Filled" transform="translate(-466.000000, -726.000000)" fill="#000000">
                <path d="M475.97,734 L473.475,726 L467,726 C466.447,726 466,726.448 466,727 C466,727.553 466.447,728 467,728 L472,728 L474.011,734 L474,734 L476,746 C476,748.209 477.791,750 480,750 L491,750 C493.209,750 495,748.209 495,746 L498,734 L475.97,734 L475.97,734 Z M490,752 C488.896,752 488,752.896 488,754 C488,755.104 488.896,756 490,756 C491.104,756 492,755.104 492,754 C492,752.896 491.104,752 490,752 L490,752 Z M480,752 C478.896,752 478,752.896 478,754 C478,755.104 478.896,756 480,756 C481.104,756 482,755.104 482,754 C482,752.896 481.104,752 480,752 L480,752 Z" id="cart"></path>
            </g>
        </g>
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 0.329956C0.335786 0.329956 0 0.665742 0 1.07996C0 1.49417 0.335786 1.82996 0.75 1.82996H2.77148L3.30451 4.49313C3.30772 4.51341 3.31173 4.53342 3.31652 4.55313L4.4169 10.0509L4.41704 10.0516C4.51172 10.5274 4.77064 10.9548 5.14854 11.259C5.52457 11.5617 5.99456 11.7233 6.47705 11.7159H12.8703C13.3528 11.7233 13.8228 11.5617 14.1988 11.259C14.5769 10.9546 14.8359 10.527 14.9305 10.0509L14.9305 10.0509L14.9316 10.0453L15.9861 4.51576C16.0279 4.29619 15.9697 4.0694 15.8272 3.89718C15.6847 3.72496 15.4729 3.62526 15.2493 3.62526H4.66056L4.12166 0.932763C4.0515 0.582245 3.74371 0.329956 3.38624 0.329956H0.75ZM5.88794 9.75759L4.96078 5.12526H14.3428L13.4588 9.76085C13.4324 9.89076 13.3615 10.0074 13.2582 10.0905C13.1544 10.1741 13.0244 10.2186 12.8911 10.216L12.8767 10.2159H6.47065L6.45628 10.216C6.32297 10.2186 6.19301 10.1741 6.08914 10.0905C5.98528 10.0069 5.91413 9.88945 5.88816 9.75867L5.88794 9.75759ZM6.02249 14.3524C6.07288 14.3524 6.11372 14.3116 6.11372 14.2612C6.11372 14.2108 6.07288 14.17 6.02249 14.17C5.97211 14.17 5.93127 14.2108 5.93127 14.2612C5.93127 14.3116 5.97211 14.3524 6.02249 14.3524ZM4.61372 14.2612C4.61372 13.4832 5.24445 12.8524 6.02249 12.8524C6.80054 12.8524 7.43127 13.4832 7.43127 14.2612C7.43127 15.0393 6.80054 15.67 6.02249 15.67C5.24445 15.67 4.61372 15.0393 4.61372 14.2612ZM13.2722 14.3524C13.3225 14.3524 13.3634 14.3116 13.3634 14.2612C13.3634 14.2108 13.3225 14.17 13.2722 14.17C13.2218 14.17 13.1809 14.2108 13.1809 14.2612C13.1809 14.3116 13.2218 14.3524 13.2722 14.3524ZM11.8634 14.2612C11.8634 13.4832 12.4941 12.8524 13.2722 12.8524C14.0502 12.8524 14.6809 13.4832 14.6809 14.2612C14.6809 15.0393 14.0502 15.67 13.2722 15.67C12.4941 15.67 11.8634 15.0393 11.8634 14.2612Z" />
    </svg>
  )
}
