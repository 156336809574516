import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
import { updatePaymentMethods } from "./payment_methods";

const GET_MOBILE_OPERATORS = "GET_MOBILE_OPERATORS";
const CREATE_MOILE_OPERATORS = "CREATE_MOILE_OPERATORS";
const UPDATE_MOBILE_OPERATORS = "UPDATE_MOBILE_OPERATORS";
const SHOW_MOBILE_OPERATORS = "SHOW_MOBILE_OPERATORS";
const DELETE_MOBILE_OPERATORS = "DELETE_MOBILE_OPERATORS";

const initialState = {
    mobileOperators: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: [],
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    }

}

export const getMobileOperators = createAsyncThunk(GET_MOBILE_OPERATORS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.mobileOperators, null, token, store)
});
export const createMobileOperator = createAsyncThunk(CREATE_MOILE_OPERATORS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("POST", GraceApi.mobileOperatorCreate, data, token, store);
});
export const updateMobileOperator = createAsyncThunk(UPDATE_MOBILE_OPERATORS, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id, value } = data;
    return await customFetch("PUT", GraceApi.mobileOperatorUpdate(id), value, token, store)
});

export const showMobileOperator = createAsyncThunk(SHOW_MOBILE_OPERATORS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.mobileOperatorShow(data), null, token, store)
})
export const deleteMobileOperator = createAsyncThunk(DELETE_MOBILE_OPERATORS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("DELETE", GraceApi.mobileOperatorDelete(data), null, token, store)
});



const mobileOperator = createSlice({
    name: "mobileOperator",
    initialState,
    extraReducers: {
        //get
        [getMobileOperators.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {}
        },
        [getMobileOperators.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getMobileOperators.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.mobileOperators = action.payload.data;
        },
        // create
        [createMobileOperator.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
        },
        [createMobileOperator.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;
        },
        [createMobileOperator.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload;
            state.mobileOperators.unshift(action.payload.data);
        },
        [updateMobileOperator.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;

        },
        [updateMobileOperator.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;

        },
        [updateMobileOperator.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload;
            const value = state.mobileOperators.find(mobile => mobile.id === action.payload.id);
            if (value > -1) {
                state.mobileOperators['value'] = action.payload.value;
            }
        },

        //show
        [showMobileOperator.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;
            //state.view.data = {}
        },
        [showMobileOperator.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showMobileOperator.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;

        },
        //delete
        [deleteMobileOperator.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            state.delete.error = null;
            //state.delete.data = {}
        },
        [deleteMobileOperator.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            state.delete.error = action.error;
        },
        [deleteMobileOperator.fulfilled]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = true;
            state.delete.error = null;
            state.delete.data = action.payload;
            state.mobileOperators = state.mobileOperators.filter(mobile => mobile.id != action.meta.arg);
        },
        //update  

    }
})

export default mobileOperator.reducer;