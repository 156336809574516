import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthRoutes } from './auth/routes';
import { WebRoutes } from './web/pages/routes';
import { DashboardRoutes } from './dashboard/pages/routes';
import { PrivacyPage } from './web/pages/privacy';


export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route path="/dashboard/*" element={<DashboardRoutes />} />
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path="/*" element={<WebRoutes />} />
      </Routes>
    </BrowserRouter>
  )
}
