import { Routes, Route } from "react-router-dom";
import React from 'react'
import { RolePageRoutes } from "./role/routes";

export const ConfigurationRoutes = () => {
    return (
        <Routes>
            <Route path="/role/*" element={<RolePageRoutes />} />
        </Routes>
    )
}
