import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMerchantType, showMerchantType, updateMerchantType } from '../../../../../../../redux/features/merchant'
import { closeModal } from '../../../../../../../redux/features/modal'


const initialValues = {
    name: "",
    description: ""
}
export const MerchantFormModal = (props) => {
    console.log("props===passed",props);
    const { value, submitted,changeParentProps } = props;
    //state
    const [values, setValues] = useState(initialValues);
    const [mount, setMount] = useState(false);
    const [merchantType, setMerchantType] = useState({});
    //redux
     const dispatch = useDispatch();
    const merchantTypeCreateState = useSelector((store) => store.merchant.createMerchantType);
    const merchantTypeUpdateState = useSelector((store) => store.merchant.updateMerchantTypeState);
    const merchantTypeState = useSelector((store) => store.merchant.viewMerchantType);



  //effects

 useEffect(()=>{
    setMount(true);
  setValues({
      name: "",
    description: ""
      
    });
  },[mount])

    useEffect(() => {
        if(value){
        dispatch(showMerchantType(value));
        }
      
    }, [value])
    // console.log(props.value)
    useEffect(() => {
        if (!mount) return;
        if (!merchantTypeState.isSuccessful) return;
        setValues({});
        setMerchantType(merchantTypeState.data.data);

    }, [merchantTypeState.isSuccessful]);



     useEffect(() => {
        if (!mount) return;
        if (!merchantTypeCreateState.isSuccessful) return;
        dispatch(closeModal());

    }, [merchantTypeCreateState.isSuccessful]);

    useEffect(()=>{
        if (!mount) return;
        if (!merchantTypeUpdateState.isSuccessful) return;
        dispatch(closeModal());
    },[merchantTypeUpdateState.isSuccessful]);



    useEffect(() => {
         if (!mount) return;
        if (!merchantType) return;
        console.log('use value form :', merchantType);
        let payload = initialValues;
        for (const field in initialValues) {
            console.log("field ==" + field);
            if (field in merchantType) {
                console.log("dectect field =======>", field, "the value", merchantType);
                payload[field] = merchantType[field];

            }

        }
        setValues(payload);
    }, [merchantType])
    
     // console.log("the value is clicked",submitted);
       useEffect(() => {
        if (!mount) return;
        if (value) {
            handleUpdate();
           
        } else {
            handleCreate();
           
        }
    }, [submitted]);

      useEffect(() => {
        changeParentProps('confirmLoading', merchantTypeCreateState.isLoading);
    }, [merchantTypeCreateState.isLoading])

    useEffect(() => {
        changeParentProps('confirmLoading', merchantTypeUpdateState.isLoading);
    }, [merchantTypeUpdateState.isLoading])





    //handles
    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setValues({
            ...values, [name]: value
        });

    }
    const handleCreate = () => {
    
        dispatch(createMerchantType(values));
    }

    const handleUpdate = () => {
        const data = {
            id: value,
            value: values
        }
        dispatch(updateMerchantType(data))

    }

   
    return (
        <div className='container'>
            {(value) ? <> <h4>Edit MerChant </h4></> : <> <h4>New MerChant </h4></>}

            <div className="row">
                <div className="col-lg-12">
                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input type="text"
                            className="form-control"
                            onChange={handleChange}
                            value={values.name}
                            name="name" placeholder="Enter name" />

                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="mb-4">
                        <label className="form-label" >Description</label>
                        <textarea className="form-control " onChange={handleChange} value={values.description} name="description" rows="2"
                            placeholder=""> </textarea>

                    </div>
                </div>
            </div>

           

        </div>
    )
}
