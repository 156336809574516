
import { Divider, Descriptions, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showMobileOperator } from '../../../../../../../redux/features/mobile_operators'

export const MobileOperatorModalShow = (props) => {
    const dispatch = useDispatch();
    const viewState = useSelector((store) => store.mobile_operators.view);
    const [mobile, setMobile] = useState([]);

    useEffect(() => {
        console.log("pass id value: ", props.id)
        dispatch(showMobileOperator(props.id))
    }, [props.id])
    useEffect(() => {
        if (!viewState.isSuccessful) return;
        setMobile(viewState.data);
    }, [viewState.isSuccessful])

    return (
        <div>
            <div className='container'>
                <div><h4>MOBILE OPERATORS DETAILS</h4></div>
                <Divider />
                {viewState.isSuccessful ? <div>
                    <Descriptions title="" layout="vertical">
                        <Descriptions.Item label="Name">{mobile.name}</Descriptions.Item>
                        <Descriptions.Item label="Value">{mobile.value}</Descriptions.Item>
                        <Descriptions.Item label="Description">{mobile.description}</Descriptions.Item>
                        <Descriptions.Item label="Status">{(mobile.active != 0) ? <Tag color="green" key={mobile.active}>
                            
                            {"active".toUpperCase()}
                        </Tag> : <Tag color="red" key={mobile.active}>
                            {"in active".toUpperCase()}
                        </Tag>}</Descriptions.Item>
                    </Descriptions>
                </div> : <div> Loading.......</div>}

            </div>

        </div>
    )
}
