import React from 'react'

export const IconDashboard = (props) => {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 12.5H12.5L12.5 16.5H16.5V12.5ZM12.5 11H16.5C17.3284 11 18 11.6716 18 12.5V16.5C18 17.3284 17.3284 18 16.5 18H12.5C11.6716 18 11 17.3284 11 16.5V12.5C11 11.6716 11.6716 11 12.5 11Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3.5H3.5L3.5 7.5H7.5V3.5ZM16.5 3.5H12.5V7.5H16.5V3.5ZM7.5 12.5H3.5L3.5 16.5H7.5V12.5ZM7.5 2H3.5C2.67157 2 2 2.67157 2 3.5V7.5C2 8.32843 2.67157 9 3.5 9H7.5C8.32843 9 9 8.32843 9 7.5V3.5C9 2.67157 8.32843 2 7.5 2ZM16.5 2H12.5C11.6716 2 11 2.67157 11 3.5V7.5C11 8.32843 11.6716 9 12.5 9H16.5C17.3284 9 18 8.32843 18 7.5V3.5C18 2.67157 17.3284 2 16.5 2ZM3.5 11H7.5C8.32843 11 9 11.6716 9 12.5V16.5C9 17.3284 8.32843 18 7.5 18H3.5C2.67157 18 2 17.3284 2 16.5V12.5C2 11.6716 2.67157 11 3.5 11Z" />
    </svg>
  )
}
