import React from 'react'
import productSingle from '$assets/images/productSingle.png';
import productSingleA from '$assets/images/productSingleA.png';
import * as styles from './index.module.scss';
import { IconHeart } from '../../../../../../components/icons/heart';
import WebComponentPartialSimilarProducts from '../../../../components/partials/similarProducts';
import WebComponentPartialSingleProducts from '../../../../components/partials/singleProduct';


export const WebSingleComponentHero = () => {
  return (
    <div className="container">
      <WebComponentPartialSingleProducts />
       <WebComponentPartialSimilarProducts />
    </div>
    
    
    

  )
}
