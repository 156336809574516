import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import women from '$assets/images/categories/women.png';
import men from '$assets/images/categories/men.png';
import children from '$assets/images/categories/children.png';
import pregnant from '$assets/images/categories/pregnant.png';
import WebComponentPartialCategoriesItemCard from './item';
import * as styles from './index.module.scss'


const WebComponentPartialCategories = () => {
  return (
    <div>
        <h1 className='text-center'>Shop by Categories</h1>
        <p className='text-center'>Its easy to find what you are looking for through categories. Explore even more with Grace Products.</p>

        <div className='row'>
            <div className={`col-lg-8 ${styles.category}`}>
               <NavLink to="/shop">
                  <WebComponentPartialCategoriesItemCard title="For Women" description="24 Products" banner={women} />
               </NavLink>
            </div>
            <div className={`col-lg-4 ${styles.category}`}>
                <NavLink to="/shop">
                    <WebComponentPartialCategoriesItemCard title="For Men" description="36 Products" banner={men} />
                </NavLink>    
            </div>
        </div>

        <div className='row mt-3'>
            <div className={`col-lg-4 ${styles.category}`}>
                <NavLink to="/shop">
                   <WebComponentPartialCategoriesItemCard title="For Children" description="24 Products" banner={pregnant} />
                </NavLink>
            </div>
            <div className={`col-lg-8 ${styles.category}`}>
                <NavLink to="/shop">
                    <WebComponentPartialCategoriesItemCard title="For Pregnant" description="36 Products" banner={children} />
                </NavLink>
                  
            </div>
        </div>
    </div>
  )
}

export default WebComponentPartialCategories;