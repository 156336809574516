import modal from './features/modal';
import authentication from './features/authentication';
import app from './features/app';
import product from './features/product';
import order from './features/order';
import agent from './features/agent';
import member from './features/member';
import user from './features/user';
import location from './features/location';
import merchant from './features/merchant';
import payment_methods from './features/payment_methods';
import mobile_operators from './features/mobile_operators';
import role from './features/role';
import cart from './features/cart';
import payout from './features/payout';


export default {
    app,
    modal,
    authentication,
    product,
    order,
    agent,
    member,
    user,
    location,
    merchant,
    payment_methods,
    mobile_operators,
    role,
    cart,
    payout,
}