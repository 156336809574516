.footer {

    position: relative;
    padding: 80px 80px 30px 80px;
    color: white;

    .tint {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        z-index: 0;
    }

    div, span {
        z-index: 1;
    }

    h5 {
        margin-bottom: 25px;
    }

    a {
        all: unset;
        display: block;
        margin: 10px 0;
    }

    input {
        all: unset;
        border: solid 1px white;
        border-radius: 10px;
        padding: 18px;
        background-color: transparent;
    }


    .logo {
        height: 45px;
    }

    .info {
        margin: 30px 0;
    }

    .address {
        .line {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            span {
                margin-left: 10px;
            }
        }
    }
}