import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_MERCHANTS = "GET_MERCHANTS";
const CREATE_MERCHANT = "CREATE_MERCHANT";
const UPDATE_MERCHANT = "UPDATE_MERCHANT";
const DELETE_MERCHANT = "DELETE_MERCHANT";
const SHOW_MERCHANT = "SHOW_MERCHANT";



const GET_MERCHANTS_TYPE = "GET_MERCHANTS_TYPE";
const CREATE_MERCHANTS_TYPE = "CREATE_MERCHANTS_TYPE";
const UPDATE_MERCHANT_TYPE = "UPDATE_MERCHANT_TYPE";
const SHOW_MERCHANT_TYPE = "SHOW_MERCHANT_TYPE";
const DELETE_MERCHANT_TYPE = "DELETE_MERCHANT_TYPE";

const initialState = {

    merchants: [],
    merchantTypes: [],
    getMerchant: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    createMerchant: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    viewMerchant: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    deleteMerchant: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    updateMerchant: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },


    getMerchantType: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    createMerchantType: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    viewMerchantType: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    deleteMerchantTypeState: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    updateMerchantTypeState: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },




}

export const getMerchants = createAsyncThunk(GET_MERCHANTS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.merchants, null, token, store)
});
export const showMerchant = createAsyncThunk(SHOW_MERCHANT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.merchantShow(data), null, token, store)
});

export const createMerchant = createAsyncThunk(CREATE_MERCHANT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.merchantCreate,data, token, store)
})

export const updateMerchant = createAsyncThunk(UPDATE_MERCHANT, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id, value } = data;
    return await customFetch('PUT', GraceApi.merchantUpdate(id), value, token, store)
})

export const deleteMerchant = createAsyncThunk(DELETE_MERCHANT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('DELETE', GraceApi.merchantDelete(data), null, token, store)
})




export const getMembersType = createAsyncThunk(GET_MERCHANTS_TYPE, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.merchantType, null, token, store)

});


export const createMerchantType = createAsyncThunk(CREATE_MERCHANTS_TYPE, async (data, store) => {
    const { token } = store.getState().authentication;
    // const { values } = data;
    // console.log(values);
    return await customFetch('POST', GraceApi.merchantTypeCreate, data, token, store);
});
export const updateMerchantType = createAsyncThunk(UPDATE_MERCHANT_TYPE, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id, value } = data;
    return await customFetch('PUT', GraceApi.merchantTypeUpdate(id), value, token, store)

});

export const showMerchantType = createAsyncThunk(SHOW_MERCHANT_TYPE, async (data, store) => {
   // console.log("my data value:", data);
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.MerchantTypeShow(data), null, token, store)
});

export const deleteMerchantType=createAsyncThunk(DELETE_MERCHANT_TYPE, async(data,store) => {
     const { token } = store.getState().authentication;
      return await customFetch("DELETE", GraceApi.merchantTypeDelete(data),null,token,store);

})


const merchantSlice = createSlice({
    name: 'merchant',
    initialState,
    extraReducers: {
        [getMerchants.pending]: (state, action) => {
            state.getMerchant.isLoading = true;
            state.getMerchant.isSuccessful = false;
            state.getMerchant.error = null;
            state.getMerchant.data = {};
        },
        [getMerchants.rejected]: (state, action) => {
            state.getMerchant.isLoading = false;
            state.getMerchant.isSuccessful = false;
            state.getMerchant.error = action.error

        },
        [getMerchants.fulfilled]: (state, action) => {
            state.getMerchant.isLoading = false;
            state.getMerchant.isSuccessful = true;
            state.getMerchant.error = null;
            state.getMerchant.data = action.payload;
            state.merchants = action.payload.data.data;

        },
        [getMembersType.pending]: (state, action) => {
            state.getMerchantType.isLoading = true;
            state.getMerchantType.isSuccessful = false;
            state.getMerchantType.error = null;
            state.getMerchantType.data = {};

        },
        [getMembersType.rejected]: (state, action) => {
            state.getMerchantType.isLoading = false;
            state.getMerchantType.isSuccessful = false;
            state.getMerchantType.error = action.error;

        },
        [getMembersType.fulfilled]: (state, action) => {
            state.getMerchantType.isLoading = false;
            state.getMerchantType.isSuccessful = true;
            state.getMerchantType.error = null;
            state.getMerchantType.data = action.payload;
            state.merchantTypes = action.payload.data;

        },
        [createMerchantType.pending]: (state, action) => {
            state.createMerchantType.isLoading = true;
            state.createMerchantType.isSuccessful = false;
            state.createMerchantType.error = null;

        },
        [createMerchantType.rejected]: (state, action) => {
            state.createMerchantType.isLoading = false
            state.createMerchantType.isSuccessful = false;
            state.createMerchantType.error = action.error;;

        },
        [createMerchantType.fulfilled]: (state, action) => {
            state.createMerchantType.isLoading = false
            state.createMerchantType.isSuccessful = true
            state.createMerchantType.error = null;
            state.createMerchantType.data = action.payload.data;
            state.merchantTypes.unshift(action.payload.data);

        },
        [updateMerchantType.pending]: (state, action) => {
            state.updateMerchantTypeState.isLoading = true;
            state.updateMerchantTypeState.isSuccessful = false;
            state.updateMerchantTypeState.error = null;

        },
        [updateMerchantType.rejected]: (state, action) => {
            state.updateMerchantTypeState.isLoading = false;
            state.updateMerchantTypeState.isSuccessful = false;
            state.updateMerchantTypeState.error = action.error;

        },
        [updateMerchantType.fulfilled]: (state, action) => {
            state.updateMerchantTypeState.isLoading = false;
            state.updateMerchantTypeState.isSuccessful = true;
            state.updateMerchantTypeState.error = null;
            state.updateMerchantTypeState.data = action.payload;
          const index = state.merchantTypes.findIndex(u => u.id === action.payload.data.id);
            state.merchantTypes[index] = {...state.merchantTypes[index], ...action.payload.data};
           


        },
        [deleteMerchantType.pending]:(state,action)=>{
            state.deleteMerchantTypeState.isLoading=true;
            state.deleteMerchantTypeState.isSuccessful=false;
            state.deleteMerchantTypeState.error=null;
        },
         [deleteMerchantType.rejected]:(state,action)=>{
            state.deleteMerchantTypeState.isLoading=false;
            state.deleteMerchantTypeState.isSuccessful=false;
            state.deleteMerchantTypeState.error=action.error;
        }, [deleteMerchantType.fulfilled]:(state,action)=>{
            state.deleteMerchantTypeState.isLoading=false;
            state.deleteMerchantTypeState.isSuccessful=true;
            state.deleteMerchantTypeState.error=null;
            state.merchantTypes=state.merchantTypes.filter(merchantType => merchantType.id != action.meta.arg);
        },




        [showMerchantType.pending]: (state, action) => {
            state.viewMerchantType.isLoading = true;
            state.viewMerchantType.isSuccessful = false;
            state.viewMerchantType.error = null;

        },
        [showMerchantType.rejected]: (state, action) => {
            state.viewMerchantType.isLoading = false
            state.viewMerchantType.isSuccessful = false;
            state.viewMerchantType.error = action.error;

        },
        [showMerchantType.fulfilled]: (state, action) => {
            state.viewMerchantType.isLoading = false
            state.viewMerchantType.isSuccessful = true;
            state.viewMerchantType.error = null;
            state.viewMerchantType.data = action.payload;


        },

        [showMerchant.pending]: (state, action) => {
            state.viewMerchant.isLoading = true;
            state.viewMerchant.isSuccessful = false;
            state.viewMerchant.error = null;
        },
        [showMerchant.rejected]: (state, action) => {
            state.viewMerchant.isLoading = false;
            state.viewMerchant.isSuccessful = false;
            state.viewMerchant.error = action.error;
        },
        [showMerchant.fulfilled]: (state, action) => {
            state.viewMerchant.isLoading = false;
            state.viewMerchant.isSuccessful = true;
            state.viewMerchant.error = null;
            state.viewMerchant.data = action.payload.data;

        },
         [createMerchant.pending]: (state, action) => {
            state.createMerchant.isLoading = true;
            state.createMerchant.isSuccessful = false;
            state.createMerchant.error = null;
        },
        [createMerchant.rejected]: (state, action) => {
            state.createMerchant.isLoading = false;
            state.createMerchant.isSuccessful = false;
            state.createMerchant.error = action.error;
        },
        [createMerchant.fulfilled]: (state, action) => {
            state.createMerchant.isLoading = false;
            state.createMerchant.isSuccessful = true;
            state.createMerchant.error = null;
            state.createMerchant.data = action.payload.data;
            state.merchants.unshift(action.payload.data);

        },
        [updateMerchant.pending]: (state, action) => {
            state.updateMerchant.isLoading = true;
            state.updateMerchant.isSuccessful = false;
            state.updateMerchant.error = null;
        },
        [updateMerchant.rejected]: (state, action) => {
            state.updateMerchant.isLoading = false;
            state.updateMerchant.isSuccessful = false;
            state.updateMerchant.error = action.error;
        },
        [updateMerchant.fulfilled]: (state, action) => {
            state.updateMerchant.isLoading = false;
            state.updateMerchant.isSuccessful = true;
            state.updateMerchant.error = null;
            state.updateMerchant.data = action.payload.data;
           const value = state.merchants.find(val => val.id === action.payload.id);
            if (value > -1) {
                state.merchants['value'] = action.payload.value;
            }


        },
        [deleteMerchant.pending]: (state, action) => {
            state.deleteMerchant.isLoading = true;
            state.deleteMerchant.isSuccessful = false;
            state.deleteMerchant.error = null;
        },
        [deleteMerchant.rejected]: (state, action) => {
            state.deleteMerchant.isLoading = false;
            state.deleteMerchant.isSuccessful = false;
            state.deleteMerchant.error = action.error;
        },
        [deleteMerchant.fulfilled]: (state, action) => {
            state.deleteMerchant.isLoading = false;
            state.deleteMerchant.isSuccessful = true;
            state.deleteMerchant.error = null;
            state.createMerchant.data = action.payload.data;
           state.merchants= state.merchants.filter(u=> u.id != action.meta.arg);
        },





    }
})

export default merchantSlice.reducer;