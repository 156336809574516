import React, { useEffect, useState } from 'react'
import { ComponectSearchInput } from '../../../../components/form/input/search';
import { DashboardComponentTabs } from '../../../../components/layout/body/bottom';
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top';
import * as styles from './index.module.scss';
import { Button, Dropdown, Space, Divider, Table, Tag } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { showModal } from '../../../../../../../redux/features/modal';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from '../../../../../../../redux/features/product';
import { Modals } from '../../../../components/layout/modal';
import Link from 'antd/es/typography/Link';
import { getProductColumns } from './columns';

const handleMenuClick = (e) => {


  console.log('click', e);
};

const items = [
  {
    label: '1st menu item',
    key: '1',

  },

];

const menuProps = {
  items,
  onClick: handleMenuClick,
};



export const DashbaordPageProductList = () => {

  // state
  const [product, setProduct] = useState({});
  
  // redux
  const dispatch = useDispatch();
  const productState = useSelector((state) => state.product.get);
  const products = useSelector((state) => state.product.products);

  // effects
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    if (productState.isSuccessful) return;
    console.log(productState.isSuccessful);
    console.log(productState.data);
    setProduct(productState.data);

  }, [productState])

  // handlers
  const handleAddClicked = (e) => {
      e.preventDefault();
      dispatch(showModal(Modals.ProductFormModal,{},parentProps={ height:900, width:700}))
  }

  const handleAction = (action, data) => {
    switch (action) {
        case 'view':
                handleView(data.id);
                break;
            case 'edit':
                handleEdit(data.id);
                break;
            default:
                break;
    }
  }

  const handleEdit=(id) => {
    <Link to="/sku/{id}"></Link>
  }
  const handleView=(id) =>{}
  return (
    <div>
      <DashboardComponentBodyTop title="Products" onAdd={handleAddClicked} />
      <DashboardComponentTabs />
      <div className={styles.wrapper}>
        <div className={`${styles.inputSerach}`}>
          <div className='row'>
            <div className='col-md-10'>
              <ComponectSearchInput placeholder="Search product" />
            </div>
            <div className='col-md-2'>
              <Dropdown menu={menuProps}>
                <Button style={{
                  borderRadius: '20px',
                }}>
                  <Space>
                    Actions
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={styles.datatable}></div>
        <Divider />

        <Table
          columns={getProductColumns(handleAction)}
          dataSource={products}
        />
      </div>
      <div>

      </div>

    </div>
  )
}
