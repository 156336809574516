import React from 'react'

export const IconMarker = (props) => {
  return (
    <svg viewBox="0 0 23 23" fill='currentColor' xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.59375 9.34375C3.59375 5.07649 7.23274 1.4375 11.5 1.4375C15.7673 1.4375 19.4062 5.07649 19.4062 9.34375C19.4062 11.3841 18.1175 14.0129 15.5509 17.2803L15.146 17.788C15.0769 17.8735 15.0068 17.9593 14.936 18.0457L14.5008 18.5686L14.0456 19.1017L13.5701 19.645L13.0746 20.1985C12.9903 20.2916 12.9052 20.3852 12.8193 20.4792L12.2936 21.0482L12.0232 21.3366C11.7394 21.6378 11.2606 21.6378 10.9768 21.3366C10.7949 21.1435 10.6163 20.9521 10.4411 20.7624L9.92542 20.1985L9.42987 19.645L8.95445 19.1017L8.49916 18.5686L8.064 18.0457C7.99316 17.9593 7.92315 17.8735 7.85398 17.788L7.44905 17.2803C4.88253 14.0129 3.59375 11.3841 3.59375 9.34375ZM17.9688 9.34375C17.9688 5.8704 14.9734 2.875 11.5 2.875C8.02665 2.875 5.03125 5.8704 5.03125 9.34375C5.03125 11.0074 6.20899 13.3703 8.57507 16.3844L8.96918 16.8789C9.0366 16.9622 9.10489 17.0461 9.17404 17.1304L9.59942 17.6419C9.67205 17.7281 9.74556 17.8147 9.81993 17.9019L10.2766 18.4303L10.7541 18.97L11.2526 19.5209L11.5 19.7893L12.0047 19.2381L12.5028 18.6812L12.9791 18.136L13.4336 17.6026C13.5076 17.5147 13.5806 17.4273 13.6527 17.3403L14.0746 16.8246C14.2116 16.6547 14.345 16.4868 14.4747 16.3208L14.8531 15.8289C15.0981 15.505 15.3286 15.189 15.5446 14.8811L15.8578 14.4253C17.2184 12.4013 17.9198 10.7453 17.9663 9.47741L17.9688 9.34375Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1875 9.34375C7.1875 6.96202 9.11827 5.03125 11.5 5.03125C13.8817 5.03125 15.8125 6.96202 15.8125 9.34375C15.8125 11.7255 13.8817 13.6562 11.5 13.6562C9.11827 13.6562 7.1875 11.7255 7.1875 9.34375ZM14.375 9.34375C14.375 7.75593 13.0878 6.46875 11.5 6.46875C9.91218 6.46875 8.625 7.75593 8.625 9.34375C8.625 10.9316 9.91218 12.2188 11.5 12.2188C13.0878 12.2188 14.375 10.9316 14.375 9.34375Z" />
    </svg>
  )
}
