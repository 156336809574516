import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { closeModal } from '../../../../../../redux/features/modal';
import { ProductFormModal } from '../../modals/productForm';
import { useSelector, useDispatch } from 'react-redux';
import { UserFormModal } from '../../modals/userForm';
import { MerchantFormModal } from '../../modals/merchant/main';
import { MerchantFormTypeModal } from '../../modals/merchant/type';
import { AgentFormModal } from '../../modals/agents';
import { PaymentMethodModalShow } from '../../modals/paymentMethod/show';
import { PaymentMethodModalEdit } from '../../modals/paymentMethod/edit';
import { DashboardComponentModalLocationCountryForm } from '../../modals/location/country/form/index';
import { MobileOperatorModalEdit } from '../../modals/mobileOperator/edit';
import { MobileOperatorModalShow } from '../../modals/mobileOperator/show';
import { ProductCategoryModalCreate } from '../../modals/productForm/category';
import { RoleModalForm } from '../../modals/config/role/edit';
import { OrderFormModalView } from '../../modals/order/view';
import { OrderFormModalEdit } from '../../modals/order/edit';
import { userViewFormModal } from '../../modals/userForm/view';
import { MemberModalEdit } from '../../modals/member/edit';
import { ProductOptionModal } from '../../modals/productForm/option';





export class Modals {
  static ProductFormModal = 'PRODUCT_FORM';
  static UserFormModal = 'USER_FORM';
  static MerchantFormModal = 'MERCHANT_FORM';
  static MerchantFormModalType = 'MERCHANT_FORM_TYPE';
  static AgentFormModal = 'AGENT_FORM_MODAL';
  static PaymentMethodModalShow = "PAYMENT_METHOD_MODAL_SHOW";
  static PaymentMethodModalEdit = "PAYMENT_METHOD_MODAL_EDIT";
  static CountryForm = "LOCATION_COUNTRY_FORM";
  static MobileForm = "MOBILE_FORM";
  static MobileShow = "MOBILE_SHOW";
  static ProductCategory = "PRODUCT_CATEGORY";
  static RoleModalForm = "ROLE_MODAL_FORM";
  static OrderFormModalView ="OrderFormModalView";
  static OrderFormModalEdit ="OrderFormModatEdit";
  static UserViewFormModal = "USER_VIEW_FORM_MODAL";
  static MemberModalEdit  =  "MEMBER_MODAL_EDIT";
  static ProdcutOptionModal="PRODCUT_OPTION_MODAL";
}

export class ModalProps {
  static ProductFormModal = class ProductFormModal {
    static property = 'propone';
  }
  static UserFormModal = class UserFormModal { }
  static MerchantFormModal = class MerchantFormModal { 
    static props = class Porps {
      static id = "id";
    };
  }
  static MerchantFormModalType = class MerchantFormModalType { 
     static props = class Porps {
      static id = "id";
    };
  }
  static AgentFormModal = class AgentFormModal { }
  static MemberModalEdit = class MemberModalEdit {
     static props = class Porps {
      static id = "id";
    };
  }
  static PaymentMethodModalShow = class PaymentMethodModalShow { }
  static PaymentMethodModalEdit = class PaymentMethodModalEdit { }
  static ProductCategory = class ProductCategory { }
  static RoleModalForm = class RoleModalForm { }
  static ProdcutOptionModal= class ProdcutOptionModal{
      static props = class Porps {
      static id = "id";
    };

  }
  static UserViewFormModal = class UserViewFormModal{
     static props = class Porps {
      static id = "id";
    };
  }
  static OrderFormModalEdit =class OrderFormModalEdit{
      static props = class Porps {
      static id = "id";
    };
  }
  static OrderFormModalView = class OrderFormModalView {
     static props = class Porps {
      static id = "id";
    };
  }
  static MobileShow = class MobileShow {
    static props = class Porps {
      static id = "id";
    };
  }
  static MobileForm = class MobileForm {
    static props = class Porps {
      static id = "id";
    };
  }
  static CountryForm = class CountryForm {
    static props = class Props {
      static id = 'id';
    };
  };
}

export const MODAL_COMPONENTS = {
  [Modals.ProductFormModal]: ProductFormModal,
  [Modals.UserFormModal]: UserFormModal,
  [Modals.MerchantFormModal]: MerchantFormModal,
  [Modals.MerchantFormModalType]: MerchantFormTypeModal,
  [Modals.AgentFormModal]: AgentFormModal,
  [Modals.PaymentMethodModalShow]: PaymentMethodModalShow,
  [Modals.PaymentMethodModalEdit]: PaymentMethodModalEdit,
  [Modals.CountryForm]: DashboardComponentModalLocationCountryForm,
  [Modals.MobileForm]: MobileOperatorModalEdit,
  [Modals.MobileShow]: MobileOperatorModalShow,
  [Modals.ProductCategory]: ProductCategoryModalCreate,
  [Modals.RoleModalForm]: RoleModalForm,
  [Modals.OrderFormModalView]:OrderFormModalView,
  [Modals.OrderFormModalEdit]:OrderFormModalEdit,
  [Modals.UserViewFormModal]:userViewFormModal,
  [Modals.MemberModalEdit]:MemberModalEdit,
  [Modals.ProdcutOptionModal]:ProductOptionModal,
};

export const RootModal = () => {
  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(0);

  // store
  const dispatch = useDispatch();
  const modalType = useSelector((store) => store.modal.type);
  const modalProps = useSelector((store) => store.modal.modalProps);
  const parentProps = useSelector((store) => store.modal.parentProps);

  // state
  const [parentPropsState, setParentPropsState] = useState(parentProps);

  // effects
  useEffect(() => {
    if (!modalType) {
      setIsModalOpen(false);
    } else {
      if (MODAL_COMPONENTS[modalType]) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  }, [modalType]);

  useEffect(() => {
    if (parentPropsState !== parentProps) {
      setParentPropsState(parentProps);
    }
  }, [parentProps]);

  
  
  // handlers
  const handleCancel = () => {
    dispatch(closeModal());
  }


  const handleOnOk = () => {
    console.log('Handling on ok');
    setSubmitted(submitted + 1);
  }

  // handle parent props change
  const handleChangeParentProps = (key, value) => {
    setParentPropsState(prev => ({ ...prev, [key]: value }));
  }

  if (!MODAL_COMPONENTS[modalType]) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return (
    <Modal width={parentPropsState.width} height={parentPropsState.height} open={isModalOpen} onCancel={handleCancel} okText={parentPropsState.okText ?? 'Save'} confirmLoading={parentPropsState.confirmLoading} onOk={handleOnOk}>
      <SpecificModal {...modalProps} submitted={submitted} changeParentProps={handleChangeParentProps} />
    </Modal>
  )
}
