import React from 'react';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { IconSearch } from '../../../../../components/icons/search';
import { IconSetting } from '../../../../../components/icons/setting';

export const ComponectSearchInput = (props) => (

    <>
        <Input

            style={{
                borderRadius: '20px',
            }}
            placeholder={props.placeholder}
            prefix={<IconSearch width="20px" height="20px" />}
            suffix={
                <Tooltip title="Extra information">
                    <IconSetting width="20px" height="20px" />

                </Tooltip>
            }
        />

    </>
);
