@import '$assets/scss/main.scss';

.tabs {
    display: flex;
    justify-content: center;
    gap: 20px;

    .tab {
        padding: 0 10px;
        &.active {
            border-bottom: solid 2px $primary;
        }
    }
}

.items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 20px;
}