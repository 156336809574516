import React from 'react'

export const IconCustomer = (props) => {
    return (
        <svg viewBox="0 0 14 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.26924 3.73333C9.26924 4.95327 8.26683 5.96667 7 5.96667C5.73318 5.96667 4.73077 4.95327 4.73077 3.73333C4.73077 2.5134 5.73318 1.5 7 1.5C8.26683 1.5 9.26924 2.5134 9.26924 3.73333ZM10.7692 3.73333C10.7692 5.7952 9.08169 7.46667 7 7.46667C4.91832 7.46667 3.23077 5.7952 3.23077 3.73333C3.23077 1.67147 4.91832 0 7 0C9.08169 0 10.7692 1.67147 10.7692 3.73333ZM5.46342 10.0333H8.53658C10.6689 10.0333 12.5 11.851 12.5 14.2222C12.5 14.3188 12.4636 14.3929 12.4181 14.4403C12.3735 14.4867 12.33 14.5 12.2927 14.5H1.70732C1.67003 14.5 1.62647 14.4867 1.58194 14.4403C1.5364 14.3929 1.5 14.3188 1.5 14.2222C1.5 11.851 3.33108 10.0333 5.46342 10.0333ZM0 14.2222C0 11.0803 2.44606 8.53334 5.46342 8.53334H8.53658C11.5539 8.53334 14 11.0803 14 14.2222C14 15.2041 13.2356 16 12.2927 16H1.70732C0.764392 16 0 15.2041 0 14.2222Z" />
        </svg>

    )
}
