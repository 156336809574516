import React from "react";

export const PrivacyPage = () => {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-lg-8 mx-auto">
            
          <h1>Privacy Policy</h1>
          <h2>TERMS AND CONDITIONS OF USE</h2>

          <h3>General</h3>
          <p>
            Grace Product owns and operates this Website. This document governs
            your relationship with{" "}
            <a href="https://www.graceproducttz.com">
              https://www.graceproducttz.com
            </a>{" "}
            (“Website”). Access to and use of this Website and the products and
            services available through this Website (collectively, the
            “Services”) are subject to the following terms, conditions and
            notices (the “Terms of Service”). By using the Services, you are
            agreeing to all of the Terms of Service, as may be updated by us
            from time to time. You should check the website regularly to take
            notice of any changes we may have made to the Terms of Service.
          </p>
          <p>
            This Website may contain links to other websites (the “Linked
            Sites”), which are not operated by{" "}
            <a href="https://www.graceproducttz.com">
              https://www.graceproducttz.com
            </a>{" "}
            has no control over the Linked Sites and accepts no responsibility
            for them or for any loss or damage that may arise from your use of
            them. Your use of the Linked Sites will be subject to the terms of
            use and service contained within each such site.
          </p>
          <p>
            The use of the website is governed by the terms and conditions
            below, please read the terms and conditions carefully as they apply
            to all transactions between us and can only be waived in writing
            signed by us. By proceeding with a purchase with us you’re
            indicating to us that you have read, understood and agreed to enter
            into a binding agreement with us on these terms and conditions. If
            you have any questions or concerns about our terms and conditions,
            services or website or any purchase you make through the website,
            please do not hesitate to get in touch with us.
          </p>
          <p>
            While we take every care to make sure our website is accurate and up
            to date, we’re only human and from time to time, mistakes may occur
            (for example, a pricing error or inclusions error). Please note that
            when you submit an order through the website, an agreement is formed
            until we process and accept your order and send you a confirmation.
            If there’s been an error leading to your order (such as pricing
            error), we may choose not to complete the order but we’ll be sure to
            contact you to discuss your options (for example placing an order at
            the correct price).
          </p>

          <h3>Intellectual Property, Software and Content</h3>
          <p>
            The intellectual property rights in all software and content
            (including photographic images) made available to you on or through
            this Website remains the property of{" "}
            <a href="https://www.graceproducttz.com">
              https://www.graceproducttz.com
            </a>{" "}
            or its licensors and are protected by copyright laws and treaties
            around the world. All such rights are reserved by{" "}
            <a href="https://www.graceproducttz.com">
              https://www.graceproducttz.com
            </a>{" "}
            and its licensors. You may store, print and display the content
            supplied solely for your own personal use. You are not permitted to
            publish, manipulate, distribute or otherwise reproduce, in any
            format, any of the content or copies of the content supplied to you
            or which appears on this Website nor may you use any such content in
            connection with any business or commercial enterprise.
          </p>

          <h3>Terms of Sale</h3>
          <p>
            By placing an order you are offering to purchase a product on and
            subject to the following terms and conditions. All orders are
            subject to availability and confirmation of the order price.
            Dispatch times may vary according to availability and subject to any
            delays resulting from postal delays or force majeure for which we
            will not be responsible.
          </p>
          <p>
            In order to contract with https://www.graceproducttz.com you must be
            over 18 years of age.
          </p>
          <h3>Pricing &amp; Payment</h3>
          <p>
            You agree to pay us the purchase price for the services on the
            website. The prices are listed in Tanzanian Shillings In order to
            purchase you must firstly create an online account,all payment will
            be done via each customer’s/consumer or purchaser’s online account.
          </p>
          <p>
            The payment structure for all services includes full free upfront
            payment. Delivery costs will be charged in addition; such additional
            charges are clearly displayed where applicable and included in the
            ‘Total Cost’.
          </p>
          <p>
            We currently accept payment via Mobile network operator payments
            (MNO’S) e.g Mpesa,TigoPesa,Airtelmoney,HaloTel.We may offer other
            ways to pay from time to time such as electronic funds transfer into
            our nominated bank account.
          </p>
          <p>
            Once we’ve received your payment and confirmed your order, you’ll
            receive an email with your login details to access the services. If
            you have any technical problems accessing your online account or
            accessing the material which forms part of the services, please
            contact us for assistance.
          </p>
          <p>
            We may vary our prices from time to time. You agree and acknowledge
            that we may do so at any time on notice and that such notice is
            given by us posting the updated price on our website or otherwise
            notifying you.
          </p>
          <p>
            If you fail to make a scheduled payment or if any such payment is
            returned or denied for any reason ,we may terminate this agreement
            or suspend or cancel your access at our
            absolute&nbsp;discretion,unless or until payment is received.
          </p>
          <h3>Return &amp; Cancellation Policy</h3>
          <p>
            There are no refunds, but you may return any product within ………… and
            also If you wish to cancel an order, please do so as soon as
            possible.
          </p>
          <h3>Disclaimer/Limitation Liability</h3>
          <p>
            Information provided on this website is informational only and is
            not intended to diagnose, prescribe or replace the advice of medical
            professionals. It is your responsibility to check with a medical
            professional before using any products that may interfere with drugs
            or medical conditions. https://www.graceproducttz.com is not
            responsible for incorrect use of products sold or information
            conveyed on this Website.
          </p>
          <p>
            The material displayed on this Website is provided without any
            guarantees, conditions or warranties as to its accuracy. Unless
            expressly stated to the contrary to the fullest extent permitted by
            law and its suppliers, content providers and advertisers hereby
            expressly exclude all conditions, warranties and other terms which
            might otherwise be implied by statute, common law or the law of
            equity and shall not be liable for any damages whatsoever, including
            but without limitation to any direct, indirect, special,
            consequential, punitive or incidental damages, or damages for loss
            of use, profits, data or other intangibles, damage to goodwill or
            reputation, or the cost of procurement of substitute goods and
            services, arising out of or related to the use, inability to use,
            performance or failures of this Website or the Linked Sites and any
            materials posted thereon, irrespective of whether such damages were
            foreseeable or arise in contract, tort, equity, restitution, by
            statute, at common law or otherwise.
          </p>
          <p>
            We will not be liable for any loss or damage caused by a distributed
            denial-of-service attack, viruses or other technologically harmful
            material that may infect your computer equipment, computer programs,
            data or other proprietary material due to your use of this Website
            or to your downloading of any material posted on it, or on any
            website linked to it.
          </p>
          <h3>Privacy Policy</h3>
          <p>
            By using this Website, you consent to the processing described
            therein and warrant that all data provided by you is accurate and
            secured,your privacy is very important .This website uses cookies to
            collect statistics about our visitors and to help us analyse how the
            site is used.The cookies on this website may be used by and
            delivered to you by us or by third party web analytics services.
            Cookies are not used to collect any personal data.
          </p>
          <p>
            You may refuse to accept cookies from our website and delete
            existing cookies by seolecting the appropriate settings on your
            browse
          </p>

          <h3>advise,information and instructional videos</h3>
          <p>
            We may give you advise,recommendations,information,instruction or
            assistance in relation to the services and in material provided to
            you through the services,believing it to be accurate,appropriate and
            reliable at the time but we do not give any warranty of
            accuracy,appropriateness or reliability
          </p>
          <p>
            The information,advice and instruction we give are general in nature
            and is not intended to constitute or substitute for professional or
            medical advice.You should seek appropriate professional advice
            suitable for your personal circumstances if necessary.
          </p>

          <h3>Dispute resolution</h3>
          <p>
            In any case a dispute arises between us and the purchaser, we
            operate a complaints handling procedure which we use to resolve
            disputes when they first arise, incase the complaints handling
            procedures fails, the parties shall attempt to resolve any dispute
            relating to this agreement or transactions amicably (mediation or
            negotiations ).
          </p>
          <p>Tanzania Laws will be applicable in resolving such disputes.</p>
        </div>
      </div>
    </div>
  );
};
