import React from 'react'

export const IconChevronRight = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289C10.9324 5.65338 10.9047 6.22061 11.2097 6.6129L11.2929 6.70711L20.585 16L11.2929 25.2929C10.9324 25.6534 10.9047 26.2206 11.2097 26.6129L11.2929 26.7071C11.6534 27.0676 12.2206 27.0953 12.6129 26.7903L12.7071 26.7071L22.7071 16.7071C23.0676 16.3466 23.0953 15.7794 22.7903 15.3871L22.7071 15.2929L12.7071 5.29289Z"/>
    </svg>

  )
}
