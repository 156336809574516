import { Divider, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createMember, getMembers } from '../../../../../../../redux/features/member';
import { getUsers } from '../../../../../../../redux/features/user';

const initialState ={
    user_id :'',
    parent_id :'',
}
export const MemberModalEdit = (props) => {
    //const=
   const {data, submitted, changeParentProps} = props;
   //state
   const [values, setValue] = useState(initialState);
   //redux
     const dispatch = useDispatch();
     const users = useSelector((store)=>store.user.users);
     const members = useSelector((store)=>store.member.members);
     const memberStateCreate = useSelector((store)=>store.member.create);
     //effects
     useEffect(()=>{
       dispatch(getUsers());
       dispatch(getMembers());
     },[]);

     useEffect(()=>{
        if(data){
            handleUpdate(data);
        }else{
            handleCreate();
        }

     },[submitted]);
        useEffect(() => {
        changeParentProps('confirmLoading', memberStateCreate.isLoading);
    }, [memberStateCreate.isLoading])


     //handles 
     const handleChange=(value,option)=>{
       setValue({...values, user_id:value})
        //setValue({...values, parent_id:value});
        //onsole.log("console value==>===",value,option);
     }
    // console.log("================================",values)
     const handleChangeParent=(value,option)=>{
       setValue({...values, parent_id:value});
       //console.log("console value==>===",value,option);
     }
      console.log("================================",values)

     
     const handleUpdate =(data)=>{

     }
     const handleCreate =()=>{
        console.log("values =====>===my data:",values);
       dispatch(createMember(values));
     }
  return (
     <div className='container'>
            {(data) ? <> <h4>Edit Member </h4></> : <> <h4>New Member </h4></>}
            <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label"> Select Member</label>
                                <div>
                                    <Select
                                        name="user_id"
                                        defaultValue={(data) ? {label:(data.user)?data.user.name:null,value:(data.user)?data.user.id:null} : null}
                                        onSelect={(value,option)=>handleChange(value,option)}
                                        style={{
                                           width: '100%',
                                        }}
                                        options={users.map(type => ({ label: type.name, value: type.id }))}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label"> Select Parent</label>
                                <div>
                                    <Select
                                        name="parent_id"
                                        defaultValue={(data) ? {label:(data.user)?data.user.name:null,value:(data.user)?data.user.id:null} : null}
                                        onSelect={(value,option)=>handleChangeParent(value,option)}
                                        style={{
                                           width: '100%',
                                        }}
                                        options={members.map(type => ({ label:(type.user)?type.user.name:null, value:type.id }))}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
   

   
    </div>
  )
}
