.wrapper {
    width: 100%;
    height: 100%;

    padding: 0 31px;

    display: flex;
    align-items: center;

    .divider {
        width: 1px;
        height: 40px;
        background-color: #D9D9D9;
        margin: 0 31px;
    }

    .user {

        display: flex;
        align-items: center;

        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #FFD240;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 12px;
        }

        .name {
            font-size: 15px;
            font-weight: normal;
        }
    }
}