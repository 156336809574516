
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../../../redux/features/modal';
import { createUser, showUser, updateUser } from '../../../../../../redux/features/user';
import * as styles from './index.module.scss';


const initialValues = {
    name: '',
    email: '',
    phone: '',
};
export const UserFormModal = (props) => {
  
   const { value,submitted } = props;

    console.log("the value of initial props",props);
    const dispatch = useDispatch();
    const [values, setValues] = useState(initialValues);
    const [user, setUser] = useState({});
    const [mount, setMount] = useState(false);
    const showState = useSelector((store) => store.user.view);
    const update = useSelector((store) => store.user.update.isSuccessful);

    useEffect(() => {
        setMount(true);
        console.log('component mounted successfully');
    }, []);

    useEffect(() => {
        dispatch(showUser(value));
    }, [value])

    useEffect(() => {
        if (!mount) return;
        if (!showState.isSuccessful) return;
        setValues({})
        setUser(showState.data);
    }, [showState.isSuccessful]);

    useEffect(() => {
        if (!mount) return;
        if (!user) return;
        console.log('use value form :', user);
        let payload = initialValues;
        for (const field in initialValues) {
            console.log("field ==" + field);
            if (field in user) {
                console.log("dectect field ==", field, "the value",)
                payload[field] = user[field];

            }

        }
        setValues(payload);
    }, [user])

    handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setValues({ ...values, [name]: value })
    }

    useEffect(()=>{
        if(!mount) return;
        if(submitted){
            handleUpdate()
        }else{
            handleCreate()
        }
    },[submitted])


    handleCreate = () => {
        dispatch(createUser(values));
    }

    handleUpdate = () => {
        const data = {
            userId:value,
            value: values
        }
        dispatch(updateUser(data))
    }

    useEffect(() => {
        if (!update) return;
        dispatch(closeModal());

    }, [update])

    return <div className="container">
        {(value) ? <div>  <h4>Edit Account</h4>

        </div> : <div><h4>New Account</h4>

        </div>}

        <form className="row g-3">
            <hr/>
            <div className="col-md-6">
                <label for="inputEmail4" className="form-label">Name</label>
                <input type="text" name="name" value={values.name} onChange={handleChange} className="form-control" id="inputEmail4" />
            </div>
            <div className="col-md-6">
                <label for="inputPassword4" className="form-label">Phone</label>
                <div className="input-group">
                    <div className="input-group-text">+255</div>
                    <input type="number" name="phone" value={values.phone} onChange={handleChange} className="form-control" />
                </div>

            </div>
            <div className="col-12">
                <label for="inputAddress" className="form-label">Email</label>
                <div className="input-group">
                    <div className="input-group-text">@</div>
                    <input type="email" name="email" value={values.email} onChange={handleChange} className="form-control" placeholder="" />
                </div>

            </div>
        </form>

    </div>
}