import { Button, Dropdown, Table, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { deleteMobileOperator, getMobileOperators } from '../../../../../../redux/features/mobile_operators';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardComponentBodyTop } from '../../../components/layout/body/top';
import { Space, Dropdown, Image, Tag } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modals } from '../../../components/layout/modal';
import { showModal } from '../../../../../../redux/features/modal';
import moment from 'moment/moment';
import Link from 'antd/es/typography/Link';
import GraceApi from '../../../../../../api/grace/endpoint';
import { getMobileOperatorsColumns } from './columns';

export const DashboardPageMoileOperators = () => {
    // redux
    const dispatch = useDispatch();
    const mobileGetState = useSelector((store) => store.mobile_operators.get);
    const mobileOperatorData = useSelector((store) => store.mobile_operators.mobileOperators);

    // effects
    useEffect(() => {
        dispatch(getMobileOperators());
    }, [])

    // handlers
    const handleEdit = (id) => {
        dispatch(showModal(Modals.MobileForm, { id }))
        console.log("edit by", id)
    }

    const handleView = (id) => {
        dispatch(showModal(Modals.MobileShow, { id }))
        console.log("view by", id)
    }

    const handleDelete = (id) => {
        dispatch(deleteMobileOperator(id))
    }

    const handleAddClicked = (e) => {
        e.preventDefault();
        dispatch(showModal(Modals.MobileForm))
    }

    const handleAction = (action, data) => {
        console.log(action, data);
        switch (action) {
            case 'edit':
                handleEdit(data.id)
                break;
            case 'view':
                handleView(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <DashboardComponentBodyTop title="Mobile Operators" onAdd={handleAddClicked} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    columns={getMobileOperatorsColumns(handleAction)}
                                    dataSource={mobileOperatorData}
                                    loading={mobileGetState.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
