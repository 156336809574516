.wrapper {
    height: 65px;
    width: 100%;
    padding: 0px;


    .title {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        float: left;
        /* identical to box height */

        display: flex;
    }

    .icon {
        float: right;
        padding: 10px;
        display: flex;

    }

}

.navAl {
    height: 38px;
    width: 100%;
    padding: 0px;
    display: flex;

    .nav {
        float: left;
        width: 420px;
        height: 38px;
        background-color: #dddddd;
        display: flex;
        border-radius: 20px;


        .active {
            width: 140px;
            height: 38px;
            border-radius: 20px;
            text-align: center;
            color: black;
            padding: 7px;
            background-color: white;


        }

        .link {
            width: 140px;
            height: 38px;
            text-align: center;
            color: #52575c;
            padding: 7px;
        }
    }
}

.mainBody {
    background-color: white;
    width: 100%;
    height: 750px;
    border-radius: 20px;
    margin-top: 20px;

    .inputSerach {
        width: 100%;
        padding-top: 20px;
        padding-left: 6px;
        padding-right: 6px;
        flex: 1;
        margin-left: 20px;
        margin-right: 20px;
    }

    .datatable {
        padding-top: 6px;
        padding-left: 10px;
        padding-right: 10px;
    }

}