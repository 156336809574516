import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_ROLES = "GET_ROLES";
const CREATE_ROLE = "CREATE_ROLE";
const DELETE_ROLE = "DELETE_ROLE";
const SHOW_ROLE = "SHOW_ROLE";
const UPDATE_ROLE = "UPDATE_ROLE";

const GET_PERMISSIONS = "GET_PERMISSIONS";
const initialState = {
    roles: [],
    permissions: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: [],
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },


    getPermission: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: [],
    },


}

export const getRoles = createAsyncThunk(GET_ROLES, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.roles, null, token, store);
});

export const createRole = createAsyncThunk(CREATE_ROLE, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("POST", GraceApi.roleCreate, data, token, store)

}
);

export const showRole = createAsyncThunk(SHOW_ROLE, async (data, store) => {
    // const { id, value } = data;
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.roleShow(data), null, token, store)

});
export const updateRole = createAsyncThunk(UPDATE_ROLE, async (data, store) => {
    const { id, value } = data;
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.roleUpdate(id), value, token, store)

});
export const deleteRole = createAsyncThunk(DELETE_ROLE, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("POST", GraceApi.roleDelete(data), null, token, store)

});


export const getPermission = createAsyncThunk(GET_PERMISSIONS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("GET", GraceApi.permissions, null, token, store);
});

const roleSlice = createSlice({
    initialState,
    name: "role",
    extraReducers: {

        //get
        [getRoles.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
            state.roles = [];

        },
        [getRoles.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getRoles.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.roles = action.payload.data;

        },
        //
        [createRole.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;


        },
        [createRole.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.get.error = action.error;

        },
        [createRole.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.roles = action.payload.data;

        },
        [getPermission.pending]: (state, action) => {
            state.getPermission.isLoading = true;
            state.getPermission.isSuccessful = false;
            state.getPermission.error = null;
            state.getPermission.data = {};

        },
        [getPermission.rejected]: (state, action) => {
            state.getPermission.isLoading = false;
            state.getPermission.isSuccessful = false;
            state.getPermission.error = action.error;


        },
        [getPermission.fulfilled]: (state, action) => {
            state.getPermission.isLoading = false;
            state.getPermission.isSuccessful = true;
            state.getPermission.error = null;
            state.getPermission.data = action.payload;
            state.permissions = action.payload.data;

        }




    }

});

export default roleSlice.reducer;