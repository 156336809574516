import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createMerchant, getMembersType, showMerchant, updateMerchant } from '../../../../../../../redux/features/merchant';
import { closeModal } from '../../../../../../../redux/features/modal';


const initialState = {
    name: "",
    organization_type_id: "",
    parent_id: ""

}

export const MerchantFormTypeModal = (props) => {
     //console.log("Props===values",props);
     
     const {id, submitted, changeParentProps}=props;
     //state,
    const [mount, setMount]=useState(false);
    const [merchant, setMechant] = useState([]);
    const [values, setValues] = useState(initialState);

    //redux
    const dispatch = useDispatch();
    const merchantState = useSelector((store) => store.merchant.viewMerchant);
    const merchantTypeList = useSelector((store) => store.merchant.merchantTypes);
    const merchantList = useSelector((store) => store.merchant.merchants);
    const merchantCreateState=useSelector((store) => store.merchant.createMerchant);
    const merchantUpdateState=useSelector((store) => store.merchant.updateMerchant);
   //useEffect
   useEffect(()=>{
      setMount(true);
      setValues({
         name: "",
         organization_type_id: "",
         parent_id: ""
      });
       dispatch(getMembersType());
   },[mount])
    useEffect(() => {
      //  if(!mount) return;
        if(id){
      dispatch(showMerchant(id));
     
        }

    }, [id]);
       useEffect(() => {
        if(!mount) return;
        if (!merchantState.isSuccessful) return;
        setValues({
            name:''
        });
        setMechant(merchantState.data);

    }, [merchantState.isSuccessful])
  
    console.log("the values  of merchant==>",merchant);
    useEffect(() => {
        if(!mount) return;
        if (!merchant) return;
        console.log('use value form :', merchant);
        let payload = initialState;
        for (const field in initialState) {
            console.log("field ==" + field);
            if (field in merchant) {
              console.log("dectect field ==", field, "the value",merchant[field]);
                payload[field] = merchant[field];

            }

        }
        setValues(payload);

    }, [merchant]);

          useEffect(() => {
        if (!mount) return;
        if (id) {
            handleUpdate(id);
           
        } else {
            handleCreate();
           
        }
    }, [submitted]);

      useEffect(() => {
        changeParentProps('confirmLoading', merchantCreateState.isLoading);
    }, [merchantCreateState.isLoading])

    useEffect(() => {
        changeParentProps('confirmLoading', merchantUpdateState.isLoading);
    }, [merchantUpdateState.isLoading])

     useEffect(() => {
        if (!mount) return;
        if (!merchantCreateState.isSuccessful) return;
        dispatch(closeModal());

    }, [merchantCreateState.isSuccessful]);

    useEffect(()=>{
        if (!mount) return;
        if (!merchantUpdateState.isSuccessful) return;
        dispatch(closeModal());
    },[merchantUpdateState.isSuccessful]);

    //handles

    const handleChange = (event) => {
        const value = event.target.value;
    
        setValues({ ...values, ['name']: value })

    }

    const handleChangeSelect = (value,option,e) => {
        console.log("event on select", value,option,e);
       setValues({...values,['organization_type_id']: option.value});
       
    }

    const handleChangeParent=(value,option) => {
      setValues({...values, ['parent_id']: option.value});
    }

      //console.log("value of event change===>",values);
    const handleUpdate=(id) => {
        const data ={
            id:id,
            value:values
        }
      dispatch(updateMerchant(data));
    }
    const handleCreate=()=>{
        dispatch(createMerchant(values));
    }


    //console.log("data filter","===>",merchantList.filter(m => m.id !== values.parent_id).map(v => ({ label: v.name, value: v.id })));
    return (
        <div>
            <div className='container'>
                {(id)? <div> <h4>Edit Merchant</h4></div> : <div><h4>Create Merchant</h4></div>}

                <div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Merchant Name</label>
                                <div>
                                      <Input  placeholder="Merchant Name" value={values.name} onChange={handleChange} name="name" />
                                </div>
                              
                            </div>

                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label"> Select Merchant Type</label>
                                <div>
                                    <Select
                                        name="organization_type_id"
                                        defaultValue={values.organization_type_id}
                                        onSelect={(value,option,e)=>handleChangeSelect(value,option,e)}
                                        style={{
                                           width: '100%',
                                        }}
                                        options={merchantTypeList.map(type => ({ label: type.name, value: type.id }))}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-'>
                            <div className="mb-3">
                                <label className="form-label"> Select Parent Type</label>
                                <div>
                                    <Select   name="parent_id" defaultValue={values.parent_id} onSelect={(value,option)=>handleChangeParent(value,option)} style={{
                                        width: '100%',
                                    }}
                                        options={ (id) ? merchantList.filter(m => m.id == values.parent_id ).map(v => ({ label: v.name, value: v.id })):merchantList.map(v => ({ label: v.name, value: v.id }))}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
