import React from "react";
import cartItem from "$assets/images/cartItem.png";
import ButtonMain from "../../../../components/buttons/Main";
import * as styles from "./index.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMobileOperators } from "../../../../../redux/features/mobile_operators";
import GraceApi from '../../../../../api/grace/endpoint';
import { useLocation } from 'react-router';
import { Button, Modal, Radio, Result } from "antd";
import { useState } from "react";
import { checkPaymentStatus, createPayment } from '../../../../../redux/features/order';
import { IconCicleCheck } from "../../../../components/icons/circle-check";
import { useNavigate } from 'react-router-dom';
import { registrationOrderCreatePayment, reloadUser } from '../../../../../redux/features/authentication';

const WebPaymentPage = () => {
    // router
    const navigate = useNavigate();
    const location = useLocation();
    const { order, registrationOrder } = location.state;

    // state
    const [mounted, setMounted] = useState(false);
    const [payload, setPayload] = useState({mobile_operator_id: 1});
    const [showResults, setShowResults] = useState(false);
    const [counter, setCounter] = useState(59);
    const [timerId, setTimerId] = useState(null);
    const [paid, setPaid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // redux
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.authentication.user); 
    
    const operators = useSelector(
        (state) => state.mobile_operators.mobileOperators
    );
    const getMobileOperatorsState = useSelector(
        (state) => state.mobile_operators.get
    );
    const createPaymentState = useSelector(state => state.order.createPayment);
    const registrationOrderCreatePaymentState = useSelector(state => state.authentication.registrationOrderCreatePayment);
    const checkPaymentStatusState = useSelector(state => state.order.checkPaymentStatus);

    // effects
    useEffect(() => {
        setMounted(true);
        dispatch(getMobileOperators());
    }, []);

    useEffect(() => {
        if (!user) return;
        handleChange("phone_number", user.phone);
    }, [user]);

    useEffect(() => {
        if (!mounted) return;
        setShowResults(true);

        const _timerID = setInterval(() => {
            setCounter(prev => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(_timerID);
                    handleCheckPaymentStatus();
                    return prev;
                }
            });
        }, 1000)
        setTimerId(_timerID);

        return () => {
            clearInterval(_timerID);
        }
    }, [createPaymentState.isSuccessful]);

    useEffect(() => {
        if (!mounted) return;
        if (!createPaymentState.error) return;
        setErrorMessage("Something went wrong while initiating the payment");
        setTimeout(() => {
            setErrorMessage("");
        }, 10000);
    }, [createPaymentState.error]);

    useEffect(() => {
        if (!mounted) return;
        setShowResults(true);

        const _timerID = setInterval(() => {
            setCounter(prev => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(_timerID);
                    handleCheckPaymentStatus();
                    return prev;
                }
            });
        }, 1000)
        setTimerId(_timerID);

        return () => {
            clearInterval(_timerID);
        }
    }, [registrationOrderCreatePaymentState.isSuccessful]);

    useEffect(() => {
        if (!mounted) return;
        if (!checkPaymentStatusState.isSuccessful) return;
        clearInterval(timerId);

        if (checkPaymentStatusState && checkPaymentStatusState.data && checkPaymentStatusState.data.data && checkPaymentStatusState.data.data.status == 'SUCCESSFULL') {
            setPaid(true);
            clearInterval(timerId)
            dispatch(reloadUser());
        }

        setCounter(59);
        const _timerID = setInterval(() => {
            setCounter(prev => {
                if (prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(_timerID);
                    return prev;
                }
            });
        }, 1000)
        setTimerId(_timerID);

        return () => {
            clearInterval(_timerID);
        }
    }, [checkPaymentStatusState.isSuccessful]);

    const handleChange = (name, value) => {
        setPayload(prevState => ({...prevState, [name]: value}));
    }

    // handlers
    const handlePay = () => {
        if (order) {
            const data = {
                id: order.id,
                payload,
            }
            dispatch(createPayment(data));
        }

        if (registrationOrder) {
            const data = {
                id: registrationOrder.id,
                payload,
            }
            dispatch(registrationOrderCreatePayment(data));
        }
        
    }

    const handleCheckPaymentStatus = () => {
        const data = {
            id: order ? createPaymentState.data.data.id : registrationOrderCreatePaymentState.data.data.id
        }
        dispatch(checkPaymentStatus(data));
    }

    const handleMyOrders = () => {
        navigate('/my-account')
    }

    const handleHome = () => {
        navigate("/home");
    }

    return (
        <div className="container my-5">
            <div className="row">
                <div className="d-flex flex-column col-lg-6 mx-3">
                    <div className="row">
                        <h3 className="my-3 ">Select Operator</h3>
                        {errorMessage && <div className="alert alert-danger"><p>{errorMessage}</p></div>}
                        <Radio.Group onChange={(e) => handleChange("mobile_operator_id", e.target.value)} value={payload.mobile_operator_id}>
                            <div className="d-flex">
                                {operators.map(operator => (
                                    <div className={styles.operator}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <span className="text-xs">{operator.name}</span>
                                            <div className="px-1"></div>
                                            <Radio value={operator.id} />
                                        </div>
                                        
                                        <img src={`${GraceApi.domain}${operator.logo}`} alt="" />
                                    </div>
                                ))}
                            </div>
                        </Radio.Group>
                    </div>
                    <input
                        onChange={(e) => handleChange("phone_number", e.target.value)}
                        className="form-control my-5"
                        placeholder="Mobile Number"
                        aria-label="Mobile Number"
                    ></input>
                    <div className="col-lg-6">
                        <ButtonMain onClick={handlePay} className="btn-lg">Pay Now {createPaymentState.isLoading || registrationOrderCreatePaymentState.isLoading ? <span className="spinner-border spinner-border-sm"></span> : null}</ButtonMain>
                    </div>
                </div>


                <div className="col-lg-4">
                    <div className="card p-5 border-0 rounded-4 text-dark bg-light mb-3">
                        <div className="d-flex justify-content-between border-bottom">
                            <h5>Order Details</h5>
                            {/* <p>Edit cart</p> */}
                        </div>
                        {order ? (
                            <div className="card-body">
                            <p>{order.items.length} item(s)</p>
                            {order.items.map(item => (
                                <div className="d-flex justify-content-between my-1">
                                    <div className="d-flex">
                                        <img className={styles.imageCart} src={GraceApi.domain + item.product.skus.find(sku => sku.id == item.sku_id).featured_image} alt="" />
                                        <p className="mx-2">{item.quantity} x {item.product.name}</p>
                                    </div>
                                    <div>
                                        <p>TSH. {item.unit_price * item.quantity}</p>
                                    </div>
                                </div>
                                ))}
                                <div className="border-bottom"></div>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <p className="">Subtotal</p>
                                    </div>
                                    <div>
                                        <p>TSH. {order.items.reduce((carry, item) => carry + (item.unit_price * item.quantity), 0)}</p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <p className="">Coupon</p>
                                    </div>
                                    <div>
                                        <p>TSH. 0.00</p>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <p className="">Total</p>
                                    </div>
                                    <div>
                                        <p>TSH.  {order.items.reduce((carry, item) => carry + (item.unit_price * item.quantity), 0)}</p>
                                    </div>
                                </div> */}
                            </div>
                        ) : null}
                        
                        <div className="d-flex justify-content-between border-top">
                            <div className="d-flex">
                                <p className="mt-3 ms-3">Total</p>
                            </div>
                            <div>
                                <h5 className="fw-bold mt-3">TSH.  {order ? order.items.reduce((carry, item) => carry + (item.unit_price * item.quantity), 0) : null} {registrationOrder ? registrationOrder.amount : null}/=</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {showResults && (
                <div className={styles.popup}>
                    <div className={styles.dialog}>
                    <Result
                        status={paid ? "success" : "info"}
                        title={paid ? "Payment Successful" : `00:${counter}` }
                        subTitle="We are currently processing your payment."
                        extra={[
                            <Button type="primary" key="console" onClick={paid ? () => handleMyOrders() : () => handleCheckPaymentStatus()} loading={checkPaymentStatusState.isLoading}>
                                {paid ? "My Orders" : "Check Status"}
                            </Button>,
                            <Button onClick={paid ? () => handleHome() : () => setShowResults(false)} key="buy">{paid ? "Home" : "Close"}</Button>,
                        ]}
                    />
                    </div>
                </div>
            )}
        </div>
    );
};

export default WebPaymentPage;
