import { Routes, Route } from "react-router-dom";
import React from 'react';
import { DashboardPageRoleList } from "./list";

export const RolePageRoutes = () => {
    return (
        <Routes>
            <Route index element={<DashboardPageRoleList />} />
        </Routes>
    )
}
