import { PropTypes } from 'prop-types';
import React from 'react'
import { IconDashboard } from '../../../../../components/icons/dashboard';
import * as styles from './index.module.scss';

const DashboardComponentSideMenuItem = (props) => {
    const { link, icon: Icon, title  } = props;
  return (
    <div className={styles.menuItem}>
        <Icon height="20px" width="20px" />
        <div className={styles.menuItemText}>{title}</div>
    </div>
  )
}

DashboardComponentSideMenuItem.propTypes = {
    link: "#",
    icon: IconDashboard,
    title: "Menu Item",
}

DashboardComponentSideMenuItem.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.elementType,
    title: PropTypes.string.isRequired,
}

export default DashboardComponentSideMenuItem;