.wrapper {
    height: 100vh;
    width: 100vw;
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: 'Electrolux Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 70px;
        letter-spacing: 1.02833px;

        color: #FFFFFF;

        padding-top: 3rem;
        padding-bottom: 2rem;

        // max width mobile media screen
        @media (max-width: 768px) {
            font-size: 27px;
        }
    }

    h2 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 33px;
        /* identical to box height */

        text-align: center;

        /* Heading */
        color: #3F434A;

        @media (max-width: 768px) {
            font-size: 23px;
        }
    }

    .card {
        border-radius: 20px;
        width: 540px;
        & > div {
            margin: 0;
            padding: 0;
            padding: 0 3rem;

            @media (max-width: 768px) {
                padding: 0 2rem;
            }
        }

        // max width mobile media screen
        @media (max-width: 768px) {
            width: 100%;
        }
    }
}