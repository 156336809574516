import moment from "moment/moment";
import { ComponentTableActions } from "../../../../components/table/actions";

export const getMerchantColums=(action=(action,data)=>console.log(action,data))=>{
    return (columns = [
        {
            title: '',
            dataIndex: 'id',

        },
        {
            title: 'NAME',
            dataIndex: 'name',

        },
        {
            title: 'MERCHANT TYPE',
            dataIndex: 'organizationtypes',
            render: (obj) => obj ? obj.name : null,

        },

        {
            title: 'PARENT',
            dataIndex: 'parent',
            render:(_,{parent})=>{
                return parent ? parent.name : null;
            }


        },


        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'ACTION',
            render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },

    ]);
}