import React from 'react'
import WebComponentItemProduct from '../../items/product'
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../../../../../redux/features/product';
import { useNavigate } from 'react-router-dom';
import React ,{useEffect,useState}from 'react'
// import ButtonMain from '../../../../../../components/buttons/Main';


const WebComponentPartialSimilarProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector((store) =>store.product.products)
  const [activeCategory, setActiveCategory] = useState(null);
  
  const navigate = useNavigate();

  useEffect (() =>{
      dispatch(getProducts());
  }, []);
  return (
    <div className='container'>
      <h3 className='text-center my-5'>Popular Products</h3>
      <div className="row">
        { products.filter(p => activeCategory ?  p.categories.some(c => c.id == activeCategory) : true).map((value)=>(
                         <div className='col-lg-3' key={value.id} >
                             <WebComponentItemProduct product={value}/>
                         </div>
                    ))}

      </div>
    </div>
  )
}

export default WebComponentPartialSimilarProducts
