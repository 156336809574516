import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { DashboardPageMerchantList } from './merchants'
import { DashboardPageMerchantTypeList } from './merchantType'


export const DashBoardPageMerchantRoute = () => {
    return (
        <Routes>
             <Route element={< DashboardPageMerchantTypeList />} />
            <Route index  element={<DashboardPageMerchantList />} />
           


        </Routes>
    )
}
