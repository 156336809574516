import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './ui/pages/routes';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';


export default class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={<p>Loading State...</p>} persistor={persistor}>
                    <AppRoutes />
                </PersistGate>
            </Provider>
        )
    }
}