import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteOrder, getOrders } from '../../../../../../../redux/features/order'
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table, Tag,Space, Modal } from 'antd';
import * as styles from './index.module.scss';
import { IconDownload } from '../../../../../../components/icons/download';
import { ComponectSearchInput } from '../../../../components/form/input/search'
import moment from 'moment/moment';
import Link from 'antd/es/typography/Link';
import { Modals } from '../../../../components/layout/modal';
import { showModal } from '../../../../../../../redux/features/modal';
import { OrderFormModalEdit } from '../../../../components/modals/order/edit';
import { getColumns } from './column';





export const DashboardPageOrderList = () => {


    const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '3',
            icon: < FilePdfOutlined width={50} height={50} />

        },
    ];

   

  

    const menuProps = {
        items,
    };
    const item = [
        {
            label: '1st menu item',
            key: '1',

        },

    ];
    const orderState = useSelector((store) => store.order.get);
    const orders = useSelector((store) => store.order.orders);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrders());
    }, [])


    const handleMenuClick = (e) => {
        console.log('click', e);
    }
    const menuValue = {
        item,
        //  onClick: handleMenuClick,
    };

       const handleAction = (action, data) => {
        console.log(action, data);
        switch (action) {
            case 'edit':
                handleEdit(data.id)
                break;
            case 'view':
                handleView(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }
    }


 const handleView=(id)=>{
        dispatch(showModal(Modals.OrderFormModalView,{id},parentProps = { height:900, width:800}))
        console.log("handle clicked view",id);
    }

const handleEdit =(id) => {
    dispatch(showModal(Modals.OrderFormModalEdit,{id},parentProps = { height:900, width:800}))
     console.log("handle clicked edit",id);
}

const handleDelete =(id) => {
 dispatch( deleteOrder(id));
}

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <h4>Orders</h4>
                </div>
                <div className={styles.icon}>
                    <div className="btn-group">
                        <Dropdown menu={menuProps}>
                            <Button style={{
                                borderRadius: '20px',
                                width: '130px'
                            }}>
                                <Space>
                                    <IconDownload width="16px" height="16px" />  Export
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>


                    </div>





                </div>
                <div>

                </div>
            </div>

            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Available</a>
                    <a className={`nav-link ${styles.link}`} href="#">Disabled</a>
                    <a className={`nav-link ${styles.link}`} href="#">Refunded</a>

                </nav>
            </div>

            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search order" />
                        </div>
                        <div className='col-md-2'>
                            <Dropdown menu={menuProps}>
                                <Button style={{
                                    borderRadius: '20px',
                                }}>
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table
                    
                        columns={getColumns(handleAction)}
                        dataSource={orders}
                    />
                </div>



            </div>
        </div>
    )
}
