import { Dropdown, Space, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ComponentTableActions } from "../../../../components/table/actions";
import { Link } from "react-router-dom";

export const getProductColumns = (onAction = (action, data) => console.log(action, data)) => {
    return [
        {
          title: '',
          dataIndex: 'id',
        },
        {
          title: 'PRODUCT NAME',
          dataIndex: 'name',
        },
      
        {
          title: 'CATEGORY',
          dataIndex: 'categories',
          render: (_, { categories }) => (
            <>
              {categories.map((category) => {
                let color = category.length > 5 ? 'geekblue' : 'green';
                return (
                  <Tag color={color} key={category.id}>
                    {category.name.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ) 
        },
        {
          title: 'PRICE',
          dataIndex: 'skus',
          render: (_, { skus }) => (
            <>
              {skus.map((sku) => {
                let color = sku.length > 5 ? 'geekblue' : 'green';
                return (
                  <Tag color={color} key={sku.id}>
                    {sku.price}
                  </Tag>
                );
              },  )}
            </>
          )
        },
        {
          title: 'PRODUCT SKUS',
          dataIndex: 'id',
          render:(_,{id})=>{
           return <Space size="middle">
                <Link to={'sku/'+id} >sku</Link>
              </Space>
          }
        },
        {
            title: 'Actions',
            render: (_, record) => <ComponentTableActions onAction={onAction} record={record} />
        },
      ];
}