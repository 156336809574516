
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/request';
import GraceApi from '../../api/grace/endpoint';

// action constants
const PAYOUT_CREATE_PAYOUT = 'payout/CREATE_PAYOUT';
const PAYOUT_APPROVE_PAYOUT = 'payout/APPROVE_PAYOUT';
const PAYOUT_FETCH_PAYOUTS = 'payout/FETCH_PAYOUTS';

//actions
export const payoutCreatePayout = createAsyncThunk(
    PAYOUT_CREATE_PAYOUT, 
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', GraceApi.payoutRequests, data.payload, token, store);
    }
);

export const payoutFetchPayouts = createAsyncThunk(
    PAYOUT_FETCH_PAYOUTS, 
    async (data, store) => {
        const { token } = store.authentication;
        return await customFetch('GET', GraceApi.payoutRequests, null, token, store);
    }
);

const initialState = {
    payouts: [],
    createPayout: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    approvePayout: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    fetchPayouts: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
}

const payoutSlice = createSlice({
    'name': 'payout',
    initialState,
    reducers: {},
    extraReducers: {
        [payoutCreatePayout.pending]: (state, action) => {
            state.createPayout.isLoading = true;
            state.createPayout.isSuccessful = false;
            state.createPayout.error = null;
            state.createPayout.data = {};
        },
        [payoutCreatePayout.fulfilled]: (state, action) => {
            state.createPayout.isLoading = false;
            state.createPayout.isSuccessful = true;
            state.createPayout.data = action.payload;
            state.payouts.unshift(action.payload.data);
        },
        [payoutCreatePayout.rejected]: (state, action) => {
            state.createPayout.isLoading = false;
            state.createPayout.isSuccessful = false;
            state.createPayout.error = action.payload;
        },
        [payoutFetchPayouts.pending]: (state, action) => {
            state.fetchPayouts.isLoading = true;
            state.fetchPayouts.isSuccessful = false;
            state.fetchPayouts.error = null;
            state.fetchPayouts.data = {};
        },
        [payoutFetchPayouts.fulfilled]: (state, action) => {
            state.fetchPayouts.isLoading = false;
            state.fetchPayouts.isSuccessful = true;
            state.fetchPayouts.data = action.payload;
            state.payouts = action.payload.data;
        },
        [payoutFetchPayouts.rejected]: (state, action) => {
            state.fetchPayouts.isLoading = false;
            state.fetchPayouts.isSuccessful = false;
            state.fetchPayouts.error = action.payload;
        },
    }
});

export default payoutSlice.reducer;