import React, { useEffect, useState } from 'react'
import * as styles from './index.module.scss';
import featured from '$assets/images/product-image.png';
import { IconChevronUp } from '../../../../components/icons/chevron-up';
import { IconChevronDown } from '../../../../components/icons/chevron-down';
import ButtonMain from '../../../../components/buttons/Main';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { decrementQuantity, incrementQuantity, removeItem } from '../../../../../redux/features/cart';
import { CloseOutlined } from '@ant-design/icons';
import { createOrder } from '../../../../../redux/features/order';
import GraceApi from '../../../../../api/grace/endpoint';




export const WebCartPage = () => {
    const navigate = useNavigate();

    // redux
    const dispatch = useDispatch();
    const items = useSelector((store) => store.cart.items);
    const total = useSelector((store) => store.cart.total);
    const [mount, setMount] = useState(false)
    const { user } = useSelector(store => store.authentication);
    const createOrderState = useSelector((store) => store.order.create);

    const incrementQnty = (id) => {
        dispatch(incrementQuantity(id))
    }

    const descrementQnty = (id) => {
        dispatch(decrementQuantity(id))
    }
    const removedQuantity = (id) => {
        dispatch(removeItem(id));
    }
    const getTotal = () => {
        let totalPrice = 0
        items.forEach(item => {
            totalPrice += item.product.skus[0].price * item.quantity
        })
        return totalPrice;
    }

    const getProductImage = (item) => {

        if (item.product && item.product.skus) {

            return GraceApi.domain + item.product.skus[0].featured_image;
        } else {

            return "null";
        }
    }

    // handlers
    const handleCheckout = () => {
        console.log('handling checkout', user)
        if (!user.id) {
            navigate('/auth');
            return;
        }
        const data = {
            payload: {
                user_id: user.id,
                total_amount: getTotal(),
                order_items: items.map(item => ({
                    product_id: item.product.id,
                    quantity: item.quantity,
                    sku_id: item.product.skus[0].id,
                    unit_price: item.product.skus[0].price

                }))
            }
        }

        dispatch(createOrder(data));


    }

    useEffect(() => {
        setMount(true);
    }, [])

    useEffect(() => {
        if (!mount) return;
        if (createOrderState.isSuccessful) {
            navigate("/stores", { state: { order: createOrderState.data.data } });
            return;
        }

    }, [createOrderState.isSuccessful])


    console.log("items: value: ", items);
    return (
        <div className={`${styles.cart} container`}>
            <div className="row my-5">
                <div className="col-lg">
                    <h1>Shopping Cart</h1>
                    <p>{`Home > Shopping Cart`}</p>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-lg-8">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ITEM</th>
                                    <th>NAME</th>
                                    <th>PRICE</th>
                                    <th>QUANTITY</th>
                                    <th>SUB TOTAL</th>
                                </tr>
                            </thead>

                            <tbody>
                                {items.map(item => (

                                    <tr>
                                        <td>
                                            <span className=''><CloseOutlined onClick={() => removedQuantity(item.product.id)} /></span>
                                            <span className="px-2"></span>
                                            <img src={getProductImage(item)} height="50px" width="50px" alt="" srcset="" />
                                        </td>
                                        <td>{item.product.name}</td>
                                        <td>
                                            TZS {item.product.skus[0].price}
                                        </td>
                                        <td>
                                            <div className={styles.box} onClick={() => incrementQnty(item.product.id)}>
                                                <IconChevronUp height="10" width="10px" />
                                            </div>
                                            <span className="px-2">{item.quantity}</span>
                                            <div className={styles.box} onClick={() => descrementQnty(item.product.id)}>
                                                <IconChevronDown height="10" width="10px" />
                                            </div>
                                        </td>
                                        <td>
                                            TZS {item.product.skus[0].price * item.quantity}
                                        </td>

                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="col-lg-4">
                    <div className={styles.card}>
                        <h3>Card Total</h3>
                        <hr />
                        <div className="d-flex">
                            <p className='flex-grow-1'>Subtotal</p>
                            <p>TZS {Math.round(getTotal() / 1.18).toFixed(2)}/=</p>
                        </div>
                        <div className="d-flex">
                            <p className='flex-grow-1'>Tax</p>
                            <p>TZS {Math.round((getTotal() / 1.18) * 0.18).toFixed(2)}/=</p>
                        </div>

                        <div className="d-flex mt-3">
                            <strong className='flex-grow-1'>Total</strong>
                            <strong>TZS {Math.round(getTotal()).toFixed(2)}/=</strong>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-center">
                            <ButtonMain className="btn-dark" onClick={handleCheckout}>Checkout</ButtonMain>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getPrice = (item) => {
    console.log(price);
}