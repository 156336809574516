import React from 'react'

export const IconOrder = (props) => {
    return (
        <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66459 0.0791796C7.87574 -0.0263932 8.12427 -0.0263932 8.33541 0.0791796L15.5854 3.70418C15.8395 3.83122 16 4.09092 16 4.375C16 4.65908 15.8395 4.91878 15.5854 5.04582L8.33541 8.67082C8.12427 8.77639 7.87574 8.77639 7.66459 8.67082L0.41459 5.04582C0.160502 4.91878 2.71256e-09 4.65908 2.71256e-09 4.375C2.71256e-09 4.09092 0.160502 3.83122 0.41459 3.70418L7.66459 0.0791796ZM2.42705 4.375L8 7.16147L13.5729 4.375L8 1.58853L2.42705 4.375ZM0.0793439 11.2898C0.264586 10.9193 0.715091 10.7691 1.08557 10.9543L8.00016 14.4116L14.9148 10.9543C15.2852 10.7691 15.7357 10.9193 15.921 11.2898C16.1062 11.6602 15.9561 12.1107 15.5856 12.296L8.33558 15.921C8.12443 16.0266 7.8759 16.0266 7.66475 15.921L0.414754 12.296C0.0442702 12.1107 -0.105898 11.6602 0.0793439 11.2898ZM1.08557 7.32934C0.715091 7.1441 0.264586 7.29427 0.0793439 7.66475C-0.105898 8.03524 0.0442702 8.48574 0.414754 8.67098L7.66475 12.296C7.8759 12.4016 8.12443 12.4016 8.33558 12.296L15.5856 8.67098C15.9561 8.48574 16.1062 8.03524 15.921 7.66475C15.7357 7.29427 15.2852 7.1441 14.9148 7.32934L8.00016 10.7866L1.08557 7.32934Z" />
        </svg>

    )
}

