import { lintWarning } from "./utils";

function isConcatSelector(selector) {
  var _selector$match;

  var notContent = ((_selector$match = selector.match(/:not\(([^)]*)\)/)) === null || _selector$match === void 0 ? void 0 : _selector$match[1]) || ''; // split selector. e.g.
  // `h1#a.b` => ['h1', #a', '.b']

  var splitCells = notContent.split(/(\[[^[]*])|(?=[.#])/).filter(function (str) {
    return str;
  });
  return splitCells.length > 1;
}

function parsePath(info) {
  return info.parentSelectors.reduce(function (prev, cur) {
    if (!prev) {
      return cur;
    }

    return cur.includes('&') ? cur.replace(/&/g, prev) : "".concat(prev, " ").concat(cur);
  }, '');
}

var linter = function linter(key, value, info) {
  var parentSelectorPath = parsePath(info);
  var notList = parentSelectorPath.match(/:not\([^)]*\)/g) || [];

  if (notList.length > 0 && notList.some(isConcatSelector)) {
    lintWarning("Concat ':not' selector not support in legacy browsers.", info);
  }
};

export default linter;