
import { Space, Dropdown } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const getMenu = (record, onAction = (action, data) => console.log(action, data)) => {
    return {
        items: [
            // {
            //     key: 'view',
            //     label: 'View',
            //     icon: <EyeOutlined />
            // },
            // edit
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />
            },
            // delete
            {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                danger: true
            },
        ],
        onClick: (item) => onAction(item.key, { 'id': record.id, 'payload': record}),
    }
}

export const getColumns = (onAction = (action, data) => console.log(action, data)) => {
    return [
        {
            title: 'Country Name',
            dataIndex: 'name',
        },
        {
            title: 'No of Regions',
            dataIndex: 'regions_count',
        },
        {
            title: 'Total Districts',
            dataIndex: 'districts_count',
        },
        {
            title: 'Total Wards',
            dataIndex: 'wards_count',
        },
        {
            title: 'Total Streets',
            dataIndex: 'streets_count',
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <Space size="middle">
                    <Dropdown.Button onClick={() => onAction('view', {id: record.id})} menu={getMenu(record, onAction)}>
                        View
                    </Dropdown.Button>
                </Space>
            )
        },
    ]
}