import React from 'react'
import * as styles from './index.module.scss';
import banner from '$assets/images/callout_banner.png';
export const WebHomeComponentCallout = () => {
  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${banner})` }}>
        <div className={styles.overlay}></div>
        <div className={`container ${styles.container}`}>
          <div className="row">
            <div className="col-sm-12">
                         <div>
                <h3>You deserve the Best</h3>
                <p>Subscribe and Shop Best quality product with huge discount</p>
            </div>
            <div>
                <button type="button" className="btn btn-primary">Get Started</button>
            </div> 
            </div>
          </div>

        </div>
    </div>
  )
}
