import React from 'react'
import { Navigate, Route, Routes } from 'react-router';
import { DashboardPageLocationCountryList } from './countries/list/index';
import { Route } from 'react-router-dom';
import { Wrapper, Status } from "@googlemaps/react-wrapper";


const render = (status) => {
  return <h1>{status}</h1>;
};

export const DashboardPageLocationRoutes = () => {
  return (
    <Wrapper apiKey={"AIzaSyAmeO081qQsyMCHUmWXf_1KTBpmf_Gn9eQ"} render={render}>
      <Routes>
        <Route index element={<Navigate to="countries" />} />
        <Route path="countries" element={<DashboardPageLocationCountryList />} />
      </Routes>
    </Wrapper>
  )
}
