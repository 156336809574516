import React from 'react'
import { Space, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export const getTableActions = (record, onAction = (action, data) => console.log(action, data)) => {
    return {
        items: [
            {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />
            },
            // delete
            {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                danger: true
            },
        ],
        onClick: (item) => onAction(item.key, { 'id': record.id, 'payload': record}),
    }
}

export const ComponentTableActions = (props) => {
    const { onAction, record } = props;
    return (
        <Space size="middle">
            <Dropdown.Button onClick={() => onAction('view', {id: record.id})} menu={getTableActions(record, onAction)}>
                View
            </Dropdown.Button>
        </Space>
    );
}
