import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showOrder } from '../../../../../../../redux/features/order'
import { Button, Descriptions, Radio } from 'antd';
import  * as styles from './index.module.scss';
export const OrderFormModalView = (props) => {
    console.log("props value:",props);
    const dispatch  = useDispatch();
    const order = useSelector( (store)=>store.order.view.data)
    useEffect(() =>{
      dispatch(showOrder(props.id));
    },[props.id])
  return (
    <div>
      <div className='container'>
        <div className="row">
       <div className="col p-2"><p><h3>Orders No:#000{order.id}</h3></p></div>
       <div className='col p-2'><h5>{moment(order.date).format('DD-MM-YYYY')}</h5></div>
     </div>
     <div className='row'>
      <h4>Customer</h4>
      <table className="table">
  <thead>
   
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
    </tr>
  </thead>
  <tbody>
    
    <tr>
      <th scope="row">1</th>
      <td>{order.user ? order.user.name:null}</td>
      <td>{order.user ?order.user.email:null}</td>
      <td>{order.user?order.user.phone:null}</td>
    </tr>
    </tbody>
    </table>
     </div>
     <div className='row'>
       <h4>Products</h4>
           <table className="table">
  <thead>
   
    <tr>
      <th scope="col">#</th>
      <th scope="col">Product Name</th>
      <th scope="col">Quantity</th>
      <th scope="col">Product Price</th>
      <th scope="col">Shop</th>
    </tr>
  </thead>
  <tbody>
    {order.items ? order.items.map((item)=>(
      <tr key={item.id}>
     <td>{item.product ?item.product.id :null}</td>
     <td>{item.product?item.product.name:null}</td>
     <td>{item ?item.quantity:null}</td>
     <td>{item?item.unit_price:null}</td>
     <td>{order.agent ?order.agent.company_name:null}</td>
      </tr>
    )):null}

  </tbody>
  </table>
     </div>
     <div className='row'>
      <h5>Payment Method</h5>
      <div className='col-md-6'>
           <div className='col-md-6'>
             <div class="form-group">
       <input type="text" value="Mobile Money" className="form-control" readOnly/>
        
       </div>
           </div>
          <br/>
          <address> Transaction ID :000001-TXHQ</address>
         <address>Total Amount <b>{order.total_amount}</b>/-TSH</address> 
      </div>
       <div className={`col-md-6 ${styles.module}`}>
      
        <div className="row">
       <label  className="col-sm-6">Fulfilment Status</label>
       <div className="col-sm-6">
      <input type="text" value="Picked" className="form-control" readOnly />
        
      
       </div>
      </div>
     <div className='p-2'></div>
    <div className="row">
    <label  className="col-sm-6">Payment Status</label>
    <div className="col-sm-6">
      <input type="text" value="Paid" className="form-control border-radius-20" readOnly/>
       
    </div>
  </div>
 
      
        
      </div>
     </div>

    </div>
    </div>
  )
}

