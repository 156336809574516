import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";

const AUTH_LOGIN = "auth/AUTH_LOGIN";
const AUTH_REGISTER = "auth/AUTH_REGISTER";
const CREATE_PAYMENT = "auth/CREATE_PAYMENT";
const RELOAD_USER = "auth/RELOAD_USER";
const AUTH_REQUEST_EMAIL_VERIFICATION = "auth/REQUEST_EMAIL_VERIFICATION";
const AUTH_VERIFY_EMAIL = "auth/VERIFY_EMAIL";
const AUTH_VERIFY_PHONE_OWNERSHIP = "auth/VERIFY_PHONE_OWNERSHIP";

export const login = createAsyncThunk(AUTH_LOGIN, async (data, store) => {
    return await customFetch('POST', GraceApi.login, data);
});

export const register = createAsyncThunk(AUTH_REGISTER, async (data, store) => {
    return await customFetch('POST', GraceApi.register, data, null, store);
});

export const registrationOrderCreatePayment = createAsyncThunk(CREATE_PAYMENT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.registrationCreatePayment(data.id), data.payload, token, store);
})

export const reloadUser = createAsyncThunk(RELOAD_USER, async (data, store) => {
    const { token, user } = store.getState().authentication;
    return await customFetch('GET', GraceApi.userShow(user.id), {}, token, store);
});

export const requestEmailVerification = createAsyncThunk(AUTH_REQUEST_EMAIL_VERIFICATION, async (data, store) => {
    return await customFetch('POST', GraceApi.requestEmailVerification, data, null, store);
});

export const verifyEmail = createAsyncThunk(AUTH_VERIFY_EMAIL, async (data, store) => {
    return await customFetch('POST', GraceApi.verifyEmail, data, null, store);
});

export const verifyPhoneOwnership = createAsyncThunk(AUTH_VERIFY_PHONE_OWNERSHIP, async (data, store) => {
    return await customFetch('POST', GraceApi.verifyPhoneOwnership, data, null, store);
})

const initialState = {
    token: null,
    user: {},
    permissions: {},
    login: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    register: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    registrationOrderCreatePayment: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    reloadUser: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    requestEmailVerification: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    verifyEmail: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    verifyPhoneOwnership: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
}
const authorizationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: (state, action) => {
            state.token = null;
            state.user = {};
            state.permissions = {};
        }
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.login.isLoading = true;
            state.login.isSuccessful = false;
            state.login.error = null;
            state.login.user = {};

        },
        [login.rejected]: (state, action) => {
            state.login.isLoading = false;
            state.login.isSuccessful = false;
            state.login.error = action.error;
        },
        [login.fulfilled]: (state, action) => {
            state.login.isLoading = false;
            state.login.isSuccessful = true;
            state.login.error = null;
            state.login.data = action.payload;
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        [register.pending]: (state, action) => {
            state.register.isLoading = true;
            state.register.isSuccessful = false;
            state.register.error = null;
            state.register.user = {};

        },
        [register.fulfilled]: (state, action) => {
            state.register.isLoading = false;
            state.register.isSuccessful = true;
            state.register.error = null;
            state.register.data = action.payload;
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        [register.rejected]: (state, action) => {
            state.register.isLoading = false;
            state.register.isSuccessful = false;
            state.register.error = action.payload ?? action.error;
        },
        [registrationOrderCreatePayment.pending]: (state, action) => {
            state.registrationOrderCreatePayment.isLoading = true;
            state.registrationOrderCreatePayment.isSuccessful = false;
            state.registrationOrderCreatePayment.error = null;
            state.registrationOrderCreatePayment.data = {};
        },
        [registrationOrderCreatePayment.fulfilled]: (state, action) => {
            state.registrationOrderCreatePayment.isLoading = false;
            state.registrationOrderCreatePayment.isSuccessful = true;
            state.registrationOrderCreatePayment.error = null;
            state.registrationOrderCreatePayment.data = action.payload;
        },
        [registrationOrderCreatePayment.rejected]: (state, action) => {
            state.registrationOrderCreatePayment.isLoading = false;
            state.registrationOrderCreatePayment.isSuccessful = false;
            state.registrationOrderCreatePayment.error = action.payload ?? action.error;
        },
        [reloadUser.pending]: (state, action) => {
            state.reloadUser.isLoading = true;
            state.reloadUser.isSuccessful = false;
            state.reloadUser.error = null;
            state.reloadUser.data = {};
        },
        [reloadUser.fulfilled]: (state, action) => {
            state.reloadUser.isLoading = false;
            state.reloadUser.isSuccessful = true;
            state.reloadUser.error = null;
            state.reloadUser.data = action.payload;
            state.user = action.payload.data.user
        },
        [reloadUser.rejected]: (state, action) => {
            state.reloadUser.isLoading = false;
            state.reloadUser.isSuccessful = false;
            state.reloadUser.error = action.payload ?? action.error;
        },
        [requestEmailVerification.pending]: (state, action) => {
            state.requestEmailVerification.isLoading = true;
            state.requestEmailVerification.isSuccessful = false;
            state.requestEmailVerification.error = null;
            state.requestEmailVerification.data = {};
        },
        [requestEmailVerification.fulfilled]: (state, action) => {
            state.requestEmailVerification.isLoading = false;
            state.requestEmailVerification.isSuccessful = true;
            state.requestEmailVerification.error = null;
            state.requestEmailVerification.data = action.payload;
        },
        [requestEmailVerification.rejected]: (state, action) => {
            state.requestEmailVerification.isLoading = false;
            state.requestEmailVerification.isSuccessful = false;
            state.requestEmailVerification.error = action.payload ?? action.error;
        },
        [verifyEmail.pending]: (state, action) => {
            state.verifyEmail.isLoading = true;
            state.verifyEmail.isSuccessful = false;
            state.verifyEmail.error = null;
            state.verifyEmail.data = {};
        },
        [verifyEmail.fulfilled]: (state, action) => {
            state.verifyEmail.isLoading = false;
            state.verifyEmail.isSuccessful = true;
            state.verifyEmail.error = null;
            state.verifyEmail.data = action.payload;
        },
        [verifyEmail.rejected]: (state, action) => {
            state.verifyEmail.isLoading = false;
            state.verifyEmail.isSuccessful = false;
            state.verifyEmail.error = action.payload ?? action.error;
        },
        [verifyPhoneOwnership.pending]: (state, action) => {
            state.verifyPhoneOwnership.isLoading = true;
            state.verifyPhoneOwnership.isSuccessful = false;
            state.verifyPhoneOwnership.error = null;
            state.verifyPhoneOwnership.data = {};
        },
        [verifyPhoneOwnership.fulfilled]: (state, action) => {
            state.verifyPhoneOwnership.isLoading = false;
            state.verifyPhoneOwnership.isSuccessful = true;
            state.verifyPhoneOwnership.error = null;
            state.verifyPhoneOwnership.data = action.payload;
        },
        [verifyPhoneOwnership.rejected]: (state, action) => {
            state.verifyPhoneOwnership.isLoading = false;
            state.verifyPhoneOwnership.isSuccessful = false;
            state.verifyPhoneOwnership.error = action.payload ?? action.error;
        },
    }

})
const authenticationPersistConfig = {
    key: 'authentication',
    storage: storage,
    whitelist: ['token', 'user', 'permissions'],
}

export default persistReducer(authenticationPersistConfig, authorizationSlice.reducer);
export const { logout } = authorizationSlice.actions;