import React,{useEffect} from 'react'
import productSingle from '$assets/images/productSingle.png';
import productSingleA from '$assets/images/productSingleA.png';
import { IconHeart } from '../../../../../components/icons/heart';
import WebComponentItemProduct from '../../items/product'
import { useParams } from 'react-router-dom';
import { viewProduct } from '../../../../../../redux/features/product';
import { useDispatch, useSelector } from 'react-redux';
import GraceApi from '../../../../../../api/grace/endpoint';

const WebComponentPartialSingleProducts = (props) => {
  const params = useParams();
  const dispatch =useDispatch();

  const product = useSelector((store)=>store.product.view.data);
useEffect(() => {
  if(!params.id) return
  dispatch(viewProduct(params.id));
}, [params.id])
  return (
    <div className='container'>
        <div className="row">
        <div className="col-sm-7">
            <div className="d-flex">
                  <div className="d-flex flex-column me-3">
                      {/* <img className='mb-2 img-fluid' height="300px" width="300px" src={GraceApi.domain + (product && product.skus && product.skus[0].featured_image)} alt="" /> */}
                      {/* <img className='mb-2 img-fluid' src={productSingleA} alt="" />
                      <img className='mb-2 img-fluid' src={productSingleA} alt="" /> */}
                  </div>
                  <div>
                  <img className='img-fluid' src={GraceApi.domain + (product && product.skus && product.skus[0].featured_image)} alt="" />
                  </div>
            </div>

          </div>

            <div className="col-sm-5">
              <div className='d-flex flex-column '>
                    <h4>{product.name}</h4>
                    <h3>TSH. {product && product.skus && parseFloat(product.skus[0].price).toLocaleString()}/=</h3>
                    <p className='mt-3'>
                      {product && product.skus && product.skus[0].description}
                    </p>
                       <span className='text-muted mb-3'>(1Reviews) Write a review</span>
                       <div className="container">
                        <div className="row mt-5">
                            <div className="d-flex  col-6">
                             <input type="number" class="form-control" placeholder="Quantity" aria-label="Quantity" />
                            </div>
                            <div className="d-flex col-6">
                              <button type="button" class="btn btn-success btn-lg text-nowrap">Add To Cart</button>
                            </div>
                        </div>
                        <div className='d-flex my-5'>
                          <div><IconHeart height="20" width="20" /></div><p className='mx-2'>Add to Wish List</p>
                        </div>
                       </div>
              </div>


            </div>
        </div>
    </div>
  )
}

export default WebComponentPartialSingleProducts
