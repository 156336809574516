import { Tag } from "antd";
import moment from "moment/moment";
import { ComponentTableActions } from "../../../../components/table/actions";

export const getColumns = (action=(action, data)=>console.log(action,data))=>{
    return (
        columns = [
    {
        title: '',
        dataIndex: 'id',
       
    },
    {
        title: 'CUSTOMER',
        dataIndex: 'user',
        render: (_, { user }) => {
            let customer = user.name;
            return customer;
        }
    },
    {
        title: 'DATE',
        dataIndex: 'created_at',
        render: (text) => {
            let date = moment(text).format('DD-MM-YYYY');
            return date;
        }
    },
    {
        title: 'TOTAL',
        dataIndex: 'total_amount',
        render: (_, { total_amount }) => {
            let total = total_amount + " /=";
            return total;
        }
    },

    {
        title: 'MOBILE PAYMENT',
        dataIndex: 'payments',
        render: (_, { payments }) => {
            let payment = payments ? payments.name : 'NULL';
            return <Tag color="purple">{payment}</Tag>

        }
    },

    {
        title: 'SHOP',
        dataIndex: 'agent',
        render:(_,{agent})=>{
            let agentName  = agent ? agent.company_name : 'NULL';
            return <Tag color="pink">{agentName}</Tag>
        }
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        render: (_, { status }) => {
            if (status == "PENDING") {
                return <Tag color="blue">{status}</Tag>
            } else {
                return <Tag color="green">{status}</Tag>
            }
        }
    },
       {
            title: 'ACTION',
            render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },

]
    );
}