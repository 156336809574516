import React from 'react';
import { render } from 'react-dom';
import App from './src/App';
import './src/assets/scss/main.scss';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/base-component';
import store from './src/redux/store';
import { Provider } from 'react-redux';

render(<App />, document.getElementById('app'));