.card {
    padding: 54px 30px;
    color: white;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.category {

    a {
        all: unset;
        margin: 0 0px;
        cursor: pointer;
    }
}