import React from 'react'

export const IconDownload = (props) => {
    return (
        <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8989 7.75503C11.1994 7.48146 11.6647 7.50325 11.9383 7.8037C12.2118 8.10415 12.1901 8.56948 11.8896 8.84306L7.99852 12.3861L4.10744 8.84306C3.80699 8.56948 3.7852 8.10415 4.05877 7.8037C4.33235 7.50325 4.79768 7.48146 5.09813 7.75503L7.26278 9.72604V0.735742C7.26278 0.329402 7.59218 0 7.99852 0C8.40486 0 8.73426 0.329402 8.73426 0.735742V9.72604L10.8989 7.75503ZM1.45455 12.3633C1.45455 11.9617 1.12893 11.6361 0.727273 11.6361C0.325611 11.6361 0 11.9617 0 12.3633V13.333C0 14.8058 1.19391 15.9997 2.66667 15.9997H13.3333C14.8061 15.9997 16 14.8058 16 13.333V12.3633C16 11.9617 15.6744 11.6361 15.2727 11.6361C14.8711 11.6361 14.5455 11.9617 14.5455 12.3633V13.333C14.5455 14.0025 14.0028 14.5451 13.3333 14.5451H2.66667C1.99723 14.5451 1.45455 14.0025 1.45455 13.333V12.3633Z" />
        </svg>

    )
}
