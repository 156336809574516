import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategory } from '../../../../../../redux/features/product';
import { getProducts } from '../../../../../../redux/features/product';
import * as styles from './index.module.scss';
import WebComponentItemProduct from '../../items/product/index';
import { NavLink } from 'react-router-dom';


const WebComponentPartialProducts = () => {
  const dispatch = useDispatch();

      // state
      const [selectCategory, setSelectedCategory] = useState([]);
      const [activeCategory, setActiveCategory] = useState(null);

      //Function to get filterd list

    const getFilteredCategory = () => {
      if (!setSelectedCategory) {
          return products;
      }
      return products.filter((product) => product.category === setSelectedCategory);
      }


          // handlers

    // const handleCategoryChange = (event) => {
    //    setSelectedCategory(event.target.value);
    //}

    useEffect(() => {
      dispatch(getProductCategory());
  }, [])

  useEffect(() => {
      dispatch(getProducts());
  }, [])


  const categories = useSelector((store) => store.product.categories)

  const products = useSelector((store) => store.product.products)




  const handleCategoryChange = (id) => {
      const data = products.filter((product) => product.categories.map((value) => value.id == id));

      setActiveCategory(id);
      console.log("value selected is:", data);

  }

  return (
    <div>
        <div className={styles.container}>
            <div className={styles.tabs}>
            <div className={`${styles.tab} ${styles.active}`}>All</div>
                {categories.map((value) => (<div key={value.id}>
                    <NavLink onClick={() => handleCategoryChange(value.id)}>{value.name}</NavLink>
                </div>))}
            </div>
            <div className={styles.items}>
            {products.filter(p => activeCategory ? p.categories.some(c => c.id == activeCategory) : true).map((value) => (
                <div key={value.id} >
                  <WebComponentItemProduct product={value}/>
                </div>
             ))}
            </div>

        </div>
    </div>
  )
}

export default WebComponentPartialProducts