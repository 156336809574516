import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  getAgentsNoParams } from '../../../../../../../redux/features/agent'
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table } from 'antd';
import * as  styles from './index.module.scss'
import moment from 'moment/moment';
import { ComponectSearchInput } from '../../../../components/form/input/search';
import { IconDownload } from '../../../../../../components/icons/download';
import { Modals } from '../../../../components/layout/modal';
import { showModal } from '../../../../../../../redux/features/modal';
import { IconDot } from '../../../../../../components/icons/dot';
import { IconShop } from '../../../../../../components/icons/shop';
import Link from 'antd/es/typography/Link';


export const DashboardPageShopList = () => {

    const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '3',
            icon: < FilePdfOutlined />

        },
    ];

    const menuProps = {
        items,
    };

    const columns = [
        {
            title: '',
            dataIndex: 'id',

        },
        {
            title: 'NAME',
            dataIndex: 'company_name',

        },
        {
            title: 'COUNTRY',
            dataIndex: 'country',
            render:(_,{country})=>{
            //    let country = country.name;
                return  country?.name
            }

        },
        {
            title: 'REGION',
            dataIndex: 'region',
            render:(_,{region})=>{
                return region?.name
            }

        },
        {
            title: 'DISTRICT',
            dataIndex: 'district',
            render:(_,{district})=>{
                return district?.name
            }

        },
        {
            title: 'WARD',
            dataIndex: 'ward',
            render:(_,{ward})=>{
                return ward?.name
            }

        },
        {
            title: 'STREET',
            dataIndex: 'street',
            render:(_,{street})=>{
                return street?.name
            }

        },

        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (_,{id}) => {
                return (
                    <Space size="middle">
                       <div style={{ color: "blue" }} onClick={() => handleView(id)} >View</div>
                      
                    </Space>
                    
                );


            }
        },

    ];

    

    const agentState = useSelector((store) => store.agent.getAgentsNoParams);
    const agentData = useSelector((store) => store.agent.agents);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAgentsNoParams());
    }, []);

   const handleView=(id)=>{
  console.log(id);
   }

    const handleShowModalCreate = () => {
        dispatch(showModal(Modals.AgentFormModal))
    }
    const handleShowModal = (value) => {

    }
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <h4>Agents</h4>
                </div>
                <div className={styles.icon}>
                    <div className="btn-group">
                        <Dropdown menu={menuProps}>
                            <Button style={{
                                borderRadius: '20px',
                            }}>
                                <Space>
                                    <IconDownload width="16px" height="16px" />  Export

                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>


                    </div>
                    <div className="px-2"></div>
                    <Button type="success" style={{
                        borderRadius: '20px',
                        backgroundColor: '#258E01',
                        color: '#ffffff'

                    }} onClick={handleShowModalCreate}>+</Button>



                </div>
                <div>

                </div>
            </div>
            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Active</a>
                    <a className={`nav-link ${styles.link}`} href="#">Blocked</a>


                </nav>
            </div>
            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search agents" />
                        </div>
                        <div className='col-md-2'>
                            <Dropdown menu={menuProps}>
                                <Button style={{
                                    borderRadius: '20px',
                                }}>
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table
                       // loading={agentState.isLoading}
                        columns={columns}
                        dataSource={agentData}
                    />
                </div>



            </div>



        </>
    )
}
