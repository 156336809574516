import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { WebComponentFooter } from '../components/layout/footer';
import { WebComponentHeader } from '../components/layout/header';
import { WebCartPage } from './cart';
import { WebHomePage } from './home';
import { WebSinglePage } from './single';
import { WebNotFoundPage } from './notfound';
import { WebStoresPage } from './stores';
import { WebProductsPage } from './products/index';
import WebPaymentPage from './payment/index';
import { WebWishlistPage } from './wishlist';
import WebPaymentPage from './payment';
import { WebMyAccountRoutes } from './myaccount/routes';
import { ConfigProvider } from 'antd';
import Colors from '../../../../assets/constants/color';
import { WithdrawPage } from './myaccount/withdraw/index';
import { PrivacyPage } from './privacy/index';

export const WebRoutes = () => {
  return (
    <div>
      <ConfigProvider theme={mainTheme}>
        <WebComponentHeader />
        <Routes>
            <Route index element={<WebHomePage />}  />
            <Route path="/home" element={<WebHomePage />}  />
            <Route path="/stores" element={<WebStoresPage />}  />
            <Route path="/cart" element={<WebCartPage />}  />
            <Route path="/shop/*" element={<WebProductsPage />}  />
            <Route path="/single/:id" element={<WebSinglePage />} />
            <Route path="/payment" element={<WebPaymentPage />} />
            <Route path="/wishlist" element={<WebWishlistPage />} />
            <Route path="/my-account/*" element={<WebMyAccountRoutes />} />
            <Route path="/*" element={<WebNotFoundPage />} />
        </Routes>
        <WebComponentFooter />
        </ConfigProvider>
    </div>
    
  )
}

const mainTheme = {
  token: {
    colorPrimary: Colors.primary,
  },
};