.wrapper {
    height: 38px;
    width: 100%;
    padding: 0px;
    display: flex;

    .nav {
        float: left;
        width: 420px;
        height: 38px;
        background-color: #dddddd;
        display: flex;
        border-radius: 20px;


        .active {
            width: 140px;
            height: 38px;
            border-radius: 20px;
            text-align: center;
            color: black;
            padding: 7px;
            background-color: white;


        }

        .link {
            width: 140px;
            height: 38px;
            text-align: center;
            color: #52575c;
            padding: 7px;
        }
    }

    .alginLeft {
        height: 100%;

    }
}