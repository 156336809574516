import React from 'react'
import * as styles from './index.module.scss';
import banner from '$assets/images/home_banner.png';
import ButtonMain from '../../../../../../components/buttons/Main/index';
import { IconChevronLeft } from '../../../../../../components/icons/chevron-left';
import { IconChevronRight } from '../../../../../../components/icons/chevron-right';
import { useNavigate } from 'react-router-dom';

export const WebHomeComponentHero = () => {
  const navigate =useNavigate();
  return (
    <div className={` ${styles.wrapper}`} style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }}>
      <div className={styles.content}>
        <h1>Beauty Inspired by Real Life</h1>
        <p>We are a trusted brand for all of your cosmetic needs, our line of products gets you covered. Click the actions bellow to find out more.</p>

                <ButtonMain className="btn-lg m-2"  onClick={() => navigate('/shop')}>Shop Now</ButtonMain>
                <ButtonMain className="btn-lg btn-dark bg-dark" onClick={() => navigate('/shop')}>Explore</ButtonMain>
      </div>

      <div className={styles.controls}>
        <div className={styles.left}>
          <IconChevronLeft height="30px" width="30px" />
        </div>
        <div className={styles.right}>
          <IconChevronRight height="30px" width="30px" />
        </div>
      </div>
    </div>
  )
}
