import React from 'react'
import { IconDashboard } from '../../../../../components/icons/dashboard';
import * as styles from './index.module.scss';
import DashboardComponentSideMenuItem from './item';
import { IconCart } from '../../../../../components/icons/cart';
import { NavLink } from 'react-router-dom';
import { IconOrder } from '../../../../../components/icons/order';
import { IconCustomer } from '../../../../../components/icons/customer';
import { IconShop } from '../../../../../components/icons/shop';
import { IconMoney } from '../../../../../components/icons/monye';

export const DashboardComponentSideMenu = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <h1>GRACE</h1>
            </div>

            <div className={styles.menu}>
                <NavLink to="dashboard" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconDashboard height="20px" width="20px" />
                    <div className={styles.menuItemText}>Dashboard</div>
                </NavLink>

                <NavLink to="products" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconCart height="20px" width="20px" />
                    <div className={styles.menuItemText}>Products</div>
                </NavLink>
                <NavLink to="orders" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconOrder height="20px" width="20px" />
                    <div className={styles.menuItemText}>Orders</div>
                </NavLink>
                <NavLink to="customers" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconCustomer height="20px" width="20px" />
                    <div className={styles.menuItemText}>Customers</div>
                </NavLink>
                 <NavLink to="members" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconCustomer height="20px" width="20px" />
                    <div className={styles.menuItemText}>Members</div>
                </NavLink>
                <NavLink to="shops" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconShop height="20px" width="20px" />
                    <div className={styles.menuItemText}>Shops</div>
                </NavLink>
                <NavLink to="merchants" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconShop height="20px" width="20px" />
                    <div className={styles.menuItemText}>Merchants</div>
                </NavLink>
                <NavLink to="locations" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconShop height="20px" width="20px" />
                    <div className={styles.menuItemText}>Location</div>
                </NavLink>
                <NavLink to="payments" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconMoney height="20px" width="20px" />
                    <div className={styles.menuItemText}>Payments</div>
                </NavLink>
                <NavLink to="mobiles" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconMoney height="20px" width="20px" />
                    <div className={styles.menuItemText}>Mobiles</div>
                </NavLink>
                <NavLink to="config" className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}>
                    <IconMoney height="20px" width="20px" />
                    <div className={styles.menuItemText}>Configuration</div>
                </NavLink>
            </div>
        </div>
    )
}
