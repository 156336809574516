import React from 'react'
import * as styles from './index.module.scss';
import { IconDownload } from '../../../../components/icons/download';
export const DashboardDashboardPage = () => {
  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.text}>
            <h3>Overview</h3>

            <div className="flex-grow-1"></div>
            <button type="button" class={`btn bg-white ${styles.button}`} > <IconDownload width="16px" height="16px" /></button>
            <div className="px-2"></div>
            <button type="button" className="btn bg-white rounded dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Last 7 days
            </button>
          </div>

        </div>


      </div >

      <div className={styles.mindash}>
        <div className='container'>
          <div className='row'>
            <div className={`col-sm`} >sample1</div>
            <div className={`${styles.divider}`}></div>
            <div className={`col-sm `}> sample2</div>
            <div className={`${styles.divider}`}></div>
            <div className={`col-sm `}>sample3</div>
          </div>
        </div>
      </div>
    </div>


  )
}
