import React from 'react'
import Button from '../button';
import * as styles from './index.module.scss';

const ButtonMain = (props) => {
    const { className, onClick, children } = props;
    
    return (
        <Button className={`btn-primary ${className} ${styles.mainButton}`} onClick={onClick}>{children}</Button>
    );
}

export default ButtonMain