import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router'
import { ForgotPassword } from './pages/forgot_password'
import { Login } from './pages/login'
import { ResetPassword } from './pages/reset_password'
import { useNavigate } from 'react-router-dom';
import { RegistrationPage } from './pages/registration/index';

export const AuthRoutes = () => {

  // redux
  const { token } = useSelector((state) => state.authentication);

  // router
  const navigate = useNavigate();

  // effect
  useEffect(() => {
      if (token) {
          navigate("/");
      }
  }, [token]);

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  )
}
