import React from 'react'
import WebComponentPartialSimilarProducts from '../../components/partials/similarProducts';
import { WebSingleComponentHero } from './components/hero';

export const WebSinglePage = () => {
  return (


<div className="container my-5 py-5">
    <div className="row">
      <div className="col-lg-12 ">
        <WebSingleComponentHero />
      </div>
    </div>

</div>
  

  )
}
