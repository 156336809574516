.wrapper {
    background-color: white;
    width: 100%;
    height: 750px;
    border-radius: 20px;
    margin-top: 20px;

    .inputSerach {
        width: 100%;
        padding-top: 20px;
        padding-left: 6px;
        padding-right: 6px;
        flex: 1;
        margin-left: 20px;
        margin-right: 20px;
    }

    .datatable {
        padding-top: 6px;
        padding-left: 10px;
        padding-right: 10px;
    }

}