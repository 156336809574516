import { Tag } from 'antd';
import moment from 'moment/moment';
import React from 'react'
import { ComponentTableActions } from '../../../../components/table/actions';

export const getCustomerColumns = (action=(action,data)=>console.log(action,data)) => {
  return (
    columns = [
        {
            title: '',
            dataIndex: 'id',
            
        },
        {
            title: 'NAME',
            dataIndex: 'name',

        },
        {
            title: 'EMAIL',
            dataIndex: 'email',

        },
        {
            title: 'PHONE',
            dataIndex: 'phone',
        },
        {
            title:'MEMBER STATUS',
            dataIndex:'member',
            render:(_,{member})=>{
                let status = member?  member.is_active : null;
                 if(status == 0){
                       return (<Tag color="red">IN ACTIVE</Tag>) 
                 }else if(status == 1){
                           return (<Tag color="gold">ACTIVE</Tag>)
                 }
                 else{
                    return ( <Tag color="black">NULL</Tag>)
                 }
                  
                
            }
        },
        {
            title:"MERCHANTS",
            dataIndex:'organizations',
            render:(_,{organizations})=>{
                let merchants = organizations ? organizations.map((v)=>(v.name)) : null;
                return (
                   <Tag color="blue">{merchants}</Tag> 
                );
            }
        },
        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'ACTION',
           render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },

    ]
   
  );
}
