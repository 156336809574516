.card {
    background: #F2F2F2;
    border-radius: 20px;
    margin: 0 20px;
    padding: 30 50px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        /* or 114% */

        letter-spacing: 0.25px;

        /* black 2 */

        color: #25282B;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        /* or 160% */

        letter-spacing: 0.25px;

        /* black 2 */

        color: #25282B;
    }

    strong {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        /* or 160% */

        letter-spacing: 0.25px;

        /* black 2 */

        color: #25282B;
    }

    hr {
        border: 0.625px solid #7C7C7C;
    }
}

.box {
    all: unset;
    border: 1px solid #C4C4C4;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    height: 20px;
    width: 20px;
}

.cart {
    position: relative;
}