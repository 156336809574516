import React, { useEffect, useState } from 'react'
import * as styles from './index.module.scss';
import ButtonMain from '../../../../components/buttons/Main/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAgents } from '../../../../../redux/features/agent';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Select, Button } from 'antd';
import { fetchCountries, fetchCountyRegion } from '../../../../../redux/features/location';
import { assignAgent, updateOrder } from '../../../../../redux/features/order';

const initialState = {
    country_id: '',
    region_id: '',
    district_id: '',
    distance: '',
    organization_id: '',

};

export const WebStoresPage = (props) => {
    const [agent, setAgent] = useState([]);
    const [userLocation, setUserLocation] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const { order } = location.state;
    const [mount, setMount] = useState(false);

    // redux
    const dispatch = useDispatch();
    const agentState = useSelector((store) => store.agent.get);
    const agents = useSelector((store) => store.agent.agents);
    const countries = useSelector((store) => store.location.countries);
    const regions = useSelector((store) => store.location.regions);
    const updateOrderState = useSelector((store) => store.order.assignAgent);

    useEffect(() => {

        setMount(true);
        if ("geolocation" in navigator) {
            console.log("Available");
        } else {
            console.log("Not Available");
        }

    }, [])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });

            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            dispatch(getAgents({
                params: {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude

                }
            }));

        });

    }, []);

    useEffect(() => {

        setAgent(agentState.data);

    }, [agentState.isSuccessful]);

    useEffect(() => {
        dispatch(fetchCountries());
    }, [])

    useEffect(() => {
        if (!mount) return;
        if (!updateOrderState.isSuccessful) return;
        navigate('/payment', { state: { order }});
    }, [updateOrderState.isSuccessful]);

    const handleCHange = (id) => {
        dispatch(fetchCountyRegion(id))
    }

    const handleSelectAgent = (agent) => {
        const data = {
            id: order.id,
            payload: {
                order_id: order.id,
                agent_id: agent.id
            }
        }
        dispatch(assignAgent(data));
    }
    return (
        <div className={`container`}>
            <div className="row my-5">
                <div className="col-lg">
                    <h1>Select Nearest Store</h1>
                    <p>{`Home > Stores`}</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-8">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Shop Name</th>
                                <th>Shop Address</th>
                                <th>Distance</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {agents.map((value) => (
                                <tr key={value.id}>
                                    <td>{value.company_name}</td>
                                    <td>{value.street?.name} ,{value.ward?.name}, {value.district?.name},{value.region?.name}, {value.country?.name}</td>
                                    <td>{Math.round(value.distance).toFixed(1)} Kilometers</td>
                                    <td>
                                        <ButtonMain onClick={() => handleSelectAgent(value)}>Select Shop</ButtonMain>
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                    </table>
                </div>
                <div className="col-lg-4">
                    <Card
                        title="Select  Your Nearset Location"
                        bordered={false}
                        style={{
                            width: 300,
                        }}
                    >
                        <div>
                            <select className="form-select">
                                {countries.map((country) => (
                                    <option key={country.id} value={[{
                                        latitude: country.latitude,
                                        longitude: country.longitude,
                                    }]} onChange={() => handleCHange(country.id)}>{country.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='p-2'></div>
                        <div><select className="form-select">
                            <option></option>
                        </select> </div>
                        <div className='p-2'></div>
                        <div> <select className="form-select">
                            <option></option>
                        </select></div>
                        <div className='p-2'></div>
                        <div>
                            <select className="form-select">
                                <option></option>
                            </select>
                        </div>
                        <div className='p-2'></div>
                        <Button type="primary" >
                            Search
                        </Button>

                    </Card>

                </div>


            </div>

        </div>
    )
}
