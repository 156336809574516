.page {
    text-align: center;
    margin: 50px;

    .image {
        display: inline-block;
        position: relative;
        margin: 0 auto;
        margin-bottom: 30px;

        h1 {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translate(-50%, 0);
        }

        img {
            height: 360px;
        }
    }

    h1 {
        text-align: center;
    }

    p {
        text-align: center;
    }
}