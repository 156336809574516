import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { DashboardPageShopList } from './list';
export const DashboardPageShopRoutes = () => {
    return (
        <Routes>
            <Route index element={<DashboardPageShopList />} />
        </Routes>
    )
}
