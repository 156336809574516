import React, { useEffect } from 'react'
import { useLocation } from 'react-router';
import { WebHomeComponentHero } from './components/hero/index';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appMainMenuUpdateTransparency, MainMenuModes } from '../../../../../redux/features/app';
import WebComponentPartialCategories from '../../components/partials/categories/index';
import WebComponentPartialCategoryProducts from '../../components/partials/categoryProducts';
import { WebHomeComponentCallout } from './components/callout';
import WebComponentPartialProducts from '../../components/partials/products';
import { WebHomeComponentContact } from './components/contact';
import { WebHomeComponentTestminials } from './components/testmonials';
import { WebHomeComponentSocials } from './components/socials';

export const WebHomePage = () => {
  // router
  const location = useLocation();
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();

  // effects
  useEffect(() => {
    if (location.pathname != '/home') {
      navigate('/home');
      return;
    }

    dispatch(appMainMenuUpdateTransparency(MainMenuModes.transparent));

    return () => {
      dispatch(appMainMenuUpdateTransparency(MainMenuModes.normal));
    }
  }, [location.pathname]);
  
  return (

    <div>
      <WebHomeComponentHero />

      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <WebComponentPartialCategories />
          </div>
        </div>
      </div>
 
      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <WebComponentPartialCategoryProducts />
          </div>
        </div>
      </div>

      <WebHomeComponentCallout />

   <div className="container my-5 py-5">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <WebComponentPartialProducts />
          </div>
        </div>
      </div>
      {/* <WebHomeComponentTestminials />

      <WebHomeComponentContact />
      <WebHomeComponentSocials />  */}

      
    </div>
  )
}
