import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_MEMBERS = 'member/GET_MEMBERS';
const CREATE_MEMBER = 'member/CREATE_MEMBER';
const SHOW_MEMBER = 'member/SHOW_MEMBER';
const DELETE_MEMBER = 'member/DELETE_MEMBER';
const UPDATE_MEMBER = 'member/UPDATE_MEMBER';

const initialState = {
    members: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    }

}

export const getMembers = createAsyncThunk(GET_MEMBERS, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.members, null, token, store);
});

export const createMember = createAsyncThunk(CREATE_MEMBER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.memberCreate, data, token, store);
});

export const showMember = createAsyncThunk(SHOW_MEMBER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.memberShow(data.id), null, token, store);
});
export const deleteMember = createAsyncThunk(DELETE_MEMBER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('DELETE', GraceApi.memberDelete(data.id), null, token, store);
});

export const updateMember = createAsyncThunk(UPDATE_MEMBER, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id, value} = data;
    return await customFetch("PUT", GraceApi.memberUpdate(id), value, token, store);
    
});

const memberSlice = createSlice({
    name: 'member',
    initialState,
    extraReducers: {
        //get Members
        [getMembers.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
            state.members = [];
        },
        [getMembers.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getMembers.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload.data;
            state.members = action.payload.data;

        },
        //create member
        [createMember.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
            state.create.data = {};
        },
        [createMember.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;

        },
        [createMember.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload.data;
           state.members.unshift(action.payload.data);
        },
        //show Member
        [showMember.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;
            state.view.data = {};

        },
        [showMember.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showMember.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;
        },
        //delete Member

        [deleteMember.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = null;

        },
        [deleteMember.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = action.error

        },
        [deleteMember.fulfilled]: (state, action) => {
            state.delete.isLoading = false
            state.delete.isSuccessful = true
            state.delete.data = action.payload.data,
                state.delete.error = null;
            state.members = state.members.filter(member => member.id != action.meta.arg.id);
        },
        //update member
        [updateMember.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;
        },
        [updateMember.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;
        },
        [updateMember.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload.data;
            const value = state.members.find(member => member.id === action.payload.id);
            if (value > -1) {
                state.members['value'] = action.payload.value;
            }

        },



    }
})
export default memberSlice.reducer;