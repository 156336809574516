import { PropTypes } from 'prop-types';
import React from 'react'
import * as styles from './index.module.scss';
import { DashboardComponentHeader } from '../header/index';
import { DashboardComponentSideMenu } from '../sidemenu/index';

const DashboardComponentAppLayout = (props) => {
    const { title, children } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebar}>
                <DashboardComponentSideMenu />
            </div>
            <div className={styles.bodyWrapper}>
                <div className={styles.header}>
                    <DashboardComponentHeader />
                </div>
                <div className={styles.body}>
                    {children}
                </div>
            </div>
        </div>
    )
}

DashboardComponentAppLayout.defaultProps = {
    title: 'Dashboard Title',
    children: <div>Dashboard Main Body</div>
}

DashboardComponentAppLayout.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
}

export default DashboardComponentAppLayout;