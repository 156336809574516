import React from 'react'
import * as styles from './index.module.scss';
import banner from '$assets/images/footer_banner.png';
import logoWhite from '$assets/images/logo_white.png';
import { IconMarker } from '../../../../../components/icons/marker';
import { IconEmail } from '../../../../../components/icons/email';
import { IconTelephone } from '../../../../../components/icons/telephone';
import { Link, useNavigate } from 'react-router-dom';
import ButtonMain from '../../../../../components/buttons/Main';

export const WebComponentFooter = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.footer} style={{ backgroundImage: `url(${banner})` }}>
        <div className={styles.tint}></div>
        <div className="row">
          <div className="col-lg-3">
            <img className={styles.logo} src={logoWhite} alt="" />

            <p className={styles.info}>
              We are a trusted brand for all of your cosmetic needs, our line of products gets you covered.
            </p>

            <div className={styles.address}>
              <div className={styles.line}>
                <div><IconMarker height="20px" width="20px" /></div>
                <span>Dar es Salaam, Tanzania</span>
              </div>
              <div className={styles.line}>
                <div><IconEmail height="20px" width="20px" /></div>
                <span>info@graceproducts.com</span>
              </div>
              <div className={styles.line}>
                <div><IconTelephone height="20px" width="20px" /></div>
                <span>+255 654 888 888</span>
              </div>
            </div>
          </div>

          <div className="col-lg-2">
            <h5>INFORMATION</h5>
            <div>Shipping & Return</div>
            <div>Contact Us</div>
          </div>

          <div className="col-lg-2">
            <h5>OUR SERVICE</h5>
            <div onClick={() => navigate('/privacy')}>Terms & Condition</div>
            <div onClick={() => navigate('/privacy')}>Privacy Policy</div>
            <div onClick={() => navigate('/privacy')}>Return Policy</div>
            <div>Your Account</div>
            <div>Near Shops</div>
          </div>

          <div className="col-lg-2">
            <h5>ALL CATEGORY</h5>
            <div>Shop All</div>
            <div>Collection</div>
            <div>View All</div>
          </div>

          <div className="col-lg-3">
            <h5>GET DETAILS FROM THE STORE</h5>
            <p>Get the latest updates on new products and upcoming sales</p>
            <input placeholder='Your Email Address' />
            <div className="py-2"></div>
            <ButtonMain className="btn-lg bg-light text-dark">Subscribe</ButtonMain>
          </div>
        </div>

        <div className='d-flex mt-5'>
          <span className='flex-grow-1'>&copy; 2023 Grace Products, Powered by Peach Technology</span>
          <span onClick={() => navigate('/privacy')}>All rights reserved</span>
        </div>
    </div>
  )
}
