.wrapper {
    position: relative;
    height: 100vh;

    .content {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
        color: white;
        width: 100vw;

        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);

        padding: 81px;

        p {
            max-width: 540px;
            margin: 30px auto;
        }
    }

    .controls {
        position: absolute;
        right: 0;
        bottom: 0;
        color: white;
        display: flex;

        div {
            padding: 10px;
        }

        .left {
            background-color: #F0CA00;
        }

        .right {
            background-color: #37C4CD;
        }
    }
}