import { Modal, Table, Tag } from 'antd'
import moment from 'moment/moment'
import React, { useEffect } from 'react'
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top'
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Image, Table, Tag } from 'antd';
import { showModal } from '../../../../../../../redux/features/modal';
import { Modals } from '../../../../components/layout/modal';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategory } from '../../../../../../../redux/features/product';

export const DashboardPageProductCategory = () => {
    const dispatch = useDispatch();

    const handleView = (id) => {

    }
    const handleEdit = (id) => {
        dispatch(showModal(Modals.ProductCategory, { id }))
    }




    const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '2',
            icon: < FilePdfOutlined />

        },
    ];
    const columns = [
        {
            title: '',
            dataIndex: 'id',

        },
        {
            title: 'logo',
            dataIndex: 'logo',
            render: (logo) => {
                return (
                    <Image width={50}
                        height={50} style={{ borderRadius: "20px" }} src={logo} />
                )
            }

        },
        {
            title: 'NAME',
            dataIndex: 'name',

        },


        {
            title: 'DESCRIPTION',
            dataIndex: 'description',


        },
        {
            title: 'PARENT',
            dataIndex: 'PARENT',

        },


        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id) => {
                return (
                    <EyeOutlined style={{ color: "blue" }} onClick={() => handleView(id)} />
                );


            }
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id) => {
                return (<EditOutlined style={{ color: "green" }} onClick={() => handleEdit(id)} />)
            }
        }


    ];

    const categories = useSelector((store) => store.product.categories);

    useEffect(() => {
        dispatch(getProductCategory());
    }, [])
    const handleAddClicked = (e) => {
        e.preventDefault();
        dispatch(showModal(Modals.ProductCategory))

    }


    return (


        <div>
            <DashboardComponentBodyTop title="Product Category" onAdd={handleAddClicked} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    columns={columns}
                                    dataSource={categories}
                                // loading={mobileGetState.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
