import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { DashboardPageProductCategory } from './category/list';
import { DashbaordPageProductList } from './pages/list/index';
import { DashboardPageProductSku } from './productSku';

export const DashboardPageProductRoutes = () => {
  return (
    <Routes>
       <Route index element={<DashbaordPageProductList />} />
       <Route path='category'  element={<DashboardPageProductCategory />} />
       <Route path='sku/:id'  element={<DashboardPageProductSku />} />
    </Routes>
  )
}
