import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../../../redux/features/modal';
import { Modals } from '../../../../components/layout/modal';
import * as styles from './index.module.scss';
import { Button, Dropdown, Space, Table } from 'antd';
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { IconDownload } from '../../../../../../components/icons/download';
import { ComponectSearchInput } from '../../../../components/form/input/search';
import merchant, { deleteMerchant, getMerchants } from '../../../../../../../redux/features/merchant';
import { getMerchantColums } from './columns';

    const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '2',
            icon: < FilePdfOutlined />

        },
    ];

    const menuProps = {
        items,
    };

export const DashboardPageMerchantList = () => {

    //redux
    const dispatch = useDispatch();
    const merchants = useSelector((store) => store.merchant.merchants);
    //effects
      useEffect(() => {
        dispatch(getMerchants());
    }, [])

    //handles
    const handleShowModalCreate = () => {
        dispatch(showModal(Modals.MerchantFormModalType))
    }

    const handleEdit = (id) => {
      //  console.log(id);
        dispatch(showModal(Modals.MerchantFormModalType, { id },parentProps={width:"600px"}));
    };
    const handleDelete = (id)=>{
        dispatch(deleteMerchant(id));
    }

    const handleAction=(action,data)=>{
        switch(action){
             case 'edit':
                handleEdit(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }

    }
 
    

  
    return (
        <>
            <div className={styles.wrapper}>
                <div className="row">
                    <div className="col">
                        <div className="Merchants">
                            <h4>Merchants</h4>
                        </div>
                    </div>
                    <div className="col">
                        <div className={styles.icon}>
                            <div className="btn-group">
                                <Dropdown menu={menuProps}>
                                    <Button style={{
                                        borderRadius: '20px',
                                    }}>
                                        <Space>
                                            <IconDownload width="16px" height="16px" />  Export

                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className="px-2"></div>
                            <Button type="success" style={{
                                borderRadius: '20px',
                                backgroundColor: '#258E01',
                                color: '#ffffff'

                            }} onClick={handleShowModalCreate}>+</Button>
                        </div>


                    </div>

                </div>


            </div>
            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Active</a>
                    <a className={`nav-link ${styles.link}`} href="#">Blocked</a>


                </nav>
            </div>
            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search merchants" />
                        </div>
                        <div className='col-md-2'>
                            <Dropdown menu={menuProps}>
                                <Button style={{
                                    borderRadius: '20px',
                                }}>
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table
                       
                        columns={getMerchantColums(handleAction)}
                        dataSource={merchants}
                    />
                </div>



            </div>

        </>
    )
}
