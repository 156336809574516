import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import ButtonMain from '../../../../components/buttons/Main';
import { useNavigate, Routes } from 'react-router-dom';
import { Tabs } from 'antd';
import { MyOrders } from './orders';
import { CustomerDashboard } from './dashboard';
import { Route } from 'react-router-dom';
import { WithdrawPage } from './withdraw';

const tabItems = [
    {
        key:  1,
        label: 'Dashboard',
        children: <CustomerDashboard />,
    },
    {
        key:  2,
        label: 'Orders',
        children: <MyOrders />,
    },
    {
        key:  3,
        label: 'Commissions',
        children: 'comming soon!',
    },
    {
        key:  4,
        label: 'Payouts',
        children: 'comming soon!',
    },
    {
        key:  5,
        label: 'My Network',
        children: 'comming soon!',
    },
];

export const WebMyAccountRoutes = () => {
    // router
    const navigate = useNavigate();

    // redux
    const { user, token } = useSelector(state => state.authentication); 

    if (!token) {
        return (
            <div className="container my-5">
                <div className="row">
                    <div className="alert alert-danger">
                        You are not authorized to access this page, Login First. <ButtonMain onClick={() => navigate('/')}>Go to Home</ButtonMain>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <Routes>
            <Route path="/payouts/request" element={<WithdrawPage />} />
            <Route path="/*" element={(
                <div className='container my-5'>
                    <div className="row">
                        {user.registration_orders.length > 0 && user.registration_orders[0].status === 'PENDING' && (
                            <div className="alert alert-danger">
                                Your registration is still pending, please pay the registration fee and enjoy the full advantages of being a Member. Get commissions and earn income from your referrals. <ButtonMain onClick={() => navigate('/payment', { state: { registrationOrder: user.registration_orders[0]} })}>Pay Now! {user.registration_orders[0].amount}/=</ButtonMain>
                            </div>
                        )}
                        <div className="col-lg">
                            <Tabs defaultActiveKey="1" items={tabItems} onChange={() => {}}/>
                        </div>
                    </div>
                </div>
            )} />
        </Routes>
    )
}
