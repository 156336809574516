import { Avatar, Button, Descriptions, Space, Statistic } from "antd";
import Card from "antd/es/card/Card";
import React, { useEffect } from "react";
import ButtonMain from "../../../../../components/buttons/Main/index";
import { useSelector, useDispatch } from "react-redux";
import { CrownOutlined, ReloadOutlined } from "@ant-design/icons";
import { reloadUser } from "../../../../../../redux/features/authentication";
import { getOrders } from '../../../../../../redux/features/order';
import { useNavigate } from 'react-router-dom';

export const CustomerDashboard = () => {
  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const reloadUserState = useSelector((state) => state.authentication.reloadUser);

  const order = useSelector((state) => state.order);

  // effects
  useEffect(() => {
    dispatch(getOrders({params: {user_id: user.id}}))
}, []);

  // handlers
    const handleReloadUser = () => {
        dispatch(reloadUser());
    }

    const handleWithdraw = () => {
      navigate('payouts/request')
    }
  return (
    <div className="row">
      <div className="col-lg-3">
        <Card loading={reloadUserState.isLoading}>
            <div className="mb-3">
            <Button type="text" icon={<ReloadOutlined />} onClick={handleReloadUser} disabled={reloadUserState.isLoading}></Button>
            </div>
          <div className="d-flex">
            <Avatar size={81}>
              {user.name
                ? user.name
                    .split(" ")
                    .map((jina) => jina[0])
                    .join("")
                : "N/A"}
            </Avatar>
            <div className="p-2">
              <h4 className="m-0 p-0">{user.name}</h4>
              <p
                className={`m-0 p-0 ${
                  user.member?.is_active ? "text-success" : "text-danger"
                }`}
              >
                {user.member?.is_active ? "ACTIVE MEMBER" : "INACTIVE"}
              </p>
              {user.member?.king_code ? (
                <p className={`m-0 p-0 text-xs`}>
                  <CrownOutlined /> {user.member.king_code}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="my-3"></div>
          <Descriptions column={1}>
            <Descriptions.Item label="Full Name">{user.name}</Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {user.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          </Descriptions>

          {user.member?.king_code ? (
                      <Descriptions column={1}>
                      <Descriptions.Item label="King Code">{user.member.king_code}</Descriptions.Item>
                      <Descriptions.Item label="Membership Status">
                        {user.member.is_active ? 'ACTIVE' : 'INACTIVE'}
                      </Descriptions.Item>
                    </Descriptions>
          ) : null}
        </Card>

        <div className="my-3"></div>

        <Card hoverable loading={reloadUserState.isLoading}>
          <Statistic title="Wallet Balance" value={user.member?.wallet} precision={2} />
          <ButtonMain onClick={handleWithdraw} className="btn-sm">Withdraw</ButtonMain>
        </Card>
      </div>
      <div className="col-lg-9">
        <div className="row">
          <div className="col-lg-3 mb-4">
            <Card hoverable>
              <Statistic title="Orders" value={order.orders.length} precision={0} />
            </Card>
          </div>
          <div className="col-lg-3 mb-4">
            <Card hoverable>
              <Statistic title="Commissions" value={'...'} precision={0} />
            </Card>
          </div>
          <div className="col-lg-3 mb-4">
            <Card hoverable>
              <Statistic title="Payouts" value={'...'} precision={0} />
            </Card>
          </div>
          <div className="col-lg-3 mb-4">
            <Card hoverable>
              <Statistic title="Wishlist" value={'...'} precision={0} />
            </Card>
          </div>
          <div className="col-lg-3 mb-4">
            <Card hoverable>
              <Statistic title="My Network" value={'...'} precision={0} />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
