import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
const VIEW_PAYMENT_METHOD = "VIEW_PAYMENT_METHOD";
const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD";
const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
const initialState = {
    paymentMethods: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: [],
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    }

}
export const getPaymentMethods = createAsyncThunk(GET_PAYMENT_METHOD, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.paymentMethods, null, token, store);
});

export const showPaymentMethods = createAsyncThunk(VIEW_PAYMENT_METHOD, async (data, store) => {
    console.log("value of data---request==>id", data);
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.paymentMethodShow(data),null, token, store)

});

export const updatePaymentMethods = createAsyncThunk(UPDATE_PAYMENT_METHOD, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id, value } = data;
    return await customFetch("PUT", GraceApi.paymentMethodUpdate(id), value, token, store)
});


export const createPaymentMethods = createAsyncThunk(CREATE_PAYMENT_METHOD, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch("POST", GraceApi.paymentMethodCreate, data, token, store);

});
export const deletePaymentMethods =createAsyncThunk( DELETE_PAYMENT_METHOD,async (data,store)=>{
     const { token } = store.getState().authentication;
     return await customFetch("DELETE", GraceApi.paymentMethodDelete(data),null,token,store);
})

const paymentMethods = createSlice({
    name: 'paymentMethod',
    initialState,
    extraReducers: {
        ///get
        [getPaymentMethods.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {}
        },
        [getPaymentMethods.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;;

        },
        [getPaymentMethods.fulfilled]: (state, action) => {
            state.get.isLoading = false
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.paymentMethods = action.payload.data;
        },
        //view
        [showPaymentMethods.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;

        },
        [showPaymentMethods.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showPaymentMethods.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;

        },

        // update
        [updatePaymentMethods.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;
        },
        [updatePaymentMethods.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;
        },
        [updatePaymentMethods.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload.data;
            const value = state.paymentMethods.find(val => val.id === action.payload.id);
            if (value > -1) {
                state.paymentMethods['value'] = action.payload.value;
            }

        },
        ///create
        [createPaymentMethods.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
        },
        [createPaymentMethods.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;
        },
        [createPaymentMethods.fulfilled]: (state, action) => {
            state.create.isLoading = false
            state.create.isSuccessful = true
            state.create.error = null;
            state.paymentMethods.unshift(action.payload.data);
        },
        ///delete
        [deletePaymentMethods.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            state.delete.error = null;
        },
        [deletePaymentMethods.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            state.delete.error = action.error;
        },
        [deletePaymentMethods.fulfilled]: (state, action) => {
            state.delete.isLoading = false
            state.delete.isSuccessful = true
            state.delete.error = null;
            state.paymentMethods= state.paymentMethods.filter(payment=> payment.id != action.meta.arg);
        },

    }
})

export default paymentMethods.reducer;