import { Space, Tag } from "antd";
import moment from "moment/moment";
import { ComponentTableActions } from "../../../components/table/actions";

export const getPaymentsColumns =(action=(action,data)=>console.log(action,data))=>{
    return (columns = [
        {
            title: '',
            dataIndex: 'id',

        },
        {
            title: 'NAME',
            dataIndex: 'name',

        },


        {
            title: 'DESCRIPTION',
            dataIndex: 'description',


        },
        {
            title: 'STATUS',
            dataIndex: 'active',
            render: (active) => {
                if (active === 1) {
                    return (
                        <Tag color="green" key={active}>
                            {"active".toUpperCase()}
                        </Tag>
                    );
                } else if (active === 0) {
                    return (<Tag color="red" key={active}>
                        {"in active".toUpperCase()}
                    </Tag>);

                }


            }


        },


        {
            title: 'REGISTERED AT',
            dataIndex: 'created_at',
            render: (text) => {
                let date = moment(text).format('DD-MM-YYYY');
                return date;
            }
        },
        {
            title: 'ACTION',
             render: (_, record) => <ComponentTableActions onAction={action} record={record} />
        },
       


    ]);
}