import { Table } from "antd"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMembers, updateMember } from "../../../../../../redux/features/member";
import { showModal } from "../../../../../../redux/features/modal";
import { DashboardComponentBodyTop } from "../../../components/layout/body/top";
import { Modals } from "../../../components/layout/modal";
import { MemberModalEdit } from "../../../components/modals/member/edit";
import { getColumns } from "./columns";



export const DashboardPageMemberList=()=>{
   // const[active ,setActive] =useState([]);
    //redux
    const dispatch =  useDispatch();
    const members = useSelector((store)=>store.member.members);
    const memberState = useSelector((store)=>store.member.get);

    //effects
    useEffect(()=>{
        dispatch(getMembers());
    },[])

    //handles
    const handleAddClicked = (e) =>{
    
    dispatch(showModal(Modals.MemberModalEdit))
   }
   const handleUpdate=(data)=>{
      //setActive(['is_active'data.active]);
    //  console.log("active data: " + active);
    if(data.active== 0){
        const value ={ 
         id :data.id,
         is_active: 1
      }
      dispatch(updateMember(value));

    }else{
          const value ={ 
         id :data.id,
         is_active: 0
      }
      dispatch(updateMember(value));

    }
      

   }

   const handleAction=(action,data)=>{
    switch (action) {
            case 'view':
                handleUpdate(data);
                break;
            case 'edit':
                dispatch(showModal(Modals.MemberModalEdit,{data}));
                break;
            default:
                break;
        }

    }


return(
    <div> 
        <DashboardComponentBodyTop title="Members" onAdd={handleAddClicked} />
         <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                   columns={getColumns(handleAction)}
                                   dataSource={[...members]}
                                   loading={memberState.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
)
}