import { Divider, Input, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { createMobileOperator, showMobileOperator, updateMobileOperator } from '../../../../../../../redux/features/mobile_operators';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../../../../redux/features/modal';
import TextArea from 'antd/es/input/TextArea';


const initialState = {
    name: "",
    value: "",
    description: "",
    active: ""
}

export const MobileOperatorModalEdit = (props) => {
    // props
    const { id, submitted,changeParentProps } = props;

    // state
    const [mounted, setMounted] = useState(false);
    const [values, setValues] = useState(initialState);
    const [mobile, setMobile] = useState({});

    // redux
    const dispatch = useDispatch();
    const viewState = useSelector((store) => store.mobile_operators.view);
    const updateState = useSelector((store) => store.mobile_operators.update);
    const createState = useSelector((store) => store.mobile_operators.create);
    
    // effects
    useEffect(()=>{
       setMounted(true);
       setValues({});
    },[])
 
    useEffect(() => {
        if (!mounted) return;
        if (id) {
            handleUpdate();
        } else {
            handleCreate();
        }
    }, [submitted]);

    useEffect(() => {
        if(!id) return;
        setValues({});
        dispatch(showMobileOperator(id));
    }, [id])

    useEffect(() => {
        console.log("view state successeful", viewState.isSuccessful);
        if (!mounted) return;
        if (!viewState.isSuccessful) return;
        console.log("Populating Data.....");
        setMobile(viewState.data);
    }, [viewState.isSuccessful]);

    useEffect(() => {
        // if console.log("data loaded", mobile)
        if(!mounted) return;
        if (!mobile) return;
        //  console.log(' value form state :', mobile);
        let payload = initialState;
        for (const field in initialState) {
         //   console.log("field ==" + field);
            if (field in mobile) {
                payload[field] = mobile[field];
            }
        }
        setValues(payload);
    }, [mobile]);


    useEffect(() => {
        if (!updateState.isSuccessful) return;
        dispatch(closeModal());
    }, [updateState.isSuccessful]);


 useEffect(() => {
        changeParentProps('confirmLoading', createState.isLoading);
    }, [createState.isLoading])

    useEffect(() => {
        changeParentProps('confirmLoading', updateState.isLoading);
    }, [updateState.isLoading])

    // handlers
    const handleCreate = () => {
        dispatch(createMobileOperator(values));
    }

    const handleUpdate = () => {
        const data = {
            id: id,
            value: values
        }
        dispatch(updateMobileOperator(data));
    }

    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        setValues({ ...values, [name]: value });
    }

    const handleChangeCheck = (e) => {
      //  console.log("check values:", e);
        const value = e;
        setValues({ ...values, 'active': value });
    }

    const handleFileChange = (event) => {
        // obtain file 
        const file = event.target.files[0];
        const name = event.target.name;
        setValues({...values, [name]: file });
    }

    return (
        <div>
            <br />
            <div className="container">

                <div className='row'>
                    { (id) ? <div>
                       
                        <div><h4> EDIT MOBILE OPERATOR</h4></div>
                    </div> : <div>
                         
                        <h4>CREATE MOBILE OPERATOR </h4>
                    </div>}

                </div>
                <Divider />
                <div className="row">
                    <div className="col-md-12">

                        <div class="mb-3">
                            <label className="form-label">Logo</label>
                            <input name='logo' className="form-control" type="file" onChange={handleFileChange} />
                        </div>


                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb-3">
                        <div className="form-label">
                            Name
                        </div>
                        <Input onChange={handleChange} placeholder="Name" name="name" value={values.name} />

                    </div>
                </div>
                <div className="col-md-12">
                    <div className="mb-3">
                        <div className="form-label">
                            Value
                        </div>
                        <Input onChange={handleChange} placeholder="Value" name="value" value={values.value} />

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <div className="form-label">
                                Description
                            </div>
                            <TextArea rows={4} onChange={handleChange} name="description" value={values.description} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <Switch name="active" defaultChecked onChange={(e) => handleChangeCheck(e)} /> Active
                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}
