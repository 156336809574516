import React from 'react'

export const IconChevronLeft = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M19.2929 26.7071C19.6834 27.0976 20.3166 27.0976 20.7071 26.7071C21.0676 26.3466 21.0953 25.7794 20.7903 25.3871L20.7071 25.2929L11.415 16L20.7071 6.70711C21.0676 6.34663 21.0953 5.77939 20.7903 5.3871L20.7071 5.29289C20.3466 4.93241 19.7794 4.90468 19.3871 5.20971L19.2929 5.29289L9.2929 15.2929C8.93241 15.6534 8.90468 16.2206 9.20971 16.6129L9.2929 16.7071L19.2929 26.7071Z" />
    </svg>

  )
}
