import React from 'react'
import * as styles from './index.module.scss';
import featured from '$assets/images/product-image.png';
import ButtonMain from '../../../../components/buttons/Main';

export const WebWishlistPage = () => {
  return (
    <div className={` container`}>
    <div className="row my-5">
        <div className="col-lg d-flex flex-column align-items-start">
            <h1>My orders</h1>
             <p>{`Home > My Orders`}</p>
        </div>
    </div>

    <div className="row mb-5">
        <div className="col-lg-12">
            <table className="table">
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>PRICE</th>
                        <th>SHOP</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            <span className=''>x</span>
                            <span className="px-2"></span>
                            <img src={featured} height="50px" width="50px" alt="" srcset="" />
                        </td>
                        <td>
                            TZS 10,000/=
                        </td>
                        <td>
                            Shoppers, Masaki
                        </td>
                        <td>
                        <ButtonMain>View item</ButtonMain>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className=''>x</span>
                            <span className="px-2"></span>
                            <img src={featured} height="50px" width="50px" alt="" srcset="" />
                        </td>
                        <td>
                            TZS 10,000/=
                        </td>
                        <td>
                            Shoppers, Masaki
                        </td>
                        <td>
                        <ButtonMain>View item</ButtonMain>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className=''>x</span>
                            <span className="px-2"></span>
                            <img src={featured} height="50px" width="50px" alt="" srcset="" />
                        </td>
                        <td>
                            TZS 10,000/=
                        </td>
                        <td>
                           Shoppers, Masaki
                        </td>
                        <td>
                        <ButtonMain>View item</ButtonMain>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
  )
}
