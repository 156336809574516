.wrapper {
    height: 100vh;
    width: 100vw;
    background-color: #F8F8F8;
    display: flex;

    .sidebar {
        height: 100vh;
        width: 270px;
        background-color: #ffffff;

        border-right: solid 1px #D9D9D9;
    }

    .bodyWrapper {
        flex: 1;

        .header {
            height: 65px;
            width: 100%;
            background-color: #ffffff;
            border-bottom: solid 1px #D9D9D9;
        }
        
        .body {
            height: calc(100vh - 65px);
            width: 100%;
            background-color: #F8F8F8;

            padding: 31px;
            overflow: scroll;
        }
    }
}