import { Route, Routes } from "react-router-dom";
import React from 'react'
import { DashboardPageMoileOperators } from "./list";
export const MobileOperatorRoutes = () => {
    return (
        <Routes>
            <Route index element={<DashboardPageMoileOperators />} />
        </Routes>
    )
}
