import { Table } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries } from '../../../../../../../redux/features/location';
import { showModal } from '../../../../../../../redux/features/modal';
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top/index';
import { Modals } from '../../../../components/layout/modal';
import { countryColumns, getColumns } from './columns';

export const DashboardPageLocationCountryList = () => {
    // redux
    const dispatch = useDispatch();
    const countries = useSelector(state => state.location.countries);
    const fetchCountriesState = useSelector(state => state.location.fetchCountries);

    // effect
    useEffect(() => {
        dispatch(fetchCountries());
    }, []);

    // handlers
    const handleAddClicked = (e) => {
        e.preventDefault();
        dispatch(showModal(Modals.CountryForm))
    }

    const handleTableActions = (action, data) => {
        switch (action) {
            case 'edit':
                dispatch(showModal(Modals.CountryForm,  { form: data } ));
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <DashboardComponentBodyTop title="Countries" onAdd={handleAddClicked} />

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Table
                                    columns={getColumns(handleTableActions)}
                                    dataSource={[...countries]}
                                    loading={fetchCountriesState.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
