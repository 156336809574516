import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
const GET_USERS = 'user/GET_USERS';
const CREATE_USER = 'user/CREATE_USER';
const SHOW_USER = 'user/SHOW_USER';
const DELETE_USER = 'user/DELETE_USER';
const UPDATE_USER = 'user/UPDATE_USER';

const initialState = {
    users: [],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: [],
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    }

}

export const getUsers = createAsyncThunk(GET_USERS, async (data, store) => {
    const { token } = store.getState().authentication;
    // console.log(token);
    return await customFetch('GET', GraceApi.users, null, token);
})

export const createUser = createAsyncThunk(CREATE_USER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.userCreate, data, token, store)
})

export const showUser = createAsyncThunk(SHOW_USER, async (data, store) => {
  //  console.log("Data of show-------=>", userId);
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.userShow(data), null, token, store)
})
export const deleteUser = createAsyncThunk(DELETE_USER, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('DELETE', GraceApi.userDelete(data), null, token, store)
})

export const updateUser = createAsyncThunk(UPDATE_USER, async (data, store) => {
    console.log("for update", data)
    const { value, userId } = data;
    const { token } = store.getState().authentication;
    return await customFetch('PUT', GraceApi.userUpdate(userId), value, token, store)
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        //get User
        [getUsers.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
            state.users = [];
        },
        [getUsers.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;

        },
        [getUsers.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.users = action.payload.data;

        },
        //create User
        [createUser.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
            state.create.data = {};
        },
        [createUser.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;

        },
        [createUser.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload.data;
            // prepend action.payload.data to state.usersg
            state.users = [action.payload.data, ...state.users];
        },
        //show User
        [showUser.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.error = null;
            state.view.data = {};

        },
        [showUser.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;

        },
        [showUser.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = true;
            state.view.error = null;
            state.view.data = action.payload.data;
        },
        //delete user

        [deleteUser.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = null;

        },
        [deleteUser.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            //   state.delete.data = action.payload.data,
            state.delete.error = action.error

        },
        [deleteUser.fulfilled]: (state, action) => {
            state.delete.isLoading = false
            state.delete.isSuccessful = true
            state.delete.data = action.payload.data,
                state.delete.error = null;
            state.users = state.users.filter(user => user.id != action.meta.arg);
        },
        //update user
        [updateUser.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.error = null;
        },
        [updateUser.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.error = action.error;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.error = null;
            state.update.data = action.payload.data;
            const value = state.users.find(user => user.id === action.payload.id);
            if (value > -1) {
                state.users['value'] = action.payload.value;
            }

        },



    }
})
export default userSlice.reducer;

