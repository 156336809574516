
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GraceApi from '../../api/grace/endpoint';
import { customFetch } from '../../core/request';

// action types
const FETCH_COUNTRIES = 'location/FETCH_COUNTRIES';
const CREATE_COUNTRY = 'location/CREATE_COUNTRY';
const RETRIEVE_COUNTRY = 'location/RETRIEVE_COUNTRY';
const UPDATE_COUNTRY = 'location/UPDATE_COUNTRY';
const DELETE_COUNTRY = 'location/DELETE_COUNTRY';
const FETCH_COUNTRY_REGIONS = 'location/FETCH_COUNTRY_REGIONS'; //

// actions
export const fetchCountries = createAsyncThunk(
    FETCH_COUNTRIES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', GraceApi.countries, null, token, store);
    }
)

export const createCountry = createAsyncThunk(
    CREATE_COUNTRY,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', GraceApi.countries, data.payload, token, store);
    }
);

export const retrieveCountry = createAsyncThunk(
    RETRIEVE_COUNTRY,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', GraceApi.country(data.id), null, token, store);
    }
);

export const updateCountry = createAsyncThunk(
    UPDATE_COUNTRY,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', GraceApi.country(data.id), data.payload, token, store);
    }
);

export const deleteCountry = createAsyncThunk(
    DELETE_COUNTRY,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', GraceApi.country(data.id), null, token, store);
    }
);

export const fetchCountyRegion = createAsyncThunk(
    FETCH_COUNTRY_REGIONS,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', GraceApi.regions(data.id), null, token, store);

    });


// initial state
const initialState = {
    countries: [],
    regions: [],
    fetchCountries: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    createCountry: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    retrieveCountry: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    updateCountry: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    deleteCountry: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },

    fetchRegion: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},

    }
}

// slice
const locationSlice = createSlice({
    name: 'location',
    initialState,
    extraReducers: {
        // fetch countries
        [fetchCountries.pending]: (state, action) => {
            state.fetchCountries.isLoading = true;
            state.fetchCountries.isSuccessful = false;
            state.fetchCountries.error = null;
            state.fetchCountries.data = {};
        },
        [fetchCountries.fulfilled]: (state, action) => {
            state.fetchCountries.isLoading = false;
            state.fetchCountries.isSuccessful = true;
            state.fetchCountries.error = null;
            state.fetchCountries.data = action.payload.data;
            state.countries = action.payload.data;
        },
        [fetchCountries.rejected]: (state, action) => {
            state.fetchCountries.isLoading = false;
            state.fetchCountries.isSuccessful = false;
            state.fetchCountries.error = action.error;
        },
        // create country
        [createCountry.pending]: (state, action) => {
            state.createCountry.isLoading = true;
            state.createCountry.isSuccessful = false;
            state.createCountry.error = null;
            state.createCountry.data = {};
        },
        [createCountry.fulfilled]: (state, action) => {
            state.createCountry.isLoading = false;
            state.createCountry.isSuccessful = true;
            state.createCountry.error = null;
            state.createCountry.data = action.payload.data;
            state.countries.unshift(action.payload.data);
        },
        [createCountry.rejected]: (state, action) => {
            state.createCountry.isLoading = false;
            state.createCountry.isSuccessful = false;
            state.createCountry.error = action.payload ?? action.error;
        },
        // retrieve country
        [retrieveCountry.pending]: (state, action) => {
            state.retrieveCountry.isLoading = true;
            state.retrieveCountry.isSuccessful = false;
            state.retrieveCountry.error = null;
            state.retrieveCountry.data = {};
        },
        [retrieveCountry.fulfilled]: (state, action) => {
            state.retrieveCountry.isLoading = false;
            state.retrieveCountry.isSuccessful = true;
            state.retrieveCountry.error = null;
            state.retrieveCountry.data = action.payload.data;
        },
        [retrieveCountry.rejected]: (state, action) => {
            state.retrieveCountry.isLoading = false;
            state.retrieveCountry.isSuccessful = false;
            state.retrieveCountry.error = action.error;
        },
        // update country
        [updateCountry.pending]: (state, action) => {
            state.updateCountry.isLoading = true;
            state.updateCountry.isSuccessful = false;
            state.updateCountry.error = null;
            state.updateCountry.data = {};
        },
        [updateCountry.fulfilled]: (state, action) => {
            state.updateCountry.isLoading = false;
            state.updateCountry.isSuccessful = true;
            state.updateCountry.error = null;
            state.updateCountry.data = action.payload.data;
            const index = state.countries.findIndex(country => country.id === action.payload.data.id);
            state.countries[index] = {...state.countries[index], ...action.payload.data};
        },
        [updateCountry.rejected]: (state, action) => {
            state.updateCountry.isLoading = false;
            state.updateCountry.isSuccessful = false;
            state.updateCountry.error = action.error;
        },
        // delete country
        [deleteCountry.pending]: (state, action) => {
            state.deleteCountry.isLoading = true;
            state.deleteCountry.isSuccessful = false;
            state.deleteCountry.error = null;
            state.deleteCountry.data = {};
        },
        [deleteCountry.fulfilled]: (state, action) => {
            state.deleteCountry.isLoading = false;
            state.deleteCountry.isSuccessful = true;
            state.deleteCountry.error = null;
            state.deleteCountry.data = action.payload.data;
        },
        [deleteCountry.rejected]: (state, action) => {
            state.deleteCountry.isLoading = false;
            state.deleteCountry.isSuccessful = false;
            state.deleteCountry.error = action.error;
        },
        [fetchCountyRegion.pending]: (state, action) => {
            state.fetchRegion.isLoading = true;
            state.fetchRegion.isSuccessful = false;
            state.fetchRegion.error = null;
        },
        [fetchCountyRegion.rejected]: (state, action) => {
            state.fetchRegion.isLoading = false;
            state.fetchRegion.isSuccessful = false;
            state.fetchRegion.error = action.error;
        },
        [fetchCountyRegion.fulfilled]: (state, action) => {
            state.fetchRegion.isLoading = false;
            state.fetchRegion.isSuccessful = true;
            state.fetchRegion.error = null;
            state.fetchRegion.data = action.payload;
            state.regions = action.payload.data;
        },


    }
});

export default locationSlice.reducer;