import React from 'react'
import * as styles from './index.module.scss';

const WebComponentPartialCategoriesItemCard = (props) => {
    const { title, description, banner } = props;
    return (
        <div className={styles.card} style={{ backgroundImage: `url(${banner})` }}>
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    );
}

export default WebComponentPartialCategoriesItemCard;