import React, { useEffect, useState } from 'react'
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Table, Tag } from 'antd';
import * as styles from './index.module.scss';
import moment from 'moment/moment';
import { IconDownload } from '../../../../../components/icons/download';
import { ComponectSearchInput } from '../../../components/form/input/search';
import { deletePaymentMethods, getPaymentMethods } from '../../../../../../redux/features/payment_methods';
import { useDispatch, useSelector } from 'react-redux';
import { Modals } from '../../../components/layout/modal';
import { showModal } from '../../../../../../redux/features/modal';
import { getPaymentsColumns } from './columns';

 const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '2',
            icon: < FilePdfOutlined />

        },
    ];

    const menuProps = {
        items,
    };

export const DashboarPagePayementMethod = () => {
       
 const dispatch = useDispatch();
 const paymentMethods = useSelector((store) => store.payment_methods.paymentMethods);

    useEffect(() => {
        dispatch(getPaymentMethods())
    }, []);

        const handleAction = (action, data) => {
        console.log(action, data);
        switch (action) {
            case 'edit':
                handleEdit(data.id)
                break;
            case 'view':
                handleView(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }
    }

    const handleView = (id) => {

        dispatch(showModal(Modals.PaymentMethodModalShow, { id }))
        console.log(" is clicked ", id);
    }
    const handleEdit = (id) => {
        dispatch(showModal(Modals.PaymentMethodModalEdit, { id }));
        console.log("handle edit called", id);
    }
    const handleDelete = (id) => {
        dispatch(deletePaymentMethods(id))
    }
   const handleShowModalCreate = () => {
        dispatch(showModal(Modals.PaymentMethodModalEdit))
    }

    




    


    

    return (
        <div>
            <div className={styles.wrapper}>
                <div className="row">
                    <div className="col">
                        <div className="Merchants">
                            <h4>Payments Method</h4>
                        </div>
                    </div>
                    <div className="col">
                        <div className={styles.icon}>
                            <div className="btn-group">
                                <Dropdown menu={menuProps}>
                                    <Button style={{
                                        borderRadius: '20px',
                                    }}>
                                        <Space>
                                            <IconDownload width="16px" height="16px" />  Export

                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            </div>
                            <div className="px-2"></div>
                            <Button type="success" style={{
                                borderRadius: '20px',
                                backgroundColor: '#258E01',
                                color: '#ffffff'

                            }} onClick={handleShowModalCreate}>+</Button>
                        </div>


                    </div>

                </div>


            </div>
            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Active</a>
                    <a className={`nav-link ${styles.link}`} href="#">Blocked</a>


                </nav>
            </div>
            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search payments" />
                        </div>
                        <div className='col-md-2'>
                            <Dropdown menu={menuProps}>
                                <Button style={{
                                    borderRadius: '20px',
                                }}>
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table

                        columns={getPaymentsColumns(handleAction) }
                        dataSource={paymentMethods}
                    />
                </div>



            </div>


        </div>
    )
}
