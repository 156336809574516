import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';
import productimage from '$assets/images/placeholder.webp';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../../../../../redux/features/cart';
import { IconCart } from '../../../../../components/icons/cart';
import GraceApi from '../../../../../../api/grace/endpoint';
import { Badge, Tooltip, Button } from 'antd';
import { useEffect } from 'react';
import { placeholder } from '$assets/images/not_found.png';

const WebComponentItemProduct = (props) => {
    // props
    const { product } = props;

    // router
    const navigate = useNavigate();

    // state
    const [count, setCount] = useState();

    // redux
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart)

    // effects
    useEffect(() => {
        if (cart.items.some(item => item.product.id == product.id)) {
            let item = cart.items.find(i => i.product.id == product.id);
            setCount(item.quantity);
        }
    }, [cart]);

    // handlers
    const addTocart = (e) => {
        e.stopPropagation();
        dispatch(addToCart(product));
    };

    const viewProduct = () => {
        navigate(`/single/${product.id}`);
    }

    return (
        <div className={styles.wrapper} onClick={viewProduct}>

            <div className={`img-fluid ${styles.image}`} style={{ backgroundImage: `url(${getProductImage(product)})` }}>
            <Tooltip title="Add to Cart">
                <Button type="text" shape="circle" icon={(
                    <Badge style={{ backgroundColor: '#52c41a' }} count={count}>
                        <IconCart filled={count} width="20px" height="20px" onClick={addTocart} />
                    </Badge>
                )} />
            </Tooltip>
                
            </div>            
            <div className={styles.name}>
               {product.name} </div>
            {/* Price with thousands separator */}
            <div className={styles.price}>TZS {getProductPrice(product)}</div>
        </div>
    );
}

const getProductPrice = (product) => {
    let price = 0;
    if (product.price) {
        price = product.price;
    } else {
        if (product.skus && product.skus.length) {
            price = product.skus[0].price;
        }
    }
    if (!price) return 0;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const getProductImage = (product) => {
    console.log("product image")
    if (product && product.skus) {
        console.log("skus detected")
        if (product.skus[0].featured_image) {
            return GraceApi.domain + product.skus[0].featured_image;
        } else {
            return productimage;
        }
    } else {
        console.log('returing default image');
        return productimage;
    }
}



WebComponentItemProduct.defaultProps = {
    product: {
        id: 1,
        name: 'Product Name',
        price: 100000,
        image: productimage
    }
}

WebComponentItemProduct.propTypes = {
    product: PropTypes.object.isRequired
}


export default WebComponentItemProduct;