import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GraceApi from "../../api/grace/endpoint";
import { customFetch } from "../../core/request";
//product
const GET_PRODUCTS = 'product/GET_PRODUCTS';
const CREATE_PRODUCT = 'product/CREATE_PRODUCT';
const UPDATE_PRODUCT = 'product/UPDATE_PRODUCT';
const VIEW_PRODUCT = 'product/VIEW_PRODUCT';
const DELETE_PRODUCT = 'product/DELETE_PRODUCT';

//category
const GET_PRODUCTS_CATEGORY = 'product/GET_PRODUCTS_CATEGORY';
const CREATE_PRODUCTS_CATEGORY = 'product/CREATE_PRODUCTS_CATEGORY';
const SHOW_PRODUCTS_CATEGORY = 'product/SHOW_PRODUCTS_CATEGORY';
const UPDATE_PRODUCTS_CATEGORY = 'product/UPDATE_PRODUCTS_CATEGORY';
//product sku
const GET_PRODUCTS_SKU = 'product/GET_PRODUCTS_SKU';
const CREATE_PRODUCT_SKU = 'product/CREATE_PRODUCT_SKU';
//product Optional
const CREATE_PRODUCT_OPTIONAL = 'product/CREATE_PRODUCT_OPTIONAL';

const initialState = {
    products: [],
    categories: [],
    productSku:[],
    get: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    view: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    update: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    create: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    delete: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    getCategory: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    viewCategory: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    updateCategory: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    createCategory: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    deleteCategory: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
     getSku: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    viewSku: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}

    },
    updateSku: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    createSku: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    deleteSku: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },


    createOption:{
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    }



}
//products
export const getProducts = createAsyncThunk(GET_PRODUCTS, async (data, store) => {
    return await customFetch('GET', GraceApi.products);
});
export const createProduct = createAsyncThunk(CREATE_PRODUCT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.productCreate, data, token, store)
});
export const viewProduct = createAsyncThunk(VIEW_PRODUCT, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.productShow(data),null, token,store);
})

export const updateProduct = createAsyncThunk(UPDATE_PRODUCT, async (data, store) => {
    const { token } = store.getState().authentication;
    const { id } = data.id;
    return await customFetch('POST', GraceApi.updateProduct(id), data.payload, token, store)
})
export const deleteProduct = createAsyncThunk(DELETE_PRODUCT, async (data, store) => {
    const { id } = data.id;
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.deleteProduct(id), token, store)
});

//categories
export const getProductCategory = createAsyncThunk(GET_PRODUCTS_CATEGORY, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.productCategory, null, token, store);
});

export const createProductCategory = createAsyncThunk(CREATE_PRODUCTS_CATEGORY, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('POST', GraceApi.productCategoryCreate, data, token, store);
});
export const showProductCategory = createAsyncThunk(SHOW_PRODUCTS_CATEGORY, async (data, store) => {
    const { token } = store.getState().authentication;
    return await customFetch('GET', GraceApi.productCategoryShow(data), null, token, store);
});
export const updateProductCategory = createAsyncThunk(UPDATE_PRODUCTS_CATEGORY, async (data, store) => {
    const { id, value } = data;
    const { token } = store.getState().authentication;
    return await customFetch('PUT', GraceApi.productCategoryUpdate(id), value, token, store);
});

//productSkus

export const getProductSkus =createAsyncThunk(GET_PRODUCTS_SKU,async(data, store)=>{
    const { token } = store.getState().authentication;
    return await customFetch('GET',GraceApi.productSku(data),null,token,store);

});

export const createProductSkus = createAsyncThunk(CREATE_PRODUCT_SKU,async(data,store)=>{
    const { token } = store.getState().authentication;
    const { productId, value} = data;
    return await customFetch('POST',GraceApi.productSku(productId),value,token,store);

})

export const createProductOption =createAsyncThunk(CREATE_PRODUCT_OPTIONAL,async(data, store)=>{
    const { token } = store.getState().authentication;
    const { productId, value } = data;
    return await customFetch('POST', GraceApi.productOptionCreate(productId),value,token,store);
});

const productSlice = createSlice({
    name: 'product',
    initialState,
    extraReducers: {
        ///getproduct
        [getProducts.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessful = false;
            state.get.error = null;
            state.get.data = {};
        },
        [getProducts.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = false;
            state.get.error = action.error;
        },
        [getProducts.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessful = true;
            state.get.error = null;
            state.get.data = action.payload.data;
            state.products = action.payload.data;
        },
        //createProduct
        [createProduct.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessful = false;
            state.create.error = null;
            state.create.data = {};
        },
        [createProduct.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = false;
            state.create.error = action.error;
        },
        [createProduct.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessful = true;
            state.create.error = null;
            state.create.data = action.payload.data;
           // state.products.unshift(action.payload.data);
        },
        //showProduct
        [viewProduct.pending]: (state, action) => {
            state.view.isLoading = true;
            state.view.isSuccessful = false;
            state.view.data = {};
            state.view.error = null;

        },
        [viewProduct.rejected]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = action.error;
        },
        [viewProduct.fulfilled]: (state, action) => {
            state.view.isLoading = false;
            state.view.isSuccessful = false;
            state.view.error = null;
            state.view.data = action.payload.data;
        },
        //updateProduct
        [updateProduct.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessful = false;
            state.update.data = action.payload;
            state.update.error = null;
        },
        [updateProduct.rejected]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = false;
            state.update.data = {};
            state.update.error = action.error;
        },
        [updateProduct.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessful = true;
            state.update.data = action.payload.data;
            state.update.error = null;
            const value = state.products.find(p => p.id === action.payload.id);
            if (value > -1) {
                state.products[value] = action.payload.data;
            }
        },
        //delete product
        [deleteProduct.rejected]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessful = false;
            state.delete.error = null;

        },
        [deleteProduct.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = false;
            state.delete.error = action.error;
        },
        [deleteProduct.fulfilled]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessful = true;
            state.delete.error = null;
            state.delete.data = action.payload.data;
            state.products = state.products.filter(data => data.id != action.meta.arg.id);
        },
        //category
        [getProductCategory.pending]: (state, action) => {
            state.getCategory.isLoading = true;
            state.getCategory.isSuccessful = false;
            state.getCategory.error = null;
            state.getCategory.data = {};
        },
        [getProductCategory.rejected]: (state, action) => {
            state.getCategory.isLoading = false;
            state.getCategory.isSuccessful = false;
            state.getCategory.error = action.error;
        },
        [getProductCategory.fulfilled]: (state, action) => {
            state.getCategory.isLoading = false;
            state.getCategory.isSuccessful = true;
            state.getCategory.error = null;
            state.getCategory.data = action.payload;
            state.categories = action.payload.data;
        },
        //
        [createProductCategory.pending]: (state, action) => {
            state.createCategory.isLoading = true;
            state.createCategory.isSuccessful = false;
            state.createCategory.error = null;

        },
        [createProductCategory.rejected]: (state, action) => {
            state.createCategory.isLoading = false;
            state.createCategory.isSuccessful = false;
            state.createCategory.error = action.error;
        },
        [createProductCategory.fulfilled]: (state, action) => {
            state.createCategory.isLoading = false;
            state.createCategory.isSuccessful = true;
            state.createCategory.error = null;
            state.createCategory.data = action.payload;
            state.categories.unshift(action.payload.data);
        },


        //
        [showProductCategory.pending]: (state, action) => {
            state.viewCategory.isLoading = true;
            state.viewCategory.isSuccessful = false;
            state.viewCategory.error = null;

        },
        [showProductCategory.rejected]: (state, action) => {
            state.viewCategory.isLoading = false;
            state.viewCategory.isSuccessful = false;
            state.viewCategory.error = action.error;
        },
        [showProductCategory.fulfilled]: (state, action) => {
            state.viewCategory.isLoading = false;
            state.viewCategory.isSuccessful = true;
            state.viewCategory.error = null;
            state.viewCategory.data = action.payload.data;

        },
        //
        [updateProductCategory.pending]: (state, action) => {
            state.updateCategory.isLoading = true;
            state.updateCategory.isSuccessful = false;
            state.updateCategory.error = null;

        },
        [updateProductCategory.rejected]: (state, action) => {
            state.updateCategory.isLoading = false;
            state.updateCategory.isSuccessful = false;
            state.updateCategory.error = action.error;
        },
        [updateProductCategory.fulfilled]: (state, action) => {
            state.updateCategory.isLoading = false;
            state.updateCategory.isSuccessful = true;
            state.updateCategory.error = null;
            state.updateCategory.data = action.payload.data;
            const value = state.categories.find(val => val.id === action.payload.id);
            if (value > -1) {
                state.categories['value'] = action.payload.value;
            }

        },
        //productSkus
        [getProductSkus.pending]:(state,action)=>{
            state.getSku.isLoading=true;
            state.getSku.isSuccessful = false;
            state.getSku.error = null;
        },
        [getProductSkus.rejected]:(state,action)=>{
            state.getSku.isLoading = false;
            state.getSku.isSuccessful = false;
            state.getSku.error = action.error;
        },
        [getProductSkus.fulfilled]:(state,action)=>{
             state.getSku.isLoading = false;
            state.getSku.isSuccessful = true;
            state.getSku.error = null;
            state.getSku.data = action.payload.data;
            state.productSku = action.payload.data;

        },
         [createProductSkus.pending]:(state,action)=>{
            state.createSku.isLoading=true;
            state.createSku.isSuccessful = false;
            state.createSku.error = null;
        },
         [createProductSkus.rejected]:(state,action)=>{
            state.createSku.isLoading = false;
            state.createSku.isSuccessful = false;
            state.createSku.error = action.error;
        },
         [createProductSkus.fulfilled]: (state, action) => {
            state.createSku.isLoading = false;
            state.createSku.isSuccessful = true;
            state.createSku.error = null;
            state.createSku.data = action.payload;
            state.productSku.unshift(action.payload.data);
        },

        //option
        [createProductOption.pending]:(state,action)=>{
            state.createOption.isLoading=true;
            state.createOption.isSuccessful = false;
            state.createOption.error = null;
        },
         [createProductOption.rejected]:(state,action)=>{
            state.createOption.isLoading=false;
            state.createOption.isSuccessful = false;
            state.createOption.error = action.payload;
        },
         [createProductOption.fulfilled]:(state,action)=>{
            state.createOption.isLoading=false;
            state.createOption.isSuccessful = true;
            state.createOption.error = null;
            state.createOption.data = action.payload.data;
        }


    },
})

export default productSlice.reducer;