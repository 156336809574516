import { Input, Space } from 'antd'
import React from 'react'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

export const AuthInput = (props) => {
    const { type, name, label, placeholder, value, onChange, onBlur, error } = props;

    const renderField = () => {
        if (type == 'password') {
            return <Input.Password
                status={error ? 'error' : null} size="large" name={name} value={value} onChange={onChange} style={{ borderRadius: "14px" }} placeholder={placeholder}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
        }
        return <Input status={error ? 'error' : null} size="large" name={name} value={value} onChange={onChange} onBlur={onBlur} style={{ borderRadius: "14px" }} placeholder={placeholder}  />
    }

    return (
        <div>
            <label className="form-label">{label}</label>
            {renderField()}
            {error && <p className='text-danger' style={{ fontSize: "10px" }}>{error}</p>}
        </div>
    );
}
