import React, { useEffect, useRef, useState } from 'react'
import { Alert, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createCountry, updateCountry } from '../../../../../../../../redux/features/location';
import { closeModal } from '../../../../../../../../redux/features/modal';

export const DashboardComponentModalLocationCountryForm = (props) => {
    // refs
    const ref = useRef();
    const [map, setMap] = useState(null);

    // props
    const { form, submitted, changeParentProps } = props;

    // state
    const [mounted, setMounted] = useState(false);
    const [payload, setPayload] = useState({});
    const [position, setPosition] = useState({ lat: -6.369028, lng: 34.888822 });
    const [zoom, setZoom] = useState(8);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});

    // redux
    const dispatch = useDispatch();
    const createCountryState = useSelector((store) => store.location.createCountry);
    const updateCountryState = useSelector((store) => store.location.updateCountry);

    // effects
    useEffect(() => {
        if (!form) return;;
        if (form.id) {
            changeParentProps('title', 'Edit Country');
            changeParentProps('okText', 'Update');
        }
        if (form.payload) {
            console.log(form.payload);
            setPayload(form.payload);
            setPosition({ lat: Number(form.payload.latitude ?? position.lat), lng: Number(form.payload.longitude ?? position.lng) });
            setZoom(form.payload.zoom ?? zoom);
        }
        console.log(form);
    }, [form]);

    useEffect(() => {
        if (!ref.current) return;
        const map = new google.maps.Map(ref.current, {
            center: position,
            zoom: zoom,
        });
        map.addListener('click', handleMapClick);
        map.addListener('zoom_changed', handleMapZoom);
        setMap(map);

        return () => {
            // if (map) {
            //     map.removeListener('click', handleMapClick);
            //     map.removeListener('zoom_changed', handleMapZoom);
            // }
        }
    }, [ref.current]);

    useEffect(() => {
        if (!mounted) return;
        handleSubmit();
    }, [submitted]);

    useEffect(() => {
        console.log("position changed");
        handleChange('latitude', position.lat);
        handleChange('longitude', position.lng);
        console.log(position);
    }, [position])

    useEffect(() => {
        handleChange('zoom', zoom);
    }, [zoom]);

    useEffect(() => {
        changeParentProps('confirmLoading', createCountryState.isLoading);
    }, [createCountryState.isLoading])

    useEffect(() => {
        changeParentProps('confirmLoading', updateCountryState.isLoading);
    }, [updateCountryState.isLoading])

    useEffect(() => {
        if (!mounted) return;
        if (!updateCountryState.isSuccessful) return;
        dispatch(closeModal());
    }, [updateCountryState.isSuccessful]);

    useEffect(() => {
        if (!mounted) return;
        if (!createCountryState.error && !updateCountryState.error) return;

        if (form && form.id) {
            if (updateCountryState.error.message) {
                setError(updateCountryState.error.message);
            } else {
                setError("Something wen't wrong.");
            }
    
            if (updateCountryState.error.errors) {
                let _errors = {};
                Object.keys(updateCountryState.error.errors).forEach((key) => {
                    _errors[key] = updateCountryState.error.errors[key][0];
                });
                setErrors(_errors);
            }
        } else {
            if (createCountryState.error.message) {
                setError(createCountryState.error.message);
            } else {
                setError("Something wen't wrong.");
            }
    
            if (createCountryState.error.errors) {
                let _errors = {};
                Object.keys(createCountryState.error.errors).forEach((key) => {
                    _errors[key] = createCountryState.error.errors[key][0];
                });
                setErrors(_errors);
            }
        }

        setTimeout(() => {
            setError('');
        }, 5000);
    }, [createCountryState.error, updateCountryState.error]);

    // handlers
    // handle map click
    const handleMapClick = (e) => {
        setPosition({ lat: Number(e.latLng.lat()), lng: Number(e.latLng.lng()) });
    }

    // handle map zoom
    const handleMapZoom = () => {
        setZoom(map.getZoom());
    }

    const handleChange = (name, value) => {
        setPayload(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if (errors[name]) {
            setErrors(prevState => ({
                ...prevState,
                [name]: null,
            }));
        }
    }

    const handleSubmit = () => {
        console.log('submit');
        
        if (form && form.id) {
            dispatch(updateCountry({ id: form.id, payload: {...payload, zoom: map.getZoom()}}));
        }
        else {
            dispatch(createCountry({payload: {...payload, zoom: map.getZoom()}}));
        }
    }

    return (
        <div>
            <h4>Country Form</h4>
            {error && (
                <div className='mt-2'>
                     <Alert message={error} type="error" />
                </div>
            )}
            <div className="my-3"></div>
            <Input name='name' placeholder='Country Name' status={errors.name ? 'error' : null} value={payload.name} onChange={(e) => handleChange('name', e.target.value)} />
            {errors.name && <p className='text-danger'>{errors.name}</p>}
            <div className="my-2"></div>
            <Input.TextArea name='description' placeholder='Country Description' value={payload.description}  onChange={(e) => handleChange('description', e.target.value)} />

            <div className="my-3">
                <div ref={ref} style={{ height: '300px', width: '100%' }}>
                    <Marker map={map} position={position} />
                </div>
            </div>
        </div>
    );
}

const Marker = (options) => {
    const [marker, setMarker] = useState();
  
    useEffect(() => {
      if (!marker) {
        setMarker(new google.maps.Marker());
      }
  
      // remove marker from map on unmount
      return () => {
        if (marker) {
          marker.setMap(null);
        }
      };
    }, [marker]);

    useEffect(() => {
      if (marker) {
        marker.setOptions(options);
      }
    }, [marker, options]);
    return null;
};