import React from 'react'
import placeholder from '$assets/images/not_found.png';
import * as styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import ButtonMain from '../../../../components/buttons/Main/index';

export const WebNotFoundPage = () => {
    // router
    const navigate = useNavigate();

    return (
        <div className={styles.page}>
            <div className={styles.image}>
                <h1>404</h1>
                <img  src={placeholder} alt="" />
            </div>
            <h1>We can't seem to find that</h1>
            <p>The page you are looking for does not exists or it has been moved.</p>
            <ButtonMain className="btn-lg" onClick={() => navigate('/home')}>Go Back Home</ButtonMain>
        </div>
    );
}
