.wrapper {
    text-align: center;
    border: solid 1px rgba($color: #000000, $alpha: 0.5);
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.8);
}

.image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    padding: 10px;
}

.name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    /* or 202% */

    text-align: center;
    letter-spacing: 0.25px;
    text-transform: capitalize;

    color: #222222;
}

.price {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    /* or 206% */

    text-align: center;

    color: #222222;
}