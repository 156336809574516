import React from 'react'
import { IconMenu } from '../../../../../components/icons/menu';
import { IconNotifications } from '../../../../../components/icons/notification';
import { IconSearch } from '../../../../../components/icons/search';
import * as styles from './index.module.scss';
import avatar from '../../../../../../assets/images/Avatar.png';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../../../redux/features/authentication';

const items = [
  {
    key: '1',
    label: (
      <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
        logout
      </a>
    ),
  },
];


export const DashboardComponentHeader = () => {
  // redux
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authentication);

  return (
    <div className={styles.wrapper}>
      <IconMenu width="16px" height="16px" />
      <div className="flex-grow-1"></div>
      <IconSearch width="16px" height="16px" />
      <div className="px-2"></div>
      <IconNotifications width="16px" height="16px" />
      <div className={styles.divider}></div>
      <div className={styles.user}>
        <div className={styles.avatar} style={{ backgroundImage: `url(${avatar})` }}></div>
        <div className={styles.name}>{user && user.name} <Dropdown menu={{ items: [{label: <div onClick={() => dispatch(logout())}>LOGOUT</div>}] }}><DownOutlined /></Dropdown></div>
      </div>
    </div>
  )
}
