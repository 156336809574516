.wrapper {
    position: relative;
    height: 450px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.container {
    z-index: 2;
    // margin: 0 150px;
}