import { Divider, Descriptions, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showPaymentMethods } from '../../../../../../../redux/features/payment_methods'

export const PaymentMethodModalShow = (props) => {
    const dispatch = useDispatch();
    const viewState = useSelector((store) => store.payment_methods.view);
    const [paymentMethod, setPaymentMethod] = useState([]);

    useEffect(() => {
        console.log("pass id value: ", props.id)
        dispatch(showPaymentMethods(props.id))
    }, [props.id])
    useEffect(() => {
        if (!viewState.isSuccessful) return;
        setPaymentMethod(viewState.data);
    }, [viewState.isSuccessful])
    console.log(paymentMethod.active)
    return (
        <div>

            <div className='container'>
                <div><h4>PAYMENT METHODS DETAILS</h4></div>
                <Divider />
                {viewState.isSuccessful ? <div>
                    <Descriptions title="">
                        <Descriptions.Item label="Name">{paymentMethod.name}</Descriptions.Item>
                        <Descriptions.Item label="Description">{paymentMethod.description}</Descriptions.Item>
                        <Descriptions.Item label="Status">{(paymentMethod.active != 0) ? <Tag color="green" key={paymentMethod.active}>
                            {"active".toUpperCase()}
                        </Tag> : <Tag color="red" key={paymentMethod.active}>
                            {"in active".toUpperCase()}
                        </Tag>}</Descriptions.Item>
                    </Descriptions>
                </div> : <div> Loading.......</div>}

            </div>

        </div>
    )
}
