import React from 'react'

export const IconSearch = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 7C1.75 4.1005 4.1005 1.75 7 1.75C9.8995 1.75 12.25 4.1005 12.25 7C12.25 9.8995 9.8995 12.25 7 12.25C4.1005 12.25 1.75 9.8995 1.75 7ZM7 0.25C3.27208 0.25 0.25 3.27208 0.25 7C0.25 10.7279 3.27208 13.75 7 13.75C8.59376 13.75 10.0585 13.1976 11.2133 12.2739L14.7192 15.7799C15.0121 16.0728 15.487 16.0728 15.7799 15.7799C16.0728 15.487 16.0728 15.0121 15.7799 14.7192L12.2739 11.2133C13.1976 10.0585 13.75 8.59376 13.75 7C13.75 3.27208 10.7279 0.25 7 0.25Z" />
    </svg>

  )
}
