import React, { useEffect, useState } from 'react'
import { DashboardComponentBodyTop } from '../../../../components/layout/body/top'
import { Button, Dropdown, Space, Table } from 'antd';
import { DownOutlined, PrinterOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import * as styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../../../../redux/features/modal';
import { Modals } from '../../../../components/layout/modal';
import { IconDownload } from '../../../../../../components/icons/download';
import { IconDot } from '../../../../../../components/icons/dot';
import { ComponectSearchInput } from '../../../../components/form/input/search';
import { deleteMerchantType, getMembersType } from '../../../../../../../redux/features/merchant';
import { getMerchantTypeColums } from './columns';


  const items = [
        {
            label: 'Print',
            key: '1',
            icon: <PrinterOutlined width={50} height={50} />

        },
        {
            label: 'Excel',
            key: '2',
            icon: <FileExcelOutlined width={50} height={50} />

        },
        {
            label: 'PDF',
            key: '2',
            icon: < FilePdfOutlined />

        },
    ];

    const menuProps = {
        items,
    };

export const DashboardPageMerchantTypeList = () => {
   //states
  //  const [isModalOpen, setIsModalOpen] = useState(false);

    // redux
    const dispatch = useDispatch();
     const merchantTypes = useSelector((store) => store.merchant.merchantTypes);


    //effects
    useEffect(() => {
        dispatch(getMembersType());
    }, []);

    // handlers
    
  
    const handleAction =(action,data)=>{
         console.log(action, data);
        switch (action) {
            case 'edit':
                handleEdit(data.id)
                break;
            case 'delete':
                handleDelete(data.id)
                break;
            default:
                break;
        }

    }

    const handleEdit = (value) => {
        console.log(value);
        dispatch(showModal(Modals.MerchantFormModal, { value }));
    };
    

    const handleShowModalCreate = () => {
        dispatch(showModal(Modals.MerchantFormModal));
    }
    
    const handleDelete = (value) => {
        console.log(value);
        dispatch(deleteMerchantType(value));

    }



   
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <h4>Merchants</h4>
                </div>
                <div className={styles.icon}>
                    <div className="btn-group">
                        <Dropdown menu={menuProps}>
                            <Button style={{
                                borderRadius: '20px',
                            }}>
                                <Space>
                                    <IconDownload width="16px" height="16px" />  Export

                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>


                    </div>
                    <div className="px-2"></div>
                    <Button type="success" style={{
                        borderRadius: '20px',
                        backgroundColor: '#258E01',
                        color: '#ffffff'

                    }} onClick={handleShowModalCreate}>+</Button>



                </div>
                <div>

                </div>
            </div>
            <div className={styles.navAl}>
                <nav className={`nav ${styles.nav}`}>
                    <a className={`nav-link active ${styles.active}`} aria-current="page" href="#">All</a>
                    <a className={`nav-link ${styles.link}`} href="#">Active</a>
                    <a className={`nav-link ${styles.link}`} href="#">Blocked</a>


                </nav>
            </div>

            <div className={styles.mainBody}>
                <div className={`${styles.inputSerach}`}>
                    <div className='row'>
                        <div className='col-md-10'>
                            <ComponectSearchInput placeholder="Search merchants" />
                        </div>
                        <div className='col-md-2'>
                            <Dropdown menu={menuProps}>
                                <Button style={{
                                    borderRadius: '20px',
                                }}>
                                    <Space>
                                        Actions
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.datatable}>
                    <Table
                        
                        columns={getMerchantTypeColums(handleAction)}
                        dataSource={merchantTypes}
                    />
                </div>



            </div>
        </>
    )
}
