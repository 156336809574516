import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createProduct, createProductSkus } from '../../../../../../redux/features/product';
import { InboxOutlined } from '@ant-design/icons';
import { Divider, Input, message, Upload } from 'antd';

const { Dragger } = Upload;
const { TextArea } = Input;
const props = {
  name: 'file',
  multiple: true,
  action: '',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};

const initialValues ={
  name:'',
  price:'',
  description:''
}
export const ProductFormModal = (props) => {
  console.log(props);
  const {id ,submitted,product_id,changeParentProps } = props;
  const [mounted, setMounted]=useState(false);
  const [values,setValues] =useState(initialValues);
  const productSkuState = useSelector((store)=>store.product.createSku);
  const dispatch =useDispatch();

  useEffect(()=>{
    setMounted(true);
  },[mounted])
   useEffect(() => {
        if (!mounted) return;
        if (id) {
            handleUpdate();
        } else {
            handleCreate();
        }
    }, [submitted]);

    

    useEffect(()=>{
      if (!mounted) return;
      if(product_id){
        handleCreateSku();
      }

    },[submitted])

      useEffect(() => {
        if(!mounted) return;
        changeParentProps('confirmLoading',productSkuState.isLoading);
    }, [productSkuState.isLoading])

    const handleCreate = () => {
       dispatch(createProduct(values));
    }

    const  handleCreateSku=()=>{
      data ={
        productId: product_id,
        value:values
      }
      dispatch(createProductSkus(data));
    }
    const handleUpdate = () => {

    }
    const handleChange =(event)=>{
      const name = event.target.name;
      const value = event.target.value;
      setValues({...values, [name]:value});

    }

  return (
    <div className='container'>
      <Divider> <h4>{(product_id)?"CREATE PRODUCT SKU":"CREATE PRODUCT"}</h4></Divider>  
      <div className='p-2'></div>
      
        <div className='col-md-12'>
          <div className='row'>
          <div className='col-md-6'>
              <div className='row'>
        <Input name="name" value={values.name} onChange={handleChange} placeholder="Name" />
        </div>
        <div className='p-2'></div>
         <div className='row'>
        <Input name="price" value={values.price} onChange={handleChange} placeholder="Price" />
        </div>
          <div className='p-2'></div>
         
          </div>
           <div className='col-md-6'>
             <div className="row">
             <Dragger {...props}>
            <p className="ant-upload-drag-icon">
           <InboxOutlined />
           </p>
          <p className="ant-upload-text"> drag file to this area to upload</p>
         </Dragger>
          <div className='p-2'></div>
          <div className='p-2'></div>
          <div className='p-2'></div>
        </div>
           </div>
        </div>
      </div>
         <div className="col-md-12">
           <div className="row">
            <TextArea name="description" value={values.description} onChange={handleChange} rows={4} />
          </div>
         </div>
        <div className='p-2'></div>
      </div>
  )
}
